export const ptLang = {
  languageKey: 'pt',
  translates: {
    primeng: {
      startsWith: 'Começa com',
      contains: 'Contém',
      notContains: 'Não contém',
      endsWith: 'Termina com',
      equals: 'É igual a',
      notEquals: 'Não é igual a',
      noFilter: 'Nenhum filtro',
      lt: 'Menor que',
      lte: 'Menor ou igual a',
      gt: 'Maior que',
      gte: 'Maior ou igual a',
      is: 'É',
      isNot: 'Não é',
      before: 'Antes',
      after: 'Depois',
      clear: 'Apagar',
      apply: 'Aplicar',
      matchAll: 'Combinar tudo',
      matchAny: 'Combinar qualquer um',
      addRule: 'Adicionar regra',
      removeRule: 'Eliminar regra',
      accept: 'Sim',
      reject: 'Não',
      choose: 'Escolher',
      upload: 'Carregar',
      cancel_1: 'Cancelar',
      dayNames: ['domingo', 'segunda-feira', 'terca-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
      dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
      monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
      today: 'Hoje',
      weekHeader: 'Sem',
      weak: 'Semana',
      medium: 'Média',
      strong: 'Forte',
      passwordPrompt: 'Introduzir palavra-passe',
      emptyMessage: 'Não há resultados',
      emptyFilterMessage: 'Não há resultados',
    },
    aggrid: {
      // Set Filter
      selectAll: '(Select All)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Procurar...',
      blanks: '(Blanks)',
      noMatches: 'No matches',

      // Number Filter & Text Filter
      filterOoo: 'Filter...',
      equals: 'Equals',
      notEqual: 'Not equal',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',

      // Number Filter
      lessThan: 'Less than',
      greaterThan: 'Greater than',
      lessThanOrEqual: 'Less than or equal',
      greaterThanOrEqual: 'Greater than or equal',
      inRange: 'In range',
      inRangeStart: 'from',
      inRangeEnd: 'to',

      // Text Filter
      contains: 'Contains',
      notContains: 'Not contains',
      startsWith: 'Starts with',
      endsWith: 'Ends with',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',

      // Filter Conditions
      andCondition: 'AND',
      orCondition: 'OR',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',

      // Side Bar
      columns: 'Columns',
      filters: 'Filters',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Group',

      // Row Drag
      rowDragRows: 'rows',

      // Other
      loadingOoo: 'Loading...',
      noRowsToShow: 'No Rows To Show',
      enabled: 'Enabled',

      // Menu
      pinColumn: 'Fixar coluna',
      pinLeft: 'Fixar coluna à esquerda',
      pinRight: 'Fixar coluna à direita',
      noPin: 'Não fixar',
      valueAggregation: 'Value Aggregation',
      autosizeThiscolumn: 'Redimensionar esta coluna',
      autosizeAllColumns: 'Redimensionar todas as colunas',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Redefinir Colunas',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithGroupHeaders: 'Copy with Group Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'More',
      to: 'to',
      of: 'of',
      page: 'Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      firstPage: 'First Page',
      previousPage: 'Previous Page',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',

      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      autoRotate: 'Auto Rotate',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      lineDash: 'Line Dash',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',

      // ARIA
      ariaChecked: 'checked',
      ariaColumn: 'Column',
      ariaColumnGroup: 'Column Group',
      ariaColumnList: 'Column List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaDateFilterInput: 'Date Filter Input',
      ariaDefaultListName: 'List',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterInput: 'Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterToValue: 'Filter to value',
      ariaFilterValue: 'Filter Value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaHidden: 'hidden',
      ariaIndeterminate: 'indeterminate',
      ariaInputEditor: 'Input Editor',
      ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaSearch: 'Search',
      ariaSortableColumn: 'Press ENTER to sort',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaUnchecked: 'unchecked',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Search filter values',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
      ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
      ariaDropZoneColumnComponentSortAscending: 'ascending',
      ariaDropZoneColumnComponentSortDescending: 'descending',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.',
    },
    /**
     * ORDER ASCENDING FROM HERE
     */
    ' code-management': 'Gestão de códigos',
    ' supplier-companies': 'Empresas de avaliação',
    'bde-filter': 'BdE',
    'billing-info': 'Dados de faturação',
    'commercial-manager-user': 'Utilizador gestor comercial',
    'delivered-to-client': 'Entregues ao cliente',
    'payment-summary': 'Resumo dos pagamentos',
    'process-manager-user': 'Utilizador gestor de processos',
    'quality-control': 'Controlo de qualidade',
    'recived-from-registe': 'Recebidas do registo de propriedades',
    'requester-companies': 'Prescritores',
    'sent-to-register': 'Enviadas para o registo de propriedades',
    'simple-notes': 'Notas simples',
    'other-orders': 'Outros pedidos',
    'other-works': 'Outros trabalhos',
    avm: 'AVM',
    'technical-manager-user': 'Utilizador gestor de técnicos',
    'users-credentials': 'Utilizadores com credenciais',
    'validation-finished': 'Validação concluída',
    academy: 'Academy',
    acceptancePending: 'A aguardar aceitação',
    AccessFloor: 'Acessos ao edifício',
    ACCOUNT_MANAGEMENT_accountManagement: 'Configurações de licença',
    ACCOUNT_MANAGEMENT_active: 'Ativo',
    ACCOUNT_MANAGEMENT_activePlan: 'Plano ativo',
    ACCOUNT_MANAGEMENT_availableContractPlans: 'Deseja contratar um plano? Consulte nossos planos disponíveis!',
    ACCOUNT_MANAGEMENT_availablePlans: 'Planos disponíveis',
    ACCOUNT_MANAGEMENT_billingData: 'Detalhes da faturação',
    ACCOUNT_MANAGEMENT_buyLicense: 'Subscrever',
    ACCOUNT_MANAGEMENT_cancelLicense: 'Cancelar licença',
    ACCOUNT_MANAGEMENT_cancelLicenseDowngrade: 'Anular atualização',
    ACCOUNT_MANAGEMENT_changePlanMessage: 'Vamos proceder à atualização da sua licença. A cobrança será efetuada no método de pagamento inserido no seu perfil.',
    ACCOUNT_MANAGEMENT_connectionsNetwork: 'Minha rede de conexões',
    ACCOUNT_MANAGEMENT_contractPlanMessage: 'Tem a certeza de que deseja subscrever este plano?',
    ACCOUNT_MANAGEMENT_data: 'Dados',
    ACCOUNT_MANAGEMENT_expired: 'Caducado',
    ACCOUNT_MANAGEMENT_finished: 'Expirou em',
    ACCOUNT_MANAGEMENT_invoices: 'Histórico de faturação',
    ACCOUNT_MANAGEMENT_invoicesHistory: 'Histórico de faturas',
    ACCOUNT_MANAGEMENT_licenseDetail: 'Detalhe da licença',
    ACCOUNT_MANAGEMENT_monthActiveUsers: 'utilizadores ativos este mês',
    ACCOUNT_MANAGEMENT_moreInfo: 'Mais informações',
    ACCOUNT_MANAGEMENT_nextBilling: 'Próxima faturação em',
    ACCOUNT_MANAGEMENT_nextBillingEstimation: 'Estimativa da próxima cobrança',
    ACCOUNT_MANAGEMENT_paymentHistory: 'Histórico de pagamentos',
    ACCOUNT_MANAGEMENT_paymentMethods: 'Método de pagamento',
    ACCOUNT_MANAGEMENT_paymentPlan: 'Plano de pagamento',
    ACCOUNT_MANAGEMENT_payMethodToAddAppraisalMessage: 'Lembre-se que para registrar uma nova avaliação você deve ter inserido um método de pagamento.',
    ACCOUNT_MANAGEMENT_priceNotIncludeVAT: '*O preço não inclui IVA.',
    ACCOUNT_MANAGEMENT_profile: 'Configurações do perfil',
    ACCOUNT_MANAGEMENT_reportCustomization: 'Configuração do relatório',
    ACCOUNT_MANAGEMENT_rollbackPlanMessage: 'Tem a certeza de que deseja cancelar a atualização do plano?',
    ACCOUNT_MANAGEMENT_totalWithoutTaxes: 'Total sem impostos',
    ACCOUNT_MANAGEMENT_totalWithTaxes: 'Total com impostos',
    ACCOUNT_MANAGEMENT_trialPeriod: 'Período de teste',
    ACCOUNT_MANAGEMENT_trialPeriodIsActive: 'O período experimental da sua licença está ativo.',
    ACCOUNT_MANAGEMENT_trialPeriodIsExpired: 'A sua licença de teste expirou, introduza um método de pagamento válido e subscreva um dos nossos planos para continuar a desfrutar da Tasafy.',
    ACCOUNT_MANAGEMENT_willEnd: 'Irá expirar em',
    ACCOUNT_MANAGEMENT_willNotLongerStayActive: 'Deixará de estar ativo em',
    ACCOUNT_MANAGEMENT_willStartActive: 'Entrará em vigor em',
    account_number_for_payments: 'Número da conta bancária para o pagamento',
    ActiveLicensePlan: 'Plano atual',
    actives: 'Ativos',
    ADD_ASSET_addAsset: 'Adicionar ativo',
    ADD_ASSET_addNewAssetToRepositoryOption: 'Selecione esta opção se deseja adicionar um elemento inexistente ao repositório de ativos',
    ADD_ASSET_impossibleToMap: 'Não foi possível mapear as informações cadastrais, alguns dados devem ser preenchidos manualmente',
    ADD_ASSET_modifyStructure: 'Alterar estrutura do objeto de avaliação',
    ADD_ASSET_searchAssetInRepositoryOption: 'Selecione esta opção para pesquisar um elemento no repositório de ativos',
    ADD_ASSET_selectAsset: 'Selecionar ativo',
    add_backtesting: 'Añadir Backtesting',
    add_batch: 'Añadir lote',
    ADD_NEW_ASSET_FORM_add: 'Adicionar',
    ADD_NEW_ASSET_FORM_addByCadastralRefError: 'Não foi possível encontrar nenhum ativo',
    ADD_NEW_ASSET_FORM_addByCadastralRefSuccess: 'A informação do cadastro foi obtida de forma satisfatória',
    ADD_NEW_ASSET_FORM_addManualy: 'Adicionar manualmente',
    ADD_NEW_ASSET_FORM_externalSource: 'Fonte externa',
    ADD_NEW_ASSET_FORM_Block: 'Bloco',
    ADD_NEW_ASSET_FORM_book: 'Livro',
    ADD_NEW_ASSET_FORM_cadastralReference: 'Referência cadastral',
    ADD_NEW_ASSET_FORM_ConstructionYear: 'Antiguidade',
    ADD_NEW_ASSET_FORM_coordinatesOption: 'Coordenadas',
    ADD_NEW_ASSET_FORM_Floor: 'Piso',
    ADD_NEW_ASSET_FORM_Gate: 'Porta',
    ADD_NEW_ASSET_FORM_Identification: 'Identificação',
    ADD_NEW_ASSET_FORM_idufir: 'C.R.U (IDUFIR)',
    ADD_NEW_ASSET_FORM_industrialEstate: 'Polígono',
    ADD_NEW_ASSET_FORM_inscription: 'Inscrição',
    ADD_NEW_ASSET_FORM_location: 'Localização',
    ADD_NEW_ASSET_FORM_municipality: 'Município',
    ADD_NEW_ASSET_FORM_number: 'Número',
    ADD_NEW_ASSET_FORM_paper: 'Folha',
    ADD_NEW_ASSET_FORM_place: 'Local',
    ADD_NEW_ASSET_FORM_plot: 'Plot',
    ADD_NEW_ASSET_FORM_polygonPlotOption: 'POLÍGONO/ENREDO',
    ADD_NEW_ASSET_FORM_postalCode: 'Código postal',
    ADD_NEW_ASSET_FORM_propertyRegister: 'Registro de propriedade',
    ADD_NEW_ASSET_FORM_propertyRegisterNumber: 'Número cadastral de propriedade',
    ADD_NEW_ASSET_FORM_province: 'Província',
    ADD_NEW_ASSET_FORM_realState: 'Propriedade',
    ADD_NEW_ASSET_FORM_region: 'Região',
    ADD_NEW_ASSET_FORM_Stairs: 'Escada',
    ADD_NEW_ASSET_FORM_streeDoseNotExist: 'Não está no mapa de ruas',
    ADD_NEW_ASSET_FORM_streetName: 'Nome da rua',
    ADD_NEW_ASSET_FORM_streetNumberOption: 'RUA/NÚMERO',
    ADD_NEW_ASSET_FORM_Surface: 'Superfície',
    ADD_NEW_ASSET_FORM_SurfaceAndAntiquity: 'Superfície e antiguidade',
    ADD_NEW_ASSET_FORM_Typologies: 'Tipologia',
    ADD_NEW_ASSET_FORM_Use: 'Uso',
    ADD_NEW_ASSET_FORM_volume: 'Volume',
    add_new_prescriptor: '+ Adicionar novo prescritor',
    add_new_professional: '+ Adicionar novo profissional',
    add_new: 'Adicionar novo',
    add_prescriber: 'Adicionar prescritor',
    add: 'Adicionar',
    addAppraisalToInvoiceWarningAutoGlobal: 'O valor da fatura será o indicado após a seleção das avaliações.',
    addAppraisalToInvoiceWarningAutoIndividual: 'O valor da fatura será a soma dos montantes confirmados das avaliações selecionadas.',
    addAppraisalToInvoiceWarningManual: 'Os dados de faturação serão incluídos manualmente após a seleção das avaliações.',
    addCustomer: 'Añadir cliente',
    AddedDateTime: 'Data de criação',
    addedDateTime: 'Fecha de creación',
    addedUserFullName: 'Creado por',
    addIncompatibilities: 'Adicionar incompatibilidades',
    address: 'Endereço',
    Address: 'Endereço',
    AddressString: 'Endereço',
    addValorationToRequestInvoice: 'Adicionar avaliação à fatura',
    ADMIN_DASHBOARD_activeLicensees: 'Licenças ativas',
    ADMIN_DASHBOARD_dataReport: 'Relatório de dados',
    ADMIN_DASHBOARD_finishedAppraisals: 'Avaliações concluídas',
    ADMIN_DASHBOARD_inactiveLicensees: 'Licenças inativas',
    ADMIN_DASHBOARD_licensees: 'Licenças totais',
    ADMIN_DASHBOARD_licensesPerLicense: 'Licenças por plano subscrito',
    ADMIN_DASHBOARD_numberOfActiveLicensees: 'N.º de licenças ativas',
    ADMIN_DASHBOARD_numberOfInactiveLicensees: 'N.º de licenças inativas',
    ADMIN_DASHBOARD_numberOfLicensees: 'N.º de licenças totais',
    ADMIN_DASHBOARD_recordsNumber: 'N.º de registos',
    ADMIN_DASHBOARD_reportsPerLicense: 'Relatórios por plano e licença',
    ADMIN_DASHBOARD_startedAppraisals: 'Avaliações iniciadas',
    ADMIN_signInDate: 'Data de registro',
    admin: 'Administrador',
    airConditioning: 'Ar condicionado',
    alive: 'Entradas ativas',
    all: 'Todas',
    All: 'Todos',
    allInvoiced: 'Totalmente faturado',
    allowed_roles: 'Funções permitidas',
    allTranslatesEditedCorrectly: 'Todas las traducciones editadas correctamente',
    amount: 'Total',
    amountsSummary: 'Resumo de montantes',
    an_error_has_ocurred: 'Ocorreu um erro',
    APPRAISAL_addNewPortfolio: 'Adicionar carteira',
    APPRAISAL_allAssets: 'Todos os ativos',
    APPRAISAL_appraisalCancelled: 'Trabajo anulado',
    APPRAISAL_appraisalData: 'Dados da avaliação',
    APPRAISAL_DASHBOARD_advancedFilters: 'Filtros',
    APPRAISAL_DASHBOARD_claimsInAppraisalToBeValidated: 'Reclamações em relatórios por validar',
    APPRAISAL_DASHBOARD_claimsInAppraisalToRecive: 'Reclamações em relatórios por receber',
    APPRAISAL_DASHBOARD_filesToModifie: 'Processos a validar',
    APPRAISAL_DASHBOARD_validationTime: 'Tempo de validação',
    APPRAISAL_DASHBOARD_filesToRecive: 'Processos a receber',
    APPRAISAL_DASHBOARD_managementTime: 'Tempo de gerenciamento',
    APPRAISAL_DASHBOARD_FILTER_advancedFilters: 'Filtros',
    APPRAISAL_DASHBOARD_filterBy: 'Filtrar por:',
    APPRAISAL_DASHBOARD_goToClaims: 'Ir para as reclamações',
    APPRAISAL_DASHBOARD_goToIncidents: 'Ir para os incidentes',
    APPRAISAL_DASHBOARD_goToPendingToDeliverReport: 'Ir para a aguardar entrega do relatório',
    APPRAISAL_DASHBOARD_goToPendingToSchedule: 'Ir para a aguardar agendamento',
    APPRAISAL_DASHBOARD_greeting: 'Bom dia,',
    APPRAISAL_DASHBOARD_incidenceInReportsToBeValidated: 'Incidentes em relatórios por validar',
    APPRAISAL_DASHBOARD_lateReportDelivery: 'Relatório a aguardar entrega fora de prazo',
    APPRAISAL_DASHBOARD_lateSchedulePending: 'Trabajos a aguardar agendamento de visita fora de prazo',
    APPRAISAL_DASHBOARD_recordState: 'Estado atual dos processos',
    APPRAISAL_DASHBOARD_reportDeliverPending: 'A aguardar entrega do relatório',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resolvidas pelo avaliador',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToBeValidated: 'Revisões em relatórios por validar',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToRecive: 'Revisões em relatórios por receber',
    APPRAISAL_DASHBOARD_reviewSummary: 'Relatórios com revisões',
    APPRAISAL_DASHBOARD_schedulePending: 'A aguardar agendamento',
    APPRAISAL_DASHBOARD_summaryOfClaims: 'Relatórios com reclamações',
    APPRAISAL_DASHBOARD_summaryOfFinishedFiles: 'Resumo dos processos concluídos',
    APPRAISAL_DASHBOARD_summaryOfIncidence: 'Relatórios com incidentes',
    APPRAISAL_DASHBOARD_summaryOfUnmodifiedFiles: 'Resumo dos processos não concluídos',
    APPRAISAL_DASHBOARD_ToRecive: 'A receber',
    APPRAISAL_DASHBOARD_toResolve: 'Por resolver',
    APPRAISAL_DASHBOARD_totalOrders: 'Total de empregos: ',
    APPRAISAL_DASHBOARD_toValidate: 'Por validar',
    APPRAISAL_DETAILS_orderCanceled: 'Trabajo anulado',
    APPRAISAL_disclaimer: 'Lembramos que, como solicitante da avaliação, é da sua responsabilidade informar o seu cliente que os seus dados serão cedidos ao Instituto de Valoraciones S.A. para a execução do serviço contratado.',
    APPRAISAL_lastNotificationError: 'Último envio KO',
    APPRAISAL_lastNotificationSuccesful: 'Último envio OK',
    APPRAISAL_REPORT_createTerrainCluster: 'Criar agrupamento de terrenos',
    APPRAISAL_REPORT_dateAndTime: 'Data e hora',
    APPRAISAL_REPORT_fullName: 'Nome e apelidos',
    APPRAISAL_REPORT_infoData: 'Dados do relatório',
    APPRAISAL_VALUATION_addWitness: 'Adicionar testemunha',
    APPRAISAL_VALUATION_createWitness: 'Criar testemunha',
    APPRAISAL_VALUATION_addMarketStudy: 'Adicionar pesquisa de mercado',
    APPRAISAL_VALUATION_NotMarketStudyList: 'Não há pesquisa de mercado para poder adicionar neste método',
    APPRAISAL_VALUATION_removeWitnessList: 'Remover da lista',
    APPRAISAL_VALUATION_totalInfoMessage: 'este valor provém do método de máximo legal',
    APPRAISAL_VALUATION_marketStudy: 'Pesquisa de mercado',
    APPRAISAL_VALUATION_editMarketStudyTitle: 'Modificação do estudo de mercado',
    APPRAISAL_VALUATION_editMarketStudySubtitle: 'As modificações feitas no método dão origem a um novo estudo de mercado. Você deseja que essas modificações se apliquem ao estudo de mercado existente ou acione um novo?',
    APPRAISAL_VALUATION_editMarketStudySubtitleOnlyCreate: 'Modificações feitas no método resultam em um novo estudo de mercado.',
    APPRAISAL_VALUATION_editMarketStudyConfirm: 'Modificar existente',
    APPRAISAL_VALUATION_editMarketStudyNew: 'Crie um novo',
    APPRAISAL_VALUATION_saveMethodInfo: 'Para poder salvar o método você deve adicionar pelo menos uma testemunha.',
    APPRAISAL_VALUATION_infoMigration: '* Este método foi gerado a partir de uma migração e não pode ser editado',
    APPRAISAL_value: 'Valor da avaliação',
    APPRAISAL_valueWithSuffix: 'Valor da avaliação ({{currency}})',
    appraisalAverageTimeDashboardTitle: 'Tempo médio entre etapas dos processos concluídos',
    appraisalInExchange: 'Esta avaliação encontra-se atualmente na bolsa de trabajos.',
    APPRAISALS_add_new_appraisal: 'Adicionar novo trabajo',
    APPRAISALS_addDocument: 'Adicionar documento',
    APPRAISALS_addedMaxDateTime: 'Adicionar data de fim',
    APPRAISALS_addedMinDateTime: 'Adicionar data de início',
    APPRAISALS_addInvoiceToProfessional: 'Adicionar fatura ao técnico',
    APPRAISALS_addMessage: 'Adicionar mensagem',
    APPRAISALS_addNewAsset: '+ Adicionar novo ativo',
    APPRAISALS_addNewCustomer: '+ Adicionar novo cliente',
    APPRAISALS_addNewPrescriber: '+ Adicionar novo prescritor',
    APPRAISALS_addProcessManager: 'Atribuir responsável pelo processo',
    APPRAISALS_address: 'Endereço',
    APPRAISALS_addTechnicalManager: 'Atribuir responsável pelo departamento técnico',
    APPRAISALS_addVisitDate: 'Adicionar data de visita',
    APPRAISALS_allFields: 'Todos os campos preenchidos em',
    APPRAISALS_amount: 'Montante',
    APPRAISALS_appraisalDocuments: 'Documentos da avaliação',
    APPRAISALS_appraisalIsInExchange: 'Esta avaliação encontra-se atualmente na bolsa de trabajos.',
    APPRAISALS_appraisalStays: 'Estadias da avaliação',
    APPRAISALS_appraiser: 'Avaliador',
    APPRAISALS_appraiserAllDataSentDate: 'Data de entrega',
    APPRAISALS_appraiserDatasent: 'Relatório entregue',
    APPRAISALS_appraiserDataSent: 'Relatório entregue',
    APPRAISALS_appraiserDataSentDeadline: 'Limite para entrega do relatório',
    APPRAISALS_appraiserUserFullName: 'Técnico',
    APPRAISALS_asset: 'Ativo',
    APPRAISALS_assetAssign: 'Atribuir ativo',
    APPRAISALS_assetDocuments: 'Documentos do ativo',
    APPRAISALS_importDocuments: 'Selecione toda a documentação',
    APPRAISALS_dataDocuments: 'Data de emissão',
    APPRAISALS_orderDocuments: '*Não será necessário carregar os documentos de forma ordenada, pois os mesmos terão uma ordem pré-estabelecida pelo sistema no relatório de acordo com o tipo de documento escolhido.',
    APPRAISALS_assetStays: 'Estadias do ativo',
    APPRAISALS_assignedUser: 'Utilizador atribuído',
    APPRAISALS_assignmentBag: 'Bolsa de atribuição',
    APPRAISALS_assignmentBagStatus: 'Estado na bolsa',
    APPRAISALS_assignmentBagTimeLeft: 'Tempo restante na bolsa',
    APPRAISALS_assignmentBagTimeLeftInMin: 'Tempo restante na bolsa (minutos)',
    APPRAISALS_attachDocument: '+ Anexar',
    APPRAISALS_attaching: 'A anexar...',
    APPRAISALS_basicFilters: 'Filtros básicos',
    APPRAISALS_billedAt: 'Faturado em:',
    APPRAISALS_billFuture: 'Será faturado em:',
    APPRAISALS_budgetNumber: 'Número do orçamento',
    APPRAISALS_by: 'por',
    APPRAISALS_cancel: 'Cancelar',
    APPRAISALS_cancelAppraisal: 'Anular trabajo',
    APPRAISALS_cancelationReason: 'Motivo da anulação',
    APPRAISALS_cancelled: 'Anuladas',
    APPRAISALS_cancelledDate: 'Data de anulação',
    APPRAISALS_changeUser: 'Alterar utilizador',
    APPRAISALS_chooseAppraiser: 'Selecionar profissional',
    APPRAISALS_chooseCustomer: 'Selecionar um cliente',
    APPRAISALS_chooseExecutionMode: 'Modo de execução da avaliação',
    APPRAISALS_chooseProcessManager: 'Selecionar utilizador gestor de processos',
    APPRAISALS_chooseTechnicalManager: 'Selecionar utilizador gestor de técnicos',
    APPRAISALS_claimDate: 'Data da reclamação',
    APPRAISALS_claims: 'Reclamações',
    APPRAISALS_clientComplaint: 'Reclamação de cliente',
    APPRAISALS_antecedentsInfo: `É apresentada a lista de avaliações do sistema que contenham bens com características em comum com as deste arquivo. Dependendo de quais dados são compartilhados, distinguimos três tipos de fundo:
    <ol>
      <li class="mt-2">
        Mesmo bem: Se o IDUFIR, a referência cadastral ou o imóvel e o registro coincidirem.
      </li>
      <li class="mt-2">
        Mesmo prédio: Se a rua e o número ou a referência cadastral do prédio coincidirem.
      </li>
      <li class="mt-2">
        Mesma área: Se o Uso do imóvel e a distância entre as coordenadas forem inferiores a 1 km.
      </li>
    </ol>`,
    APPRAISALS_antecedentsInfoAssetCommon: 'Os seguintes arquivos, validados nos últimos 6 meses, contêm ativos em comum com este. revê-los.',
    APPRAISALS_coincidence: 'Coincidência',
    APPRAISALS_comesFromRequest: 'Vem de uma solicitação?',
    APPRAISALS_commercialTechnicalsFee: 'Honorários supridos',
    APPRAISALS_confirm_expenses: 'Confirmar os gastos',
    APPRAISALS_confirmCoeficientChange: 'Se alterar o tipo de homogeneização, perderá os dados relacionados e incluídos até agora, deseja continuar?',
    APPRAISALS_confirmed_amount: 'Montantes/Gastos confirmados',
    APPRAISALS_contactVisitData: 'Dados de contato para a visita',
    APPRAISALS_copyAppraisal: 'Copiar trabajo',
    APPRAISALS_createdAt: 'Criada às',
    APPRAISALS_createIncident: 'Criar incidente',
    APPRAISALS_creationDate: 'Data de criação',
    APPRAISALS_pointerTerm: 'Nota de prazo',
    APPRAISALS_executionNote: 'Nota execução',
    APPRAISALS_customer: 'Cliente',
    APPRAISALS_customerDeliverables: 'Entregáveis ao cliente',
    APPRAISALS_customerName: 'Nome / Denominação social',
    APPRAISALS_date: 'Data',
    APPRAISALS_dateDataSent: 'Relatório entregue',
    APPRAISALS_dateOfIssue: 'Data de emissão',
    APPRAISALS_dateSendDataDeadline: 'Limite para entrega do relatório',
    APPRAISALS_dateVisitScheduleDeadline: 'Limite para agendar',
    APPRAISALS_deadlineDate: 'Data de encerramento',
    APPRAISALS_delete: 'Eliminar',
    APPRAISALS_deleteMessage: 'Tem a certeza de que deseja eliminar a mensagem?',
    APPRAISALS_deliverDeadlineToCustomer: 'Data limite para entrega ao cliente',
    APPRAISALS_deliveredToClient: 'Entregue ao cliente',
    APPRAISALS_deliverToCustomer: 'Entregar ao cliente',
    APPRAISALS_description: 'Descrição',
    APPRAISALS_detail: 'Detalhe',
    APPRAISALS_documentAddedTime: 'Adicionado a data',
    APPRAISALS_documents: 'Documentos',
    APPRAISALS_documentType: 'Tipo de documento',
    APPRAISALS_documentTypes: 'Tipos de documento',
    APPRAISALS_download: 'Transferir',
    APPRAISALS_downloadAll: 'Transferir tudo',
    APPRAISALS_downloading: 'A transferir',
    APPRAISALS_downloadFileCadastral: 'Baixar arquivo cadastral',
    APPRAISALS_downloadFileCadastralSuccess: 'O arquivo cadastral foi baixado e anexado à documentação do ativo',
    APPRAISALS_downloadFileCadastralError: 'Falha ao baixar e anexar o documento',
    APPRAISALS_eco8052003: 'Cumpre Eco 805/2003',
    APPRAISALS_edit: 'Editar',
    APPRAISALS_editMessage: 'Editar a mensagem',
    APPRAISALS_email: 'E-mail',
    APPRAISALS_executed_with_tasafy: 'Selecione esta opção para criar a avaliação com execução pela Tasafy',
    APPRAISALS_expected_billing_date: 'Data prevista de cobrança',
    APPRAISALS_externalReference: 'N.º da referência externa',
    APPRAISALS_externalTech: 'Profissional',
    APPRAISALS_fieldName: 'Nome do campo',
    APPRAISALS_fileName: 'Documento',
    APPRAISALS_fileNumber: 'N.º do processo',
    APPRAISALS_finished: 'Concluídas',
    APPRAISALS_finishedDate: 'Data da finalização',
    APPRAISALS_hasPrescriptor: 'Possui prescritor',
    APPRAISALS_hasRelationshipWithAppraisal: 'Há uma relação com outra avaliação?',
    APPRAISALS_hasRelationshipWithAppraisalAdditionalOrders: 'Existe uma conexão com outro trabalho?',
    APPRAISALS_hasRelationship: 'Existe um relação?',
    APPRAISALS_homogeneousCoefficientType: 'Tipo de homogeinização',
    APPRAISALS_importReportFrom: 'Importar relatório de...',
    APPRAISALS_importReportFromSubTitle: 'Apenas um arquivo pode ser selecionado. A estrutura patrimonial e todos os dados inseridos no relatório, exceto documentos e fotos, serão copiados.',
    APPRAISALS_importFormDataPick: 'Importar da coleta de dados',
    APPRAISALS_infoButtonDataPick: '*O botão importar da coleta de dados será habilitado quando a coleta de dados for validada ou finalizada.',
    APPRAISALS_importStateDataPick: '*Quando a documentação for importada, o status do arquivo mudará automaticamente para Visita concluída.',
    APPRAISALS_importStateInfo: '*Os arquivos de coleta de dados vinculados não são validados ou finalizados.',
    APPRAISALS_infoNotDocument: '“Na coleta de dados não há documentação anexa.”',
    APPRAISALS_incidenceCreationDate: 'Data de criação do incidente',
    APPRAISALS_incidentCode: 'Código do incidente',
    APPRAISALS_incidentDate: 'Data do incidente',
    APPRAISALS_incidentId: 'ID de incidente',
    APPRAISALS_incidents: 'Incidentes',
    APPRAISALS_includeAppraisalInExchange: 'Incluir a solicitação na bolsa de trabajos',
    APPRAISALS_internalReference: 'N.º da referência interna',
    APPRAISALS_internalReferenceShort: 'Nº ref. interna',
    APPRAISALS_INVOICE_commercialTechnicalFee: 'Honorarios Comerciales',
    APPRAISALS_INVOICE_edit: 'Editar dados de faturação',
    APPRAISALS_INVOICE_error: 'Ocorreu um problema no acesso aos dados de faturação. Atualize a página e tente novamente.',
    APPRAISALS_INVOICE_generate: 'Gerar ou adicionar à fatura',
    APPRAISALS_INVOICE_generateWarning: 'Tem a certeza de que deseja gerar a fatura?',
    APPRAISALS_INVOICE_see: 'Ver fatura',
    APPRAISALS_INVOICE_technicalFee: 'Honorários técnicos',
    APPRAISALS_invoice: 'Fatura',
    APPRAISALS_invoicing: 'Faturação',
    APPRAISALS_isAppraiserAllDataSentDeadlineExpired: 'Entrega do relatório fora do prazo',
    APPRAISALS_isCustomerDeadlineExpired: 'Entrega ao cliente fora do prazo',
    APPRAISALS_isExecutedByIvForce: 'É executado com Tasafy',
    APPRAISALS_isInConfirmedInvoice: 'Possui a fatura confirmada',
    APPRAISALS_IsInvoicedInOrderRequest: 'Faturado',
    APPRAISALS_isSolved: 'Está resolvida',
    APPRAISALS_lastAppraisalDatetime: 'Data da última avaliação',
    APPRAISALS_lastCompanyName: 'Empresa avaliadora',
    APPRAISALS_lastRecordId: 'N.º do processo anterior',
    APPRAISALS_lastTotalSurface: 'Superfície adotada',
    APPRAISALS_lastUpdatedUser: 'Último editor',
    APPRAISALS_lastValuation: 'Avaliação anterior',
    APPRAISALS_lastValuationValue: 'Valor da última avaliação',
    APPRAISALS_locality: 'Localidade',
    APPRAISALS_markAsDelivered: 'Validar despesas',
    APPRAISALS_markAsDeliveredConfirmation: 'Tem a certeza de que deseja marcar esta avaliação com entregue?',
    APPRAISALS_markAsResolved: 'Marcar como resolvida',
    APPRAISALS_MASSIVE_clear: 'Eliminar',
    APPRAISALS_MASSIVE_create: 'Criar novo trabajo massivo',
    APPRAISALS_MASSIVE_createNew: 'Novo trabajo massivo',
    APPRAISALS_MASSIVE_createNewValoration: 'Upload em massa de classificações de aplicativos',
    APPRAISALS_MASSIVE_description: 'Certifique-se de que tem',
    APPRAISALS_MASSIVE_drag: 'Arraste o ficheiro para aqui ou',
    APPRAISALS_MASSIVE_fileInfo: 'Informação do ficheiro processado',
    APPRAISALS_MASSIVE_goToList: 'Ir para a lista',
    APPRAISALS_MASSIVE_massiveLoadBatches: 'Lotes de trabajo massivo',
    APPRAISALS_MASSIVE_massiveListBatches: 'Carregamento de avaliações',
    APPRAISALS_MASSIVE_process: 'Processar',
    APPRAISALS_MASSIVE_selectHere: 'Selecione aqui',
    APPRAISALS_MASSIVE_templateDownload: 'transferido a versão mais recente do modelo de ficheiro',
    APPRAISALS_MASSIVE_uploadErrorMsg: 'Ocorreram problemas no envio do ficheiro Consultar a secção ""Informação do ficheiro processado"".\'',
    APPRAISALS_message: 'Mensagem',
    APPRAISALS_messageBoard: 'Mural',
    APPRAISALS_noClaims: 'Não há reclamações',
    APPRAISALS_noDiferenceWithTheOriginalAsset: 'Não há diferenças com o ativo original.',
    APPRAISALS_noIncidents: 'Não há incidentes',
    APPRAISALS_noMessages: 'Não há mensagens',
    APPRAISALS_noRecords: 'Não há registos',
    APPRAISALS_noRevisions: 'Não há revisões',
    APPRAISALS_noStais: 'Sem estadias',
    APPRAISALS_notGenerateInvoice: 'Gerar fatura de avaliação individual',
    APPRAISALS_setDailyInvoiced: 'Incluir o profissional no final do dia no pagamento automático',
    APPRAISALS_notPrint: 'Não imprimir',
    APPRAISALS_noValidate: 'Não validar',
    APPRAISALS_numberBathrooms: 'Número de casas de banho',
    APPRAISALS_numberRooms: 'Número de divisões',
    APPRAISALS_observations: 'Observações',
    APPRAISALS_justifyObservationsSurface: 'Justifique a origem da superfície utilizada e o módulo considerado',
    APPRAISALS_observationsMarketValue: 'Observações do cálculo do valor de mercado',
    APPRAISALS_of: 'de',
    APPRAISALS_openingDate: 'Data de abertura',
    APPRAISALS_orderFeatures: 'Características do trabajo',
    APPRAISALS_orderType: 'Tipo de trabajo',
    APPRAISALS_Type: 'tipo de avaliação',
    APPRAISALS_originData: 'Dados origem',
    APPRAISALS_otherExpensesAsociated: 'Despesas associadas',
    APPRAISALS_pendingClaims: 'Reclamações pendentes',
    APPRAISALS_pendingIncidents: 'Incidentes pendentes',
    APPRAISALS_phone: 'Telefone',
    APPRAISALS_portfolios: 'Carteiras',
    APPRAISALS_prescribers: 'Prescritores',
    APPRAISALS_prescriptor: 'Prescritor',
    APPRAISALS_prescriptorOffice: 'Escritório',
    APPRAISALS_print: 'Imprimir',
    APPRAISALS_processManager: 'Gestor de processos',
    APPRAISALS_propertyData: 'Dados da solicitação',
    APPRAISALS_province: 'Província',
    APPRAISALS_purpose: 'Finalidade',
    APPRAISALS_purposeBaseValue: 'Finalidade / Base valor',
    APPRAISALS_purposeAppraisal: 'Finalidade',
    APPRAISALS_reason: 'Motivo',
    APPRAISALS_reasonsComplaint: 'Motivo da reclamação',
    APPRAISALS_reasonToChooseTech: 'Diga-nos a razão para a escolha deste avaliador.',
    APPRAISALS_recalculateWorkPlan: 'É necessário recalcular o estado da obra',
    APPRAISALS_related_expenses: 'Despesas relacionadas',
    APPRAISALS_relationship: 'Avaliações relacionadas',
    APPRAISALS_relationshipAppraisalId: 'Avaliação relacionada',
    APPRAISALS_relationshipType: 'Tipo de relação',
    APPRAISALS_reply: 'Responder',
    APPRAISALS_replyMessage: 'Responder à mensagem',
    APPRAISALS_REPORT_add: '+ Adicionar',
    APPRAISALS_REPORT_addAnex: 'Adicionar anexo',
    APPRAISALS_REPORT_addChapter: 'Adicionar capítulo',
    APPRAISALS_REPORT_addConstruction: 'Adicionar construção',
    APPRAISALS_REPORT_addExpense: 'Adicionar gestos',
    APPRAISALS_REPORT_addIncome: 'Adicionar receitas',
    APPRAISALS_REPORT_addNewExpense: 'Adicionar nova despesa',
    APPRAISALS_REPORT_addNewIncome: 'Adicionar nova receita',
    APPRAISALS_REPORT_addPhoto: 'Adicionar foto',
    APPRAISALS_REPORT_addPossession: 'Adicionar posse',
    APPRAISALS_REPORT_address: 'Endereço',
    APPRAISALS_REPORT_addValuation: 'Adicionar avaliação',
    APPRAISALS_REPORT_adoptedSurface: "Superfície adotada ({{surfaceUnit}})'",
    APPRAISALS_REPORT_adoptedSurfaceType: 'Tipo de superfície adotada',
    APPRAISALS_REPORT_adoptedSurfaceHeaderType: 'Tipo de superfície adotado globalmente',
    APPRAISALS_REPORT_application: 'Aplicação',
    APPRAISALS_REPORT_appraisalAssetBuildingState: 'Estado da obra',
    APPRAISALS_REPORT_appraisalAssetLogChanges: 'Registo de alterações',
    APPRAISALS_REPORT_appraisalCommonData: 'Dados comuns do relatório',
    APPRAISALS_REPORT_appraisalObject: 'Objeto da avaliação',
    APPRAISALS_REPORT_appraise: 'Avaliar',
    APPRAISALS_REPORT_assetDeleteWarning: 'Tem a certeza de que deseja eliminar este elemento do agrupamento?',
    APPRAISALS_REPORT_assigned: 'Atribuído ({{currency}})',
    APPRAISALS_REPORT_assignedValue: 'Valor atribuído ({{currency}})',
    APPRAISALS_REPORT_automaticInfoWarning: 'Ao guardar o formulário serão recuperados os dados realçados a laranja com base na localização, perdendo os dados editados manualmente.',
    APPRAISALS_REPORT_buildability: 'Capacidade de construção',
    APPRAISALS_REPORT_buildabilityM2: 'Capacidade de construção ({{surfaceUnit}})',
    APPRAISALS_REPORT_buildable: 'Capacidade de construção',
    APPRAISALS_REPORT_building_caracteristics_economic_message: 'O imóvel está com sua vida útil esgotada, portanto, não é provável que gere qualquer atividade econômica e o método de atualização da ILEE será eliminado.',
    APPRAISALS_REPORT_buildingCharacteristics: 'Características do edifício',
    APPRAISALS_REPORT_buildingElementWarning: 'Os parâmetros estimados do elemento a construir no cálculo do valor de mercado serão recalculados. É necessário rever.',
    APPRAISALS_REPORT_builtWithCommonAreas: 'Construída com partes comuns',
    APPRAISALS_REPORT_builtWithoutCommonAreas: 'Construída sem partes comuns',
    APPRAISALS_REPORT_cadastralReference: 'Referência cadastral',
    APPRAISALS_REPORT_calculateMarketValue: 'Cálculo do valor de mercado',
    APPRAISALS_REPORT_calculationMethod: 'Método de cálculo',
    APPRAISALS_REPORT_calculations: 'Cálculos',
    APPRAISALS_REPORT_cancel: 'Cancelar',
    APPRAISALS_REPORT_capitalGain: 'Ganho/perda de capital de terra',
    APPRAISALS_REPORT_cashflows_observations: '* Só será necessário distribuir as Receitas / Despesas que foram agregadas com a Tipologia Não Periódica.',
    APPRAISALS_REPORT_cashflows_tooltip: 'Os Fluxos de Caixa Atribuídos mostram os valores totais de Receitas e Despesas geradas pela Exploração Econômica sem aplicação do percentual relativo à Receita imputada ao imóvel.',
    APPRAISALS_REPORT_cashflows: 'Fluxos de caixa',
    APPRAISALS_REPORT_checkValidations: 'Verificações',
    APPRAISALS_REPORT_cluster: 'Agrupamento',
    APPRAISALS_REPORT_clusterItems: 'Unidades do agrupamento',
    APPRAISALS_REPORT_clusters: 'Agrupamentos',
    APPRAISALS_REPORT_comparisonCalculation: 'Cálculo de comparação',
    APPRAISALS_REPORT_comparisonForConstructionHypotheses: 'Comparação para hipótese de construção',
    APPRAISALS_REPORT_conditioningLabel: 'Condicionantes, advertências e observações',
    APPRAISALS_REPORT_constructionCosts: 'Custos de construção',
    APPRAISALS_REPORT_constructionCostsWithUnits: 'Custos de contrução ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_constructionHypothesis: 'Hipótese de construção',
    APPRAISALS_REPORT_contractDocumentation: 'Documentação que certifica o estado de ocupação',
    APPRAISALS_REPORT_country: 'País',
    APPRAISALS_REPORT_createdBy: 'Criado por',
    APPRAISALS_REPORT_createResidualDynamicMethod: 'Criar método residual dinâmico',
    APPRAISALS_REPORT_criteria: 'Critério',
    APPRAISALS_REPORT_currentValuationTotalValue: 'avaliação total atual',
    APPRAISALS_REPORT_dataSourse: 'Origem dos dados',
    APPRAISALS_REPORT_dateRange: 'Prazo',
    APPRAISALS_REPORT_delete: 'Eliminar',
    APPRAISALS_REPORT_description: 'Descrição',
    APPRAISALS_REPORT_dinamicResidualMethodValue: 'Valor do método residual dinâmico',
    APPRAISALS_REPORT_dni: 'NIF/NIE/Passaporte',
    APPRAISALS_REPORT_documents: 'Documentação',
    APPRAISALS_REPORT_doMain: 'Tornar imagem principal da avaliação',
    APPRAISALS_REPORT_doMainElement: 'Tornar elemento principal',
    APPRAISALS_REPORT_door: 'Porta',
    APPRAISALS_REPORT_duplicate: 'Duplicar',
    APPRAISALS_REPORT_economicData: 'Dados econômicos',
    APPRAISALS_REPORT_economicExploitationData: 'Dados de Exploração Econômica',
    APPRAISALS_REPORT_justification: 'Justificativa para prorrogar a data de término da exploração',
    APPRAISALS_REPORT_economicExploitationUpdateDatesMessage: 'Qualquer modificação das datas, uma vez adicionadas as receitas e despesas, implicará um reajuste destas.',
    APPRAISALS_REPORT_JustificationMessage: 'A razão para prolongar a data de fim de exploração para além daquela determinada pela sua vida útil deve ser justificada.',
    APPRAISALS_REPORT_economicParameters: 'Parâmetros econômicos',
    APPRAISALS_REPORT_edit: 'Editar',
    APPRAISALS_REPORT_editAutomaticInfo: 'Editar informação automática',
    APPRAISALS_REPORT_editFields: 'Editar campos',
    APPRAISALS_REPORT_editFinisDateTime: 'Editar data de término',
    APPRAISALS_REPORT_editOrDelete: 'Pode avaliar ou eliminar este agrupamento',
    APPRAISALS_REPORT_editPhoto: 'Editar foto',
    APPRAISALS_REPORT_editStartDateTime: 'Editar data de início',
    APPRAISALS_REPORT_elementSurface: 'Superfície estimada do elemento ({{surfaceUnit}})',
    APPRAISALS_REPORT_entrance: 'Portal',
    APPRAISALS_REPORT_euroValue: 'Valor {{currency}}',
    APPRAISALS_REPORT_everyAssetMustHaveAValidSurface: 'Verifique se os seguintes ativos têm pelo menos uma superfície válida',
    APPRAISALS_REPORT_expense: 'Despesa',
    APPRAISALS_REPORT_expenses_obserservations: 'Observações sobre despesas',
    APPRAISALS_REPORT_expenses: 'Despesas',
    APPRAISALS_REPORT_exploitationType: 'Tipo de exploração',
    APPRAISALS_REPORT_extract: 'Extrair',
    APPRAISALS_REPORT_finish: 'Concluir',
    APPRAISALS_REPORT_finishDateTime: 'Data de término',
    APPRAISALS_REPORT_floor: 'Andar',
    APPRAISALS_REPORT_futureValuationTotalValue: 'avaliação total H.E.T.',
    APPRAISALS_REPORT_identification: 'Identificação',
    APPRAISALS_REPORT_idufir: 'C.R.U.(IDUFIR)',
    APPRAISALS_REPORT_imputedIncome: 'Renda Imputada',
    APPRAISALS_REPORT_imputedIncomePercentage: '% de renda imputada ao imóvel',
    APPRAISALS_REPORT_income: 'Receitas',
    APPRAISALS_REPORT_incomes_obserservations: 'Observações sobre receitas',
    APPRAISALS_REPORT_incomes: 'Renda',
    APPRAISALS_REPORT_incomesExpensesDifference: 'Receitas - Despesas',
    APPRAISALS_REPORT_incomeType: 'Tipo de receita',
    APPRAISALS_REPORT_initialParameters: 'Parâmetros inciais',
    APPRAISALS_REPORT_insurance: 'Cálculo do valor do seguro relativo a:',
    APPRAISALS_REPORT_insuranceByEco: 'seguro de acordo com a ECO',
    APPRAISALS_REPORT_insuranceByRoyalDecree: 'seguro de acordo com o R.D.',
    APPRAISALS_REPORT_isCapitalGainPercentEdited: 'Editar ganho/perda de terreno',
    APPRAISALS_REPORT_isIncomeImputedToPropertyPercentEdited: 'Editar % de renda imputada à propriedade',
    APPRAISALS_REPORT_isRecurrent: 'É recorrente?',
    APPRAISALS_REPORT_isReversionValueEdited: 'Editar valor de reversão',
    APPRAISALS_REPORT_landDescription: 'Descrição e superfície do terreno',
    APPRAISALS_REPORT_location: 'Localização',
    APPRAISALS_REPORT_locationAndSurroundings: 'Localidade, zona limítrofe e mercado',
    APPRAISALS_REPORT_marketValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_multipleSelection: 'Seleção múltipla',
    APPRAISALS_REPORT_municipality: 'Município',
    APPRAISALS_REPORT_name: 'Nome',
    APPRAISALS_REPORT_netExpenses: 'Despesas líquidas',
    APPRAISALS_REPORT_netExpensesAssigned: 'Despesas líquidas atribuídas',
    APPRAISALS_REPORT_netIncomensExpensesDiff: 'Receitas - Despesas',
    APPRAISALS_REPORT_netIncomensExpensesDiffAssigned: 'Lucro Líquido - Despesas Líquidas Atribuídas',
    APPRAISALS_REPORT_netIncomes: 'Receitas líquidas',
    APPRAISALS_REPORT_netIncomesAssigned: 'Receitas líquidas atribuídas',
    APPRAISALS_REPORT_noDocumentSelected: 'Nenhum documento selecionado',
    APPRAISALS_REPORT_non_lease_document: 'Documento de inexistência de arrendamentos',
    APPRAISALS_REPORT_noPhotos: 'Não há fotos disponíveis para este ativo',
    APPRAISALS_REPORT_noValorationClusteredAssets: 'Este ativo não pode ser avaliado por pertencer a um agrupamento',
    APPRAISALS_REPORT_numOfElementsToBuild: 'N.º de elementos a construir',
    APPRAISALS_REPORT_objectOfValuation: 'Objeto da avaliação',
    APPRAISALS_REPORT_occupationDniNif: 'Locatário DNI / NIF',
    APPRAISALS_REPORT_occupationState: 'Estado da ocupação',
    APPRAISALS_REPORT_occupationType: 'Tipo de ocupação',
    APPRAISALS_REPORT_otherMethod: 'Outro método',
    APPRAISALS_REPORT_parameters: 'Parâmetros',
    APPRAISALS_REPORT_percentage: 'Percentagem',
    APPRAISALS_REPORT_periodicity: 'Periodicidade',
    APPRAISALS_REPORT_photos: 'Fotos',
    APPRAISALS_REPORT_plotSurfaces: 'Superfície da parcela',
    APPRAISALS_REPORT_possesionSituation: 'Posse',
    APPRAISALS_REPORT_possessionType: 'Tipo de posse',
    APPRAISALS_REPORT_postalCode: 'Código postal',
    APPRAISALS_REPORT_print: 'incluir no relatório',
    APPRAISALS_REPORT_promoterBenefitsPercent: 'Benefícios do promotor (%)',
    APPRAISALS_REPORT_propertyCaracteristics: 'Características do imóvel',
    APPRAISALS_REPORT_province: 'Província',
    APPRAISALS_REPORT_recalculateMethod: 'Recalcular métodos',
    APPRAISALS_REPORT_recalculate: 'Recalcular',
    APPRAISALS_REPORT_recalculating: 'Recalculando',
    APPRAISALS_REPORT_recurrent: 'Recorrente',
    APPRAISALS_REPORT_registryNumber: 'Registo da propriedade',
    APPRAISALS_REPORT_remove: 'Apagar',
    APPRAISALS_REPORT_reorderPhotos: 'Reordenar',
    APPRAISALS_REPORT_repercusion: 'Repercussão ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_requestData: 'Dados da solicitação',
    APPRAISALS_REPORT_reversalValue: 'Valor de reversão',
    APPRAISALS_REPORT_reversionValue: 'Valor de reversão',
    APPRAISALS_REPORT_reversionValueUpdated: 'Valor de reversão atualizado',
    APPRAISALS_REPORT_riskPremium: 'Prémio de risco',
    APPRAISALS_REPORT_selectAppraisalMethod: 'Selecione o método de avaliação',
    APPRAISALS_REPORT_selectValorationType: 'Selecionar método de avaliação',
    APPRAISALS_REPORT_stairs: 'Escada',
    APPRAISALS_REPORT_startDateTime: 'Data de início',
    APPRAISALS_REPORT_staticResidualMethodValue: 'Valor do método residual estático',
    APPRAISALS_REPORT_status: 'Estado',
    APPRAISALS_REPORT_streetName: 'Nome da rua',
    APPRAISALS_REPORT_streetNumber: 'Número da rua',
    APPRAISALS_REPORT_subType: 'Subtipologia',
    APPRAISALS_REPORT_sureDeleteCluster: 'Tem a certeza de que deseja eliminar este agrupamento?',
    APPRAISALS_REPORT_sureRemoveElement: 'Tem a certeza de que deseja eliminar o elemento?',
    APPRAISALS_REPORT_surfaces: 'Superfícies',
    APPRAISALS_REPORT_surfaceType: 'Tipo de superfície',
    APPRAISALS_REPORT_terrainFeatures: 'Características do terreno',
    APPRAISALS_REPORT_terrainValuation: 'Avaliação de terrenos',
    APPRAISALS_REPORT_totalAndUpdatedValues: 'Valores totais e atualizados',
    APPRAISALS_REPORT_totalAssigned: 'Total alocado ({{currency}})',
    APPRAISALS_REPORT_totalExpenses: 'Despesas totais',
    APPRAISALS_REPORT_totalIncomes: 'Total de Renda',
    APPRAISALS_REPORT_totalSummary: 'Resumo dos totais',
    APPRAISALS_REPORT_typology: 'Tipologia',
    APPRAISALS_REPORT_units: 'Unidades',
    APPRAISALS_REPORT_updatedRents: 'Aluguel atualizado',
    APPRAISALS_REPORT_updatedRentsImputed: 'Aluguel atualizado imputado ao imóvel',
    APPRAISALS_REPORT_updatedValue: 'Atualizado ({{currency}})',
    APPRAISALS_REPORT_updatedValueEx: 'Valor atualizado',
    APPRAISALS_REPORT_urbanisticSituation: 'Situação urbanística',
    APPRAISALS_REPORT_use: 'Uso',
    APPRAISALS_REPORT_useful: 'Útil',
    APPRAISALS_REPORT_valorationMethodChangeWarning: 'Se alterar o método de avaliação, perderá os cálculos atuais.',
    APPRAISALS_REPORT_valorations: 'Avaliações',
    APPRAISALS_REPORT_valuation: 'Avaliação',
    APPRAISALS_REPORT_valuationInfoMessage: 'Apenas é permitido um método de cada tipo por avaliação',
    APPRAISALS_REPORT_valuationMethods: 'Métodos de avaliação',
    APPRAISALS_REPORT_valuations: 'Avaliações',
    APPRAISALS_REPORT_valuationTotalValue: 'avaliação total',
    APPRAISALS_REPORT_valueByResidualStatic: 'Valor por método residual estático',
    APPRAISALS_REPORT_view: 'Ver detalhe',
    APPRAISALS_REPORT_weight: 'Peso (%)',
    APPRAISALS_REPORT_workPlanCreateRDMethodMessage: 'Quando o resíduo dinâmico é criado, será gerado para as unidades que fazem parte do mesmo "Estado da obra". O valor total do método residual dinâmico será distribuído entre os diferentes elementos. ',
    APPRAISALS_REPORT_workPlanCreateRDMethosAssetsMessage: 'Neste caso, as unidades para as quais o método residual dinâmico será gerado em conjunto serão as seguintes:',
    APPRAISALS_REPORT_workPlanMessageToRecalculate: 'O recálculo do Resíduo Dinâmico implica que todos os Resíduos que estavam sujeitos ao mesmo orçamento no Estado da Obra serão atualizados. Se você confirmar, será necessário recalcular as avaliações de cada ativo posteriormente. Quer fazer o recálculo? ',
    APPRAISALS_REPORT_MARKET_STUDY_CREATE: 'Criar estudo de mercado',
    APPRAISALS_REPORT_MARKET_STUDY_CREATION: 'Criação de estudo de mercado',
    APPRAISALS_REPORT_MARKET_STUDY_NOT_CREATE: 'Nenhum estudo de mercado foi criado',
    APPRAISALS_REPORT_MARKET_STUDY_ASSET_TYPE: 'Tipo de ativo',
    APPRAISALS_report: 'Relatório',
    APPRAISALS_request: 'Solicitação',
    APPRAISALS_requestNumber: 'N.º da solicitação',
    APPRAISALS_requiredFields: 'Os campos necessários são preenchidos em',
    APPRAISALS_resolutionAverageTime: 'Tempo médio de resolução',
    APPRAISALS_resolveClaim: 'Resolver reclamação',
    APPRAISALS_resolvedAt: 'Resolvida às',
    APPRAISALS_resolveIncidence: 'Resolver incidente',
    APPRAISALS_revalorationIsNeeded: 'É necessário reavaliar o ativo',
    APPRAISALS_review: 'Revisão',
    APPRAISALS_revisionReason: 'Motivo da revisão',
    APPRAISALS_scheduledVisitDate: 'Data de visita agendada',
    APPRAISALS_seeRelatedAppraisal: 'Ver avaliação relacionada',
    APPRAISALS_selectAppraisal: 'Selecionar avaliação',
    APPRAISALS_selectOrder: 'Selecione o trabalho',
    APPRAISALS_selectPrescriptor: 'Selecionar um prescritor',
    APPRAISALS_selectRelationshipAppraisal: 'Selecionar avaliação',
    APPRAISALS_selectRequest: 'Selecionar solicitação',
    APPRAISALS_selectRequestElements: 'Escolher elementos da solicitação',
    APPRAISALS_simpleNote: 'Nota simples',
    APPRAISALS_solvedDate: 'Data de resolução',
    APPRAISALS_solvedMaxDateTime: 'Data de resolução fim',
    APPRAISALS_solvedMinDateTime: 'Data de resolução início',
    APPRAISALS_startValidation: 'Iniciar validação',
    APPRAISALS_startValidationConfirmation: 'Tem a certeza de que deseja iniciar a validação desta avaliação?',
    APPRAISALS_status: 'Estado',
    APPRAISALS_stay: 'Estadia',
    APPRAISALS_target: 'Finalidade',
    APPRAISALS_techManager: 'Gestor de técnicos',
    APPRAISALS_technicalsFee: 'Honorários técnicos',
    APPRAISALS_thereAreNoItems: '* Não há elementos',
    APPRAISALS_totalClaims: 'Reclamações totais',
    APPRAISALS_totalIncidents: 'Incidentes totais',
    APPRAISALS_history: 'Histórico',
    APPRAISALS_useAssetRepository: 'Selecione esta opção para criar a avaliação com execução pela Tasafy',
    APPRAISALS_userType: 'Tipo de utilizador',
    APPRAISALS_validate: 'Validar',
    APPRAISALS_validateJustification: 'Justificação',
    APPRAISALS_validateJustificationInfo: 'Já existe uma avaliação ATUAL com esses dados cadastrais. Se não der o mesmo valor, justifique.',
    APPRAISALS_startValidationJustificationInfo: 'Já existe uma avaliação ATUAL com esses dados cadastrais. Você pode verificá-lo em segundo plano.',
    APPRAISALS_validateAppraisalConfirmation: 'Tem a certeza de que deseja validar esta avaliação?',
    APPRAISALS_validateAssetsOfAnAppraisal: 'Validar ativos de uma avaliação',
    APPRAISALS_validateExpenses: 'Validar despesas',
    APPRAISALS_validateExpensesConfirmation: 'Tenha em atenção que, depois de validadas as despesas, não poderão ser editadas. Tem a certeza de que pretende validar as despesas?',
    APPRAISALS_validatingExpenses: 'A validar despesas',
    APPRAISALS_validation: 'Validação',
    APPRAISALS_validationDatetime: 'Data de validação',
    APPRAISALS_validationFinishDatetime: 'Data de supervisão',
    APPRAISALS_validationFinished: 'Validação concluída',
    APPRAISALS_validationStarted: 'Validação iniciada',
    APPRAISALS_valorationTotalValue: 'Valor da avaliação',
    APPRAISALS_valorationValue: 'Valor',
    APPRAISALS_VALUATION_adoptedTotalSurfaceArea: "Superfície adotada ({{surfaceUnit}})'",
    APPRAISALS_VALUATION_atLeastOneSurfaceWithValue: 'Pelo menos uma superfície útil',
    APPRAISALS_VALUATION_back: 'Voltar',
    APPRAISALS_VALUATION_save_selection: 'Salvar seleção',
    APPRAISALS_VALUATION_buildingPhysicDeprecation: 'Dep. física do edifício',
    APPRAISALS_VALUATION_buildingUseRequired: 'Características do edifício:  uso do edifício',
    APPRAISALS_VALUATION_buildingYearRequired: 'Características do edifício:  ano de construção',
    APPRAISALS_VALUATION_calculationDocument: 'Documento de cálculo',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Despesas necessárias adotadas %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostCurrent: 'Custo de construção - Atual',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostTotal: 'Custo de construção total',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progresso dos gastos %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanFormMessage: 'Os valores de construção são calculados com base na percentagem de progresso da obra. O valor de custo provém do distribuído no separador do Estado de obra.',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanLink: 'Cálculo em função do progresso da obra',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanManualEditing: 'Editar percentagem do progresso nas despesas',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanMessage: '* As despesas necessárias são calculadas com base nos custos totais de construção da unidade (tabela "em estado da obra") tendo em conta a % aplicada pelo grau de progresso da obra. Consulte os detalhes em "Cálculo em função do progresso da obra".',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpenses: 'Despesas necessárias %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Despesas necessárias - Atual',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanProgressPercentage: 'Progresso da obra %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanTitle: 'Construção - Custos e despesas',
    APPRAISALS_VALUATION_construction: 'Construção',
    APPRAISALS_VALUATION_constructionCost: 'Custos de construção',
    APPRAISALS_VALUATION_constructionCostsTitle: 'Custos de construção',
    APPRAISALS_VALUATION_constructionCostTotalValue: 'Custos de construção ({{currency}})',
    APPRAISALS_VALUATION_constructionCostUnitValue: 'Custos de contrução ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_constructionExpenses: 'Despesas de construção',
    APPRAISALS_VALUATION_direction: 'Endereço',
    APPRAISALS_VALUATION_distance: 'Distância',
    APPRAISALS_VALUATION_elementPhysicDeprecation: 'Dep. física do elemento',
    APPRAISALS_VALUATION_financialExpenses: 'Despesas financeiras',
    APPRAISALS_VALUATION_functionalDeprecation: 'Dep. funcional',
    APPRAISALS_VALUATION_ground: 'Solo',
    APPRAISALS_VALUATION_KCoefficient: 'K (Coeficiente para passar para novo)',
    APPRAISALS_VALUATION_KeInfo: 'Ke: coeficiente de homogeneização para unidades específicas que são melhores ou piores para homogeneização geral',
    APPRAISALS_VALUATION_KhInfo: 'Kh: coeficiente de ajuste entre o valor de mercado e o valor da hipoteca. Se os requisitos do artigo 12.3 da ordem ECO forem atendidos, aplicar o coeficiente Kh e incluir também os avisos MER1 ou MER2',
    APPRAISALS_VALUATION_marketValue: 'Valor de mercado',
    APPRAISALS_VALUATION_marketValueEuroM2: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_marketValueEuroM2Month: 'Valor de mercado ({{currency_per_surfaceUnit}}/mês)',
    APPRAISALS_VALUATION_euroM2Month: '({{currency_per_surfaceUnit}}/mês)',
    APPRAISALS_VALUATION_monthly: 'Mensal',
    APPRAISALS_VALUATION_moreInfo: 'Mais informações',
    APPRAISALS_VALUATION_necessaryConstructionExpensesPercentageRequired: 'Completar "Calculations according to the progress of the work"',
    APPRAISALS_VALUATION_necessaryExpenses: 'Despesas necessárias',
    APPRAISALS_VALUATION_observations: 'Observações',
    APPRAISALS_VALUATION_promoterBenefits: 'Benefícios do promotor',
    APPRAISALS_VALUATION_proposalValue: 'Proposta de valor',
    APPRAISALS_VALUATION_recalulateWorkPlan: 'É necessário recalcular o estado da obra e atribuir uma superfície a este ativo.',
    APPRAISALS_VALUATION_repercussion: 'Repercussão',
    APPRAISALS_VALUATION_method: 'Método',
    APPRAISALS_VALUATION_requiredErrorHeader: 'Falta campos necessários para este método',
    APPRAISALS_VALUATION_requiredErrorMessage: 'Falta algum dos seguintes dados:',
    APPRAISALS_VALUATION_requiredSurface: 'Para continuar é necessário selecionar uma superfície',
    APPRAISALS_VALUATION_selectSurfaceForValuation: 'Selecione uma superfície para utilizar nesta avaliação',
    APPRAISALS_VALUATION_staticResidual: 'Residual estático simplificado',
    APPRAISALS_VALUATION_total: 'Total',
    APPRAISALS_VALUATION_totalAdoptedSurface: 'Es necesario definir las superficies adoptadas en el capítulo de Estado de la Obra.',
    APPRAISALS_VALUATION_totalLegalMaximumValue: 'Valor total máximo legal',
    APPRAISALS_VALUATION_totalMarketValue: 'Valor total de mercado',
    APPRAISALS_VALUATION_totalValueEuro: 'Valor totall ({{currency}})',
    APPRAISALS_VALUATION_suitableType: 'Controle de qualidade',
    APPRAISALS_VALUATION_totalValueEuroMonth: 'Valor totall ({{currency}}/mês)',
    APPRAISALS_VALUATION_tradingExpenses: 'Despesas de comercialização',
    APPRAISALS_VALUATION_typology: 'Tipologia',
    APPRAISALS_VALUATION_valueProposalChanges: 'Alterações na proposta de valor',
    APPRAISALS_VALUATION_valueProposalChangesObservations: 'Observações na proposta de valor',
    APPRAISALS_VALUATION_yearly: 'Anual',
    APPRAISALS_VALUTION_comparableList: 'Lista de comparáveis',
    APPRAISALS_version: 'Versão',
    APPRAISALS_viewRequest: 'Ver solicitação',
    APPRAISALS_vinculation: 'Tipo de relação',
    APPRAISALS_visit: 'Visita',
    APPRAISALS_limit: 'Prazo final',
    APPRAISALS_absent: 'Ausente',
    APPRAISALS_visitDateEnd: 'Agendada data de fim',
    APPRAISALS_visitDates: 'Datas de visita',
    APPRAISALS_visitDateStart: 'Agendada data de início',
    APPRAISALS_visitFinished: 'Visita concluída',
    APPRAISALS_visitName: 'adsasd',
    APPRAISALS_visitScheduled: 'Agendada',
    APPRAISALS_visitScheduleDeadline: 'Limite para agendar',
    APPRAISALS_visitStarted: 'Visita iniciada',
    APPRAISALS_visitType: 'Tipo de visita',
    APPRAISALS_watchCustomerDetail: 'Ver ficha do cliente',
    APPRAISALS_watchPrescriptorDetail: 'Ver ficha do prescriptor',
    APPRAISALS_watchProfile: 'Ver perfil',
    APPRAISALS_withClaims: 'Com reclamações',
    APPRAISALS_withClaimsMaxDateTime: 'Data do incidente fim',
    APPRAISALS_withClaimsNotResolved: 'Com reclamações não resolvidas',
    APPRAISALS_withIncidents: 'Com incidentes',
    APPRAISALS_withIncidentsMinDateTime: 'Data de incidente início',
    APPRAISALS_withIncidentsNotResolve: 'Com incidentes não resolvidos',
    APPRAISALS_withIncidentsActive: 'Com incidentes ativos',
    appraisals: 'Avaliações',
    AppraisalStatus_1: '1 - ENTRADA',
    AppraisalStatus_10: '10 - VALIDAÇÃO CORRETA',
    AppraisalStatus_11: '11 - ENTREGUE AO CLIENTE',
    AppraisalStatus_2: '2 - RESPONSÁVEL ATRIBUÍDO',
    AppraisalStatus_3: '3 - TÉCNICO ATRIBUÍDO',
    AppraisalStatus_4: '4 - VISITA AGENDADA',
    AppraisalStatus_5: '5 - VISITA INICIADA',
    AppraisalStatus_6: '6 - VISITA CONCLUÍDA',
    AppraisalStatus_7: '7 - RELATÓRIO ENTREGUE',
    AppraisalStatus_8: '8 - VALIDADOR ATRIBUÍDO',
    AppraisalStatus_9: '9 - VALIDAÇÃO INICIADA',
    appraisalsWithInvoice: 'Avaliações com fatura',
    appraiser: 'Avaliador',
    Appraiser: 'Profissionais',
    appraiserFee: 'Honorários técnico',
    Ascensor: 'Elevadores',
    assessments: 'Avaliações',
    ASSET_BUILDING_addDynamicResidual: 'Adicionar método residual dinâmico',
    ASSET_BUILDING_addingMethod: 'Añadiendo ...',
    ASSET_BUILDING_basicProject: 'Projeto básico',
    ASSET_BUILDING_basicProjectDatetime: 'Data da elaboração do Projeto Básico',
    ASSET_BUILDING_basicProjectVisaDatetime: 'Data de visto do Projeto Básico',
    ASSET_BUILDING_buildingAverageConstructionCost: 'Custos de contrução médios da obra ({{currency_per_surfaceUnit}})',
    ASSET_BUILDING_buildingEstimatedDate: 'Data estimada para o fim da obra',
    ASSET_BUILDING_buildingEstimatedDuration: 'Duração estimada da obra (meses)',
    ASSET_BUILDING_buildingLicense: 'Licença de obras',
    ASSET_BUILDING_buildingLicenseDatetime: 'Data da licença de obras',
    ASSET_BUILDING_buildingLicenseExpirationDatetime: 'Validade da licença de obras',
    ASSET_BUILDING_buildingProgressGrade: 'Grau de progresso da obra (%)',
    ASSET_BUILDING_buildingStateObservations: 'Observações sobre o estado da obra',
    ASSET_BUILDING_buildingTotalSurface: 'Superfície total da obra ({{surfaceUnit}})',
    ASSET_BUILDING_builingStateInitDatetime: 'Data de início da obra',
    ASSET_BUILDING_chapter: 'Capítulo',
    ASSET_BUILDING_chapterAmount: 'Montante ({{currency}})',
    ASSET_BUILDING_chapterDescription: 'Descrição do capítulo',
    ASSET_BUILDING_chapterOverTotal: 'Capítulo sobre o total (%)',
    ASSET_BUILDING_chapterProgress: 'Progresso do capítulo (%)',
    ASSET_BUILDING_currentBuildingStateObservations: 'Estado atual da obra',
    ASSET_BUILDING_documentation: 'documentação',
    ASSET_BUILDING_dynamicResidualSummary: 'Resumo dos valores do método residual dinâmico',
    ASSET_BUILDING_executionBuildingBudget: 'Orçamento para a execução da obra',
    ASSET_BUILDING_executionProject: 'Projeto de execução',
    ASSET_BUILDING_executionProjectDatetime: 'Data da elaboração do Projeto de Execução',
    ASSET_BUILDING_executionProjectVisaDatetime: 'Data de visto do Projeto de Execução',
    ASSET_BUILDING_needToReviewCashflow: '* Devido às modificações feitas é necessário refazer a distribuição do fluxo de caixa',
    ASSET_BUILDING_recalculateMethod: 'Método de recalcular',
    ASSET_BUILDING_notRecalculateMethod: 'Não é possível recalcular porque o arquivo vinculado vem de Borsan. O método deve ser excluído e criado novamente.',
    ASSET_BUILDING_removeDynamicMethodConfirmation: 'O método residual estático que afeta os seguintes ativos será eliminado: \n \n {{assets}}',
    ASSET_BUILDING_totalBudget: 'Total do orçamento ({{currency}})',
    ASSET_BUILDING_totalProgress: 'Progresso global (%)',
    ASSET_BUILDING_workPlanConstructionCost: 'Custo de construção da obra',
    ASSET_CLUSTER_DETAIL_adoptedSurface: 'Superfície adotada',
    ASSET_CLUSTER_DETAIL_buildingData: 'Dados do edifício',
    ASSET_CLUSTER_DETAIL_buildingUse: 'Uso do edifício',
    ASSET_CLUSTER_DETAIL_censusSection: 'Secção censual',
    ASSET_CLUSTER_DETAIL_clusterDetails: 'Dados do agrupamento',
    ASSET_CLUSTER_DETAIL_clusterUnits: 'Unidades de cluster',
    ASSET_CLUSTER_DETAIL_constructionYearWightedObservations: 'Observações sobre a ponderação do ano de construção',
    ASSET_CLUSTER_DETAIL_name: 'Nome',
    ASSET_CLUSTER_DETAIL_observations: 'Observações sobre agrupamento',
    ASSET_CLUSTER_DETAIL_reformYear: 'Ano de remodelação',
    ASSET_exploitingAboveGround: 'Use acima do grau ({{surfaceUnit}})',
    ASSET_isEconomicExploit: 'Exploração econômica?',
    ASSET_TREE_childrenWithIncompatibilities: 'Filhos com características incompatíveis',
    ASSET_TREE_unfinishedAssetWarning: 'Não é possível agrupar itens não concluídos',
    assetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Superficie construida Calculado',
    assetRepartitionErrorMessage: 'A soma da distribuição excede o custo total da obra',
    ASSETS_accesibility: 'Acessibilidade',
    ASSETS_actualProductionsInLastThreeYears: 'Produções reais dos últimos 3 anos',
    ASSETS_add_new_asset: 'Adicionar novo ativo',
    ASSETS_address: 'Endereço',
    ASSETS_addRoom: '+ Adicionar estadia',
    ASSETS_agrologicalClasses: 'Classes agrológicas',
    ASSETS_alarmSystem: 'Sistema de alarme',
    ASSETS_animalHusbrandy: 'Livestock',
    ASSETS_architecturalHomogeneity: 'Homogeneidade arquitetónica',
    ASSETS_assets: 'Ativos',
    ASSETS_assetState: 'Estado do ativo',
    ASSETS_atLeastOneBathAndOneBedroomWarning: 'Deve existir pelo menos uma casa de banho e um quarto',
    ASSETS_averageAltitude: 'Altitude média',
    ASSETS_averageAnnualIncome: 'Rendimento médio anual disponível',
    ASSETS_basementSurface: 'Superfície do porão',
    ASSETS_basicFacilities: 'Instalações básicas',
    ASSETS_book: 'Livro',
    ASSETS_borrowersHabitualResidence: 'Residência habitual do mutuário',
    ASSETS_buildability: 'Capacidade de construção',
    ASSETS_buildingData: 'Dados do edifício',
    ASSETS_buildingDevelopment: 'Desenvolvimento edificatório',
    ASSETS_buildingsLinkedToExploitation: 'Edifícios vinculados à exploração',
    ASSETS_buildingsOwnEconomicActivity: 'Atividade econômica própria dos edifícios',
    ASSETS_buildingUse: 'Uso',
    ASSETS_builtInWardrobes: 'Armários embutidos',
    ASSETS_builtWithCommonSurfaceM2: 'Superfície construída com partes comuns ({{surfaceUnit}})',
    ASSETS_builtWithoutCommonSurfaceM2: 'Superfície construída sem partes comuns ({{surfaceUnit}})',
    ASSETS_cadastralInformation: 'Informação cadastral',
    ASSETS_cadastralReference: 'Referência cadastral',
    ASSETS_cadastralReferenceContainerBuilding: 'Referência cadastral do edifício',
    ASSETS_cadastralSurface: 'Cadastral',
    ASSETS_cadastralSurfaceM2: "Superfície cadastral ({{surfaceUnit}})'",
    ASSETS_caracteristicUse: 'Uso característico',
    ASSETS_ceilingType: 'Tipo de telhado',
    ASSETS_characteristicAntiquity: 'Antiguidade característica',
    ASSETS_checkedSurface: 'Verificada',
    ASSETS_checkedSurfaceM2: 'Superfície verificada ({{surfaceUnit}})',
    ASSETS_clearanceHeight: 'Altura livre',
    ASSETS_closedSecurityCircuit: 'Circuito fechado de segurança',
    ASSETS_clothesLine: 'Varal',
    ASSETS_coefTableBackground: 'Background Table Coefficient',
    ASSETS_coldHoursSunnyHours: 'Horas frias / horas do sol',
    ASSETS_commonFacilitiesSurface: 'Superfície de instalações comuns',
    ASSETS_communications: 'Comunicações',
    ASSETS_compatibleUses: 'Usos compatíveis',
    ASSETS_compositionAndSize: 'Composição e tamanho',
    ASSETS_conditioning: 'Acondicionamento',
    ASSETS_conservationState: 'Estado de conservação',
    ASSETS_constructionYear: 'Ano de construção',
    ASSETS_coreType: 'Tipo de núcleo',
    ASSETS_country: 'País',
    ASSETS_createdBy: 'Criado por',
    ASSETS_creationDate: 'Data de criação',
    ASSETS_criticalJudgement: 'Juízo crítico',
    ASSETS_cultivationAge: 'Idade da cultura',
    ASSETS_cultivationPlantingFramework: 'Quadro de plantio de safra',
    ASSETS_cultivesAndUses: 'Culturas e usos',
    ASSETS_currentInfraestructure: 'Infraestrutura existente',
    ASSETS_currentPlanning: 'Planeamento em vigor',
    ASSETS_currentPricSaleRental: 'Intervalos de preços atuais de venda/arrendamento (indicar valores máximos e mínimos dos métodos de avaliação utilizados)',
    ASSETS_DefinitiveQualificationDateTime: 'Data de classificação definitiva',
    ASSETS_delete: 'Eliminar',
    ASSETS_demand: 'Procura',
    ASSETS_demandRevaluationExpectations: 'Expectativas oferta-procura e revalorização',
    ASSETS_depth: 'Profundidade',
    ASSETS_description: 'Descrição',
    ASSETS_distribution: 'Distribuição',
    ASSETS_divisibility: 'Divisibilidade',
    ASSETS_door: 'Porta',
    ASSETS_dragBluePin: '* Tem de arrastar o pino azul para a posição correta. Uma vez selecionada a localização, deve confirmá-la.',
    ASSETS_duplicate: 'Duplicar',
    ASSETS_economicExploitationObservations: 'Descrição da exploração',
    ASSETS_editActualProductionsInLastThreeYears: 'Editar informações',
    ASSETS_energeticCertificateGrade: 'Classificação de energia atual (emissões)',
    ASSETS_energeticCertificateGradeConsumo: 'Classificação de energia atual (consumo)',
    ASSETS_environmentConsolidationDegreePercentage: 'Percentagem do grau de consolidão da zona limítrofe',
    ASSETS_environmentDescription: 'Descrição da zona limítrofe',
    ASSETS_environmentSignificance: 'Significância da zona limítrofe',
    ASSETS_equipmentAndServices: 'Equipamentos e serviços',
    ASSETS_equippedKitchen: 'Cozinha equipada',
    ASSETS_executionDeadlinesAndConsequences: 'Prazos de execução previstos para o desenvolvimento urbanistico do terreno. Consequências em caso de incumprimento',
    ASSETS_existingEdifications: 'Edificações existentes',
    ASSETS_Expedient: 'Processo',
    ASSETS_exploitation: 'Aproveitamento',
    ASSETS_exploitationDescription: 'Descrição da exploração',
    ASSETS_expropiation: 'Expropriação. Situação do processo de expropriação. Critérios legais aplicáveis ​​à sua avaliação',
    ASSETS_externalWoodWork: 'Carpintaria exterior',
    ASSETS_extractionAndDistributionSystem: 'Sistema de extração e distribuição',
    ASSETS_facade: 'Fachada',
    ASSETS_facilities: 'Instalações',
    ASSETS_featuresMarket: 'Características do mercado imobiliário comparável',
    ASSETS_floor: 'Andar',
    ASSETS_fundamentalBuildingElements: 'Elementos fundamentais da edificação',
    ASSETS_generate: 'Gerar',
    ASSETS_geolocate: 'Geolocalizar',
    ASSETS_groundFloorSurface: 'Área do piso térreo',
    ASSETS_groundLevel: 'Nível do solo (Regulamento ECO/805/2003)',
    ASSETS_groundQualification: 'Classificação do solo',
    ASSETS_hasActiveCrop: 'Cultivo ativo',
    ASSETS_hasActiveCultivation: 'tem cultivo ativo?',
    ASSETS_hasAirConditioning: 'Climatização',
    ASSETS_hasCommonFacilities: 'Instalações comuns',
    ASSETS_hasElevator: 'Elevador',
    ASSETS_hasEnergeticCertificate: 'Possui certificado de eficiência energética',
    ASSETS_hasFlatTerrain: 'Nível térreo',
    ASSETS_hasGas: 'Gás',
    ASSETS_hasGreenArea: 'Zonas verdes',
    ASSETS_hasHeating: 'Aquecimento',
    ASSETS_hasHotWater: 'Água quente',
    ASSETS_hasIntercom: 'Porteiro automático',
    ASSETS_hasPaving: 'Paving',
    ASSETS_hasPhone: 'Telefone',
    ASSETS_hasPipedGasNetwork: 'Rede de gás canalizado',
    ASSETS_hasPlumbingAndSanitaryEquipment: 'Canalização e equipamentos sanitários',
    ASSETS_hasPool: 'Piscina',
    ASSETS_hasPublicLighting: 'Iluminação pública',
    ASSETS_hasRolledAccess: 'Acesso rodoviário',
    ASSETS_hasSanitation: 'Saneamentos',
    ASSETS_hasSanitationNetwork: 'Rede de saneamento',
    ASSETS_hasSecurity: 'Segurança',
    ASSETS_hasSportsZone: 'Zona desportiva',
    ASSETS_hasTelephoneNetwork: 'Rede telefônica',
    ASSETS_hasTennisOrPaddle: 'Tênis / Paddle',
    ASSETS_hasWarehouse: 'Armazém',
    ASSETS_hasWaterDistribution: 'Distribuição de água',
    ASSETS_hasWaterSupply: 'Abastecimento de água',
    ASSETS_heatingType: 'Tipo de aquecimento',
    ASSETS_hotWaterType: 'Tipo de água quente',
    ASSETS_humidityRegime: 'Regime de umidade',
    ASSETS_hydrology: 'Hidrologia',
    ASSETS_idufir: 'IDUFIR',
    ASSETS_industrialBuildingCharacteristics: 'Características do edifício industrial',
    ASSETS_infrastructures: 'Infraestruturas',
    ASSETS_inscription: 'Inscrição',
    ASSETS_internalWoodType: 'Carpintaria interior',
    ASSETS_isAdequatedToCurrentPlanning: 'Adequado ao planeamento em vigor?',
    ASSETS_isEconomicExploitation: 'É uma exploração econômica?',
    ASSETS_isPlot: 'É um enredo?',
    ASSETS_jobOccupation: 'Ocupação laboral',
    ASSETS_lastRehabilitationYear: 'Ano da reabilitação',
    ASSETS_legalSituation: 'Situação legal',
    ASSETS_license: 'Licença',
    ASSETS_lngLatWarning: 'Não foi possível obter os dados da localidade e zona limítrofe',
    ASSETS_localCharacteristics: 'Características do local',
    ASSETS_characteristics: 'Características',
    ASSETS_locality: 'Localidade',
    ASSETS_localityMainFeatures: 'Principais características da localidade',
    ASSETS_locationComparedToSameBuildingElements: 'Localização do imóvel dentro do edifício em comparação com outros elementos do mesmo edifício',
    ASSETS_locationComparedToSameMunicipality: 'Localização do imóvel em comparação com outros do mesmo município',
    ASSETS_locationComparedToSameZone: 'Localização do imóvel em comparação com outros da mesma zona',
    ASSETS_locationIndustrialShip: 'Localização do navio',
    ASSETS_locationStreetType: 'Localização - Tipo de rua',
    ASSETS_loftSurface: 'Superfície do loft',
    ASSETS_LOG_currentValue: 'Valor atual',
    ASSETS_LOG_elementWithId: 'O elemento',
    ASSETS_LOG_previusValue: 'Valor anterior',
    ASSETS_LOG_was: 'foi',
    ASSETS_mainElements_Deck: 'Cobertura',
    ASSETS_mainElements_DeckDescription: 'Descrição da cobertura',
    ASSETS_mainElements_Enclosure: 'Recintos',
    ASSETS_mainElements_EnclosureDescription: 'Descrição das recintos',
    ASSETS_mainElements_Foundation: 'Fundação',
    ASSETS_mainElements_FoundationDescription: 'Descrição da fundação',
    ASSETS_mainElements_Structure: 'Estrutura',
    ASSETS_mainElements_StructureDescription: 'Descrição da estrutura',
    ASSETS_managementPhase: 'Fase de gestão',
    ASSETS_managementSystem: 'Sistema de gestão',
    ASSETS_managementTypology: 'Tipologia de gerenciamento',
    ASSETS_manuallySelection: 'Selecionar localização manualmente',
    ASSETS_changeUrbanSituationMonths: 'Prazo previsto de alteração da situação urbana do terreno (meses)',
    ASSETS_expectedObtainingLicenseMonths: 'Prazo esperado para obtenção da licença (meses)',
    ASSETS_market: 'Mercado',
    ASSETS_marketMortgageValueDifferences: 'Diferenças do valor de mercado e valor hipotecário',
    ASSETS_maxHeight: 'Altura máxima',
    ASSETS_mezzanineSurface: 'Área entre andares',
    ASSETS_microclimate: 'Microclima',
    ASSETS_minimunPlot: 'Parcela mínima',
    ASSETS_modeAndManagementSystem: 'Sistema e modo de gestão',
    ASSETS_morphology: 'Morfologia',
    ASSETS_notCorrectLocation: '* Se a localização exibida não está correta pode selecioná-la manualmente',
    ASSETS_numberOfBuildingAccesses: 'Acessos ao edifício',
    ASSETS_observations: 'Observações',
    ASSETS_ocupation: 'Ocupação',
    ASSETS_offer: 'Oferta',
    ASSETS_officeCharacteristics: 'Características do escritório',
    ASSETS_openSpace: 'Espaço aberto',
    ASSETS_otherBuildings: 'Outros edifícios',
    ASSETS_otherSurface: 'Outra superfície',
    ASSETS_paper: 'Folha',
    ASSETS_parkings: 'Parques de estacionamento',
    ASSETS_pavementType: 'Tipo de pavimento',
    ASSETS_pendingInfrastructure: 'Infraestrutura pendente',
    ASSETS_pendingInfrastructuresPercentage: 'Percentagem de infraestruturas pendentes',
    ASSETS_pendingProcedures: 'Processos pendentes para solo finalista',
    ASSETS_percentageOfValuedArea: 'Percentagem da área avaliada',
    ASSETS_percentOfWorkDone: 'Porcentagem de urbanização executada',
    ASSETS_placeName: 'Lugar',
    ASSETS_planningDevelopment: 'Desenvolvimento do planeamento',
    ASSETS_plotCode: 'Enredo',
    ASSETS_pluviometry: 'Pluviometria',
    ASSETS_podeAndManagementSystem: 'Sistema e modo de gestão',
    ASSETS_polivalentUse: 'Uso polivalente',
    ASSETS_polygonCode: 'Polígono',
    ASSETS_population: 'População N.º de habitantes',
    ASSETS_populationEvolutionPercent: 'Evolução recente da população',
    ASSETS_portal: 'Portal',
    ASSETS_postalCode: 'Código postal',
    ASSETS_predominantOfficeUse: 'O uso de escritório predomina?',
    ASSETS_productionDestination: 'Destino de produção',
    ASSETS_productToDevelopTypes: 'Produto previsto para desenvolver',
    ASSETS_prohibitedUses: 'Usos proibidos',
    ASSETS_propertyRegistrationName: 'Nome do registo da propriedade',
    ASSETS_propertySize: 'Tamanho do imóvel',
    ASSETS_ProtectionTimeLimit: 'Prazo de proteção (anos)',
    ASSETS_province: 'Província',
    ASSETS_ProvisionalQualificationDateTime: 'Data de classificação provisória',
    ASSETS_pubjectCorrespondingManagementInitiative: 'Sujeito a quem a compete a iniciativa da gestão',
    ASSETS_qualityOfBuilding: 'Qualidade das edificações',
    ASSETS_raisedFloorOrFalseCeiling: 'Piso elevado / teto falso',
    ASSETS_reformLastYear: 'Ano de remodelação',
    ASSETS_regionName: 'Região',
    ASSETS_registeredPropertyNumber: 'Registo da propriedade',
    ASSETS_registeredSurfaceM2: "Superfície registada ({{surfaceUnit}})'",
    ASSETS_registralSurface: 'Registada',
    ASSETS_registryInfo: 'Informação de registo',
    ASSETS_remarkableMicroclimates: 'Microclimas notáveis',
    ASSETS_renovation: 'Renovação',
    ASSETS_residenceType: '1.ª/2.ª habitação',
    ASSETS_roomType: 'Tipo de quarto',
    ASSETS_scopeSurface: 'Superfície da área',
    ASSETS_section: 'Secção',
    ASSETS_securityDoor: 'Security door',
    ASSETS_setBacks: 'Contratempos',
    ASSETS_setOff: 'Alívio',
    ASSETS_sourceOfWaterAndFlow: 'Origem da água e fluxo',
    ASSETS_species: 'Espécies',
    ASSETS_stairs: 'Escada',
    ASSETS_status: 'Estado',
    ASSETS_stays: 'Estadias',
    ASSETS_streetCategory: 'Categoria da rua',
    ASSETS_streetName: 'Nome da rua',
    ASSETS_streetNotExist: 'A rua não existe no mapa de ruas',
    ASSETS_streetNumber: 'Número',
    ASSETS_streetType: 'Tipo de via',
    ASSETS_structureLight: 'Estrutura de luz',
    ASSETS_subjectCorrespondingManagementInitiative: 'Sujeito a quem a compete a iniciativa da gestão',
    ASSETS_subTypology: 'Subtipologia',
    ASSETS_surface: 'Superfície',
    ASSETS_surfaceM2: "Superfície ({{surfaceUnit}})'",
    ASSETS_surfaceToDemolish: "Superfície a demolir ({{surfaceUnit}})'",
    ASSETS_surveillance: 'Vigilância',
    ASSETS_temperatures: 'Temperaturas',
    ASSETS_terrainDescription: 'Descrição do terreno',
    ASSETS_terrainFeatures: 'Características do terreno',
    ASSETS_terrainInfraestructures: 'Infraestruturas do terreno',
    ASSETS_terrainSurface: 'Superfície do terreno',
    ASSETS_terrainTexture: 'Textura do terreno',
    ASSETS_topography: 'Topografia',
    ASSETS_topographyAndAgrologicalCharacteristics: 'Topografia e características agrológicas',
    ASSETS_topologyAndSlope: 'Topologia e relevo',
    ASSETS_totalFloorsAboveGround: 'Pisos acima do solo',
    ASSETS_totalFloorsBellowGround: 'Pisos abaixo do solo',
    ASSETS_totalPlotSurface: "Superfície da parcela ({{surfaceUnit}})'",
    ASSETS_typology: 'Tipologia',
    ASSETS_unemploymentRatePercent: 'Taxa de desemprego',
    ASSETS_urbanBurdens: 'Trabajos urbanísticos (cessões, custos de urbanização, obrigações adquiridas, etc.)',
    ASSETS_urbanCoreProximity: 'Proximidade do núcleo urbano',
    ASSETS_urbanisticManagment: 'Gestão urbanística',
    ASSETS_urbanisticParameters: 'Parâmetros urbanísticos',
    ASSETS_urbanisticParams: 'Parâmetros urbanísticos',
    ASSETS_urbanisticQualificationAndManagement: 'Classificação e gestão urbanística',
    ASSETS_urbanisticQualification: 'Classificação urbanística',
    ASSETS_urbanizationCostsAndPendingCharges: 'Custos de urbanização e trabajos pendentes',
    ASSETS_urbanizationState: 'Estado da urbanização',
    ASSETS_use: 'Uso',
    ASSETS_usefulSurfaceM2: "Superfície útil ({{surfaceUnit}})'",
    ASSETS_vegetativeState: 'Estado vegetativo',
    ASSETS_volume: 'Volume',
    ASSETS_wallType: 'Tipo de paredes',
    ASSETS_withPaving: 'Pavimentação',
    ASSETS_withPipedGasNetwork: 'Rede de gás canalizado',
    ASSETS_withSanitationNetwork: 'Rede de saneamento',
    ASSETS_withStreetLighting: 'Iluminação pública',
    ASSETS_withTelephoneNetwork: 'Rede de telefones',
    ASSETS_withWaterSupply: 'Abastecimento de água',
    ASSETS_marketStudy: 'Lembre-se que esta modificação implicará mudanças nos métodos onde esta pesquisa de mercado está sendo utilizada.',
    ASSETS_marketStudyInformation: '*Lembre-se que a homogeneização deve ser discriminada para avaliações ECO sim.',
    ASSETS_marketStudyRemove: 'Tem certeza que deseja remover o estudo de mercado?',
    AssetState: 'Estado',
    assetSubTypology: 'Subtipologia',
    AssetSubTypology: 'Subtipologia',
    AssetTypology: 'Tipologia',
    AssetUse: 'Uso',
    assignRoles: 'Atribuir funções',
    Assistance: 'Assitencial',
    automaticInvoicing: 'Faturação automática',
    avm_add_valuation: 'Añadir Avaliação',
    avm_additionalInfo: 'Información adicional',
    avm_alerts: 'Alertas',
    avm_assets: 'Activos',
    avm_back: 'Volver',
    avm_backtesting_message_2: 'Apenas um backtesting pode ser gerado por pedido. Escolha um dos lotes avaliados e clique no botão "Criar backtesting". ',
    avm_backtesting_message_3: 'Escolha a avaliação que irá modificar os ativos usados ​​no backtesting.',
    avm_backtesting_message: 'O processo de backtesting está sendo concluído. Foi solicitado {{dateValue}}. Se demorar mais de 30 minutos, entre em contato com o serviço técnico.',
    avm_backtestings_files: 'Ficheros backtesting',
    avm_backtestings: 'Backtestings',
    avm_backtestingSelectedBatchValorationIdis: 'Batch Id',
    avm_batchAlertType: 'Tipo',
    avm_batchId: 'ID del lote',
    avm_batchValorationId: 'ID Valoratión',
    avm_cadastralApiBatchEstimatedFinishDatetime: 'Hora de término estimada',
    avm_cadastralApiBatchFinishedDatetime: 'Hora de término',
    avm_cadastralApiBatchStartDatetime: 'Hora de início',
    avm_cadastralApiBatchStartUserFullName: 'Nome de usuário',
    avm_cadastralApiBatchStartUserId: 'Id do usuário',
    avm_cadastralData: 'API Catastro',
    avm_calculated: 'Calculado',
    avm_checkValue_tooltip: 'AVM (A) assistido pelo avaliador conforme Guia de Supervisão BDE. O algoritmo de avaliação terá em conta verificações adicionais e pareceres especializados efetuados por um avaliador relativamente aos parâmetros utilizados na avaliação do ativo.',
    avm_checkValue: 'Verificação Assistida – AVM (A)',
    avm_choose_backtesting: 'Seleccionar Backtesting',
    avm_column_preselection: 'Pré-seleção de colunas',
    avm_confidenceIndexPercent: 'Indice de confianza (%)',
    avm_create_batch_headerRowsCount_tooltip: 'Linhas de cabeçalho que a tabela possui. Normalmente 1.',
    avm_create_batch_initialCell_tooltip: 'A célula onde a tabela começa a ser lida no Excel. Por exemplo: B2',
    avm_creationDate: 'Fecha de creación',
    avm_databaseVersion: 'Versão BD',
    avm_dbVersion: 'Versión Base de Datos',
    avm_deliveredDatetime: 'Data de entrega',
    avm_deliveredSolveBacktestingValorationId: 'Valuation Id',
    avm_deliveredUserFullName: 'Nome completo',
    avm_deliveredUserId: 'ID do usuário',
    avm_deliverToClient: 'Entregar a cliente',
    avm_deliverToClientData: 'Datos de la entrega a cliente',
    avm_detail: 'Detalle',
    avm_discrepancies_filter_ids_tooltip: 'Introduzca los IDS de los activos separados por ; para buscarlos.<br><br>Ejemplo: 1;2;3;4;5 ...',
    avm_discrepancies: 'Discrepancias',
    avm_edit_translation: 'Editar traducción',
    avm_enrichment_criteria: 'Criterios de enriquecimiento',
    avm_errorPercent: 'Error (%)',
    avm_establish_discrepancies: 'Estabelecer criterios',
    avm_estimatedFinishDatetime: 'Fecha Fin Estimada',
    avm_failureProbability: 'Probabilidad de fallo %',
    avm_file: 'Fichero',
    avm_generate_backtesting_file: 'Generar fichero backtesting',
    avm_generated_backtesting_files: 'Ficheros backtesting generados',
    avm_go_to_order_detail: 'Ver detalle pedido',
    avm_hasMoreThanOneConstructiveElement: 'Con más de un elemento constructivo (origen)',
    avm_headerRowsCount: 'Número de filas de encabezado',
    avm_hittingProbability: 'Probabilidad de acierto %',
    avm_ids_to_review_value_tooltip: 'Por padrão, eles serão todos os ativos, a menos que os ids sejam especificados',
    avm_initialCell: 'Celda inicial',
    avm_isDelivered: 'Está entregue',
    avm_isDiscrepancySolved: 'Discrepancia resuelta',
    avm_keepDiscrepancies: 'Mantener discrepancias',
    avm_login_information: 'Login information',
    avm_logins_log: 'Logins log',
    avm_orderCustomerFullName: 'Nombre del cliente del pedido',
    avm_orderDescription: 'Descripción del pedido',
    avm_orderOrderDateTime: 'Fecha del pedido',
    avm_orderType: 'Tipo de pedido',
    avm_origin: 'Origen',
    avm_pq: 'PQ',
    avm_providerAssetId: 'ID del activo',
    avm_providerAssetIds: 'IDs de activos del proveedor',
    avm_relatedAssetHasMoreThanOneConstructiveElement: 'Con más de un elemento constructivo (calculado)',
    avm_resolve_discrepancies: 'Confirmar criterios',
    avm_scriptVersion: 'Script de versão',
    avm_see_batch: 'Veja o lote',
    avm_see_valuation: 'Ver Avaliação',
    avm_solve_incidents: 'Resolver incidencias',
    avm_solving: 'Resolviendo...',
    avm_this_batch_has_been_duplicated_from: 'Este lote ha sido duplicado del lote con ID:',
    avm_this_is_a_duplicated_batch: 'Este es un Lote duplicado',
    avm_useInValorationBatchAssetSurfaceData: 'Discrepancia Superficie',
    avm_useInValorationBatchAssetTypologyData: 'Discrepancia Tipología',
    avm_useReferenceUnitValue_tooltip: 'AVM assistido por avaliador (A) conforme Guia de Supervisão BDE. O algoritmo de avaliação levará em conta verificações adicionais e pareceres especializados feitos por um avaliador. O valor de referência é obtido do arquivo de entrada, daquela coluna que foi marcada como “Valor de referência unitário do elemento principal”. Naqueles que não possuem valor, o algoritmo padrão será aplicado.',
    avm_useReferenceUnitValue: 'Avaliação assistida – AVM (A)',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'Ao selecionar esta opção, o algoritmo de avaliação irá fornecer uma área para os ativos que eles não possuem, calculada a partir do banco de dados núcleo (a versão mais recente disponível) do cluster',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Testemunhas áreas estimadas',
    avm_valorations: 'Valoraciones',
    avm_valorationState: 'Status de avaliação',
    avm_value_revision_similarity_index_tooltip: 'Este índice limita a diferença das testemunhas que vão entrar novas, com respeito às já selecionadas. Com 1 sendo a similaridade máxima, este valor deve ser aumentado para aceitar mais diferença entre os controles. Portanto, 1,15 seria o mesmo que dizer que temos um desvio de 15% na semelhança em relação aos que já foram selecionados. Não é recomendado ir acima de 1,5. ',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'Número de testigos mínimo en el clúster para calcular la superficie sobre aquellos activos que no tengan. En aquellos que no llegue a los testigos mínimos, se dejarán sin superficie y por tanto no se valorarán.',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Limite minimo de testigos para la media',
    backtesting: 'Backtesting',
    bank_account_number_for_payment: 'Número da conta bancária para o pagamento',
    basicFilters: 'Filtros básicos',
    batchAssetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Superficie construida Origen',
    batchDateTime: 'Fecha del Lote',
    BATCHES_addedUserName: 'Utilizador',
    BATCHES_batchId: 'ID',
    BATCHES_batchState: 'Estado',
    BATCHES_creationDate: 'Data de criação',
    BATCHES_creationMaxDate: 'Até',
    BATCHES_creationMinDate: 'Desde',
    BATCHES_fileName: 'Nome do ficheiro',
    BATCHES_processError: 'Erro de processamento',
    BatchStatus_0: 'Pendente',
    BatchStatus_1: 'Em processamento',
    BatchStatus_2: 'Processado corretamente',
    BatchStatus_3: 'Erro',
    batchWithId: 'Lote {{massiveBatchId}}',
    requestWithId: 'Aplicativo {{requestId}}',
    bathroomNumber: 'Número de banheiros',
    BDE_FILTER_IsReportedToBde: "Reportado ao Bde'",
    BDE_FILTER_Trimester: 'Trimestre',
    BDE_FILTER_YearReportedToBdE: 'Ano reportado ao Bde',
    bedroomNumber: 'Número de quartos',
    BILL_LIST_invoiceNumber: 'N.º da fatura',
    billing_email: 'E-mail de cobrança',
    billing_info: 'Dados de faturação',
    BILLING_INFORMATION_addAsDefaultMethod: 'Adicionar como método predefinido',
    BILLING_INFORMATION_addPaymentMethods: 'Adicionar métodos de pagamento',
    BILLING_INFORMATION_billingData: 'Dados de faturação',
    BILLING_INFORMATION_card: 'Cartão',
    BILLING_INFORMATION_cardNumber: 'Número do cartão',
    BILLING_INFORMATION_creditCardExpiryDateMonth: 'Mês de validade',
    BILLING_INFORMATION_creditCardExpiryDateYear: 'Ano de validade',
    BILLING_INFORMATION_cvv: 'CVV',
    BILLING_INFORMATION_defaultPayment: 'Opção de pagamento predefinida',
    BILLING_INFORMATION_expirationDate: 'Data de validade',
    BILLING_INFORMATION_paymentMethods: 'Métodos de pagamento',
    BILLING_INFORMATION_payments: 'Pagamentos',
    BILLING_INFORMATION_redirect: 'A redirecionar...',
    BILLING_INFORMATION_redirectToSafeLink: 'A redirecionar para um site seguro.',
    BILLING_INFORMATION_setAssDefault: 'Definir como opção de pagamento predefinida',
    BILLING_INFORMATION_sureRemoveElement: 'Tem a certeza de que deseja eliminar este método de pagamento?',
    BILLING_INFORMATION_warningDescription: 'Para o pagamento dos arquivos de avaliação efetuados, armazenamos os dados do seu cartão com total segurança sob o sistema Redsys. Para isso, insira-os na janela pop-up do banco que se abrirá ao clicar em "Adicionar formas de pagamento", processo que não acarreta cobrança no cartão.',
    BillingDataCopyOptions_0: 'Copiar dados do titular',
    BillingDataCopyOptions_1: 'Copiar dados do prescritor',
    BillingDataCopyOptions_2: 'Copiar dados da conta',
    billingFileName: 'Fatura',
    billingStatus: 'Estado da faturação',
    billingTaxType: 'Tipo de imposto',
    BillingTaxType: 'Tipo de imposto',
    billingType: 'Tipo de faturação',
    Book: 'Livro',
    BooleanQuestionValues_false: 'Não',
    BooleanQuestionValues_true: 'Sim',
    BUILDING_ELEMENT_COMPARISON_observations: 'Observações sobre a hipótese de construção',
    BUILDING_ELEMENT_valuationOtherMethod: 'Manualmente',
    BuildingInformation: 'Dados do edifício',
    buildingLastRehabilitationYear: 'Ano de reabilitação',
    Buildings: 'Edifícios',
    buildingUse: 'Uso do edifício',
    BuildingUse: 'Uso',
    buildingYear: 'Ano de construção',
    builtConstructionYear: 'Año de construcción',
    BusinessPremises: 'Estabelecimento comercial',
    BUTTON_ACTION_accept: 'Aceitar',
    BUTTON_ACTION_cancel: 'Cancelar',
    BUTTON_ACTION_reject: 'Rejeitar',
    BUTTON_ACTION_resend: 'Reenviar',
    cadastral: 'Cadastral',
    cadastralData: 'Dados cadastrais',
    cadastralReference: 'Referência cadastral',
    CadastralReference: 'Referência cadastral',
    CadastralReferenceContainerBuilding: 'Referência cadastral do edifício',
    CadastralSurfaces: 'Superfície cadastral',
    calculated: 'Calculado',
    CAN_CREATE_APPRAISAL_addPaymentMethod: 'Adicionar método de pagamento',
    CAN_CREATE_APPRAISAL_selectPlan: 'Selecione um plano',
    cancel: 'Cancelar',
    cancelled: 'Anuladas',
    cancelledDate: 'Data de anulação',
    CeilingType: 'Tipo de telhado',
    change_asset_status: 'Se você alterar o status do ativo para concluído, você perderá os dados relativos às avaliações atuais inseridas. Você deseja continuar?',
    change_asset_typology: 'As modificações feitas implicam que os métodos que não se aplicam à tipologia escolhida sejam eliminados. Você deseja continuar? ',
    change_date_justification: 'Justificação',
    change_password: 'Alterar palavra-passe',
    change_picture: 'Alterar imagem',
    CHANGE_TEMPORAL_PASSWORD_change: 'Alterar',
    CHANGE_TEMPORAL_PASSWORD_changeTemporalPassword: 'Alterar palavra-passe temporária',
    CHANGE_TEMPORAL_PASSWORD_newPassword: 'Nova palavra-passe',
    CHANGE_TEMPORAL_PASSWORD_passwordsNotMatch: 'As palavras-passe não coincidem',
    CHANGE_TEMPORAL_PASSWORD_repeatPassword: 'Repita a palavra-passe',
    CHANGE_TEMPORAL_PASSWORD_yourPasswordIsTemporal: 'A sua palavra-passe atual é temporária, deve alterá-la por razões de segurança',
    checked: 'Verificada',
    CheckedSurfaces: 'Superfície verificada',
    checkLicenseData: 'Reveja os dados da sua licença',
    ChecksRelatedToAttachedDocumentation: 'Verificações relativas à documentação em anexo',
    cif_nif: 'NIF/NIE/Passaporte/CIF',
    cif: 'CIF',
    city: 'Cidade',
    claims: 'Reclamações',
    claimsResolutionAverageTime: 'Tempo médio de resolução de reclamações',
    client: 'Cliente',
    close: 'Fechar',
    closed: 'Fechado',
    CLUSTER_addAssetToCluster: 'Adicionar ativo',
    CLUSTER_addUnit: 'Añadir unidad',
    CLUSTER_buildingUse: 'Uso de construção',
    CLUSTER_chooseClusterAssets: 'Escolha ativos',
    CLUSTER_chooseClusterType: 'Escolha o tipo de agrupamento',
    CLUSTER_clusterType: 'Tipo de agrupamento',
    CLUSTER_completeClusterData: 'Dados completos do agrupamento',
    CLUSTER_constructionYear: 'Ano de construção',
    CLUSTER_createCluster: 'Criar novo agrupamento',
    CLUSTER_createClusterMessage: 'Os elementos a serem agrupados devem ser sempre do mesmo tipo de acordo com o menu suspenso superior.',
    CLUSTER_details: 'Dados do agrupamento',
    CLUSTER_next: 'Segue',
    CLUSTER_noSelectableAssets: 'Ativos não selecionáveis',
    CLUSTER_propertyRegistry: 'Propriedade de registro',
    CLUSTER_selectableAssets: 'Ativos selecionáveis',
    CLUSTER_sureDelete: 'Tem a certeza de que deseja apagar este agrupamento?',
    CLUSTER_TERRAIN_clusterTerrain: 'Agrupamento de terrenos',
    CLUSTER_TERRAIN_typologyInfo: 'Este ativo pertenece a um agrupamento de terrenos e não é possível editar seu uso. Para su edición debe extraerlo de la agrupación. ',
    CLUSTER_typologyInfo: 'Este bem pertence a um grupo e não é possível alterar o seu estatuto ou alterar a sua utilização para terrenos / quintas rústicas ou económicas. Para a edição, você deve extraí-lo do agrupamento.',
    CLUSTER_unit: 'unidade',
    CLUSTER_valuationSurfaceSelectedMessage: 'A superfície selecionada pode ser modificada em unidades',
    CLUSTERS_canNotChangeUseToTerrain: 'Este ativo pertence a um cluster. Se você quiser convertê-lo em terreno, é necessário removê-lo desse agrupamento ',
    CLUSTERS_surfaces_error: 'É necessário adicionar as superfícies na seção Superfícies de cada ativo e selecioná-las na seção Unidades.',
    CLUSTERS_valorations_summary: 'Análise da avaliação',
    clusterSurface: 'Superfície de cluster',
    ClusterTerrainSurfaces_0: 'Útil',
    ClusterTerrainSurfaces_12: 'Construída sem partes comuns',
    ClusterTerrainSurfaces_6: 'Construída com partes comuns',
    CLUTER_reformLastYear: 'Ano da reforma',
    CODE_MANAGEMENT_addNewCode: 'Adicionar código',
    CODE_MANAGEMENT_code_management: 'Gestão de códigos',
    CODE_MANAGEMENT_discount_percentage: 'Percentagem de desconto',
    CODE_MANAGEMENT_discountPercent: 'Percentagem',
    CODE_MANAGEMENT_ending_date: 'Data de fim',
    CODE_MANAGEMENT_identifier: 'Identificador',
    CODE_MANAGEMENT_initial_date: 'Data de início',
    CODE_MANAGEMENT_is_active: 'Ativo',
    CODE_MANAGEMENT_isActive: 'Ativo',
    CODE_MANAGEMENT_isActiveFromDateTime: 'Data de início',
    CODE_MANAGEMENT_isActiveUntilDateTime: 'Data de fim',
    CODE_MANAGEMENT_payment_summary: 'Resumo dos pagamentos',
    CODE_MANAGEMENT_plus_add_new: '+ Adicionar novo',
    CODE_MANAGEMENT_promotionalCode: 'Identificador',
    CODE_MANAGEMENT_user: 'Utilizador',
    code: 'Código',
    Code: 'Código',
    codeManagement: 'Gestão de códigos',
    collaborator: 'Colaborador',
    collaboratorCheckbox: 'Colaborador?',
    COMMERCIAL_MANAGER_paymentOption: 'Método de pagamento',
    COMMERCIAL_MANAGER_USERS_add_commercial_manager: 'Adicionar gestor comercial',
    COMMERCIAL_MANAGER_USERS_appraisals_in_process_count: 'Avaliações em processamento',
    COMMERCIAL_MANAGER_USERS_email: 'E-mail',
    COMMERCIAL_MANAGER_USERS_first_name: 'Nome',
    COMMERCIAL_MANAGER_USERS_is_active: 'Utilizador ativo',
    COMMERCIAL_MANAGER_USERS_lastname: 'Apelidos',
    COMMERCIAL_MANAGER_USERS_national_identity_document: 'NIF/NIE/Passaporte',
    COMMERCIAL_MANAGER_USERS_plus_add_user: '+ Adicionar utilizador',
    COMMERCIAL_MANAGER_USERS_position: 'Função',
    COMMERCIAL_MANAGER_USERS: 'Utilizadores gestores comerciais',
    commercial_managers: 'Gestores comerciais',
    commercialFee: 'Honorários supridos',
    CommercialManager: 'Gestores comerciais',
    CommercialMedian: 'Média comercial',
    COMPARISON_METHOD_comparison: 'Comparação',
    COMPARISON_METHOD_depreciation: 'Dep. Funcional ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_depreciationTotal: 'Dep. Funcional ({{currency}})',
    COMPARISON_METHOD_direction: 'Endereço',
    COMPARISON_METHOD_finalValue: 'Valor final ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_finalValueMonthly: 'Valor final ({{currency_per_surfaceUnit}}/mês)',
    COMPARISON_METHOD_homogCoef: 'Coef. Homog.',
    COMPARISON_METHOD_homogeneizedValue: 'Valor homogeneizado',
    COMPARISON_METHOD_homogeneizedValueDepreciated: 'Valor homogeneizado depreciado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValueMonthly: 'Valor de mercado ({{currency_per_surfaceUnit}}/mês)',
    COMPARISON_METHOD_moreInfo: 'Mais informações',
    COMPARISON_METHOD_normalizedComparison: 'comparação ajustada',
    COMPARISON_METHOD_observations: 'Observações relacionadas com o estudo de mercado',
    COMPARISON_METHOD_reduc: 'Reduc. / Negociação',
    COMPARISON_METHOD_surface: "Superfície ({{surfaceUnit}})'",
    COMPARISON_METHOD_typology: 'Tipologia e subtipologia',
    COMPARISON_METHOD_UnitValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_weight: 'Peso',
    COMPARISON_METHOD_witnessInformation: 'Informação da testemunha',
    complete: 'Completar',
    completeRequiredFields: 'Campos necessários preenchidos',
    completing: 'Completando',
    COND_WARN_OBS_obsCode: 'OBS',
    conditions: 'Condicionantes',
    config_report: 'Configura tu informe',
    config: 'Configuración',
    confirm_and_get_assets: 'Confirmar y obtener activos',
    confirm: 'Confirmar',
    skip: 'Pular',
    confirming: 'A confirmar',
    confirmInvoice: 'Confirmar fatura',
    confirmPassword: 'Repita a palavra-passe',
    Connection_label: 'Tipo de conexão',
    connections: 'Conexiones',
    ConservationState: 'Estado de conservação',
    ConstructionYear: 'Ano de construção',
    contact_number: 'Contacte-nos',
    contactLicenseeAdmin: 'Contacte o administrador da sua licença',
    CONTRACT_METHOD_adoptedValueType: 'Tipo de valor adotado',
    CONTRACT_METHOD_adoptedValueTypes: 'Tipos de valor adotados',
    CONTRACT_METHOD_calculationDate: 'Data do cálculo',
    CONTRACT_METHOD_constructionCost: 'Custos de construção',
    CONTRACT_METHOD_contractId: 'Identificador do contrato',
    CONTRACT_METHOD_contractInitialDate: 'Data de início do contrato',
    CONTRACT_METHOD_contractualData: 'Dados contratuais',
    CONTRACT_METHOD_economicData: 'Dados económicos',
    CONTRACT_METHOD_monthlyNetIncome: 'Renda líquida mensal',
    CONTRACT_METHOD_necessaryExpenses: 'Despesas necessárias',
    CONTRACT_METHOD_nextChargeDate: 'Data da próxima cobrança',
    CONTRACT_METHOD_observationsNetIncome: 'Comentários sobre o rendimento líquido / Informações de estimativa de pagamento',
    CONTRACT_METHOD_pendingContractDuration: 'Duração pendente do contrato',
    CONTRACT_METHOD_technicalValues: 'Valores técnicos da construção',
    CONTRACT_METHOD_tenantsFreeValue: 'Valor sem inquilinos',
    CONTRACT_METHOD_updateType: 'Tipo de atualização',
    cost_group_valuation_message_information: '* El coste de construcción que se indicará corresponderá con la media ponderada de todos los elementos que conforman la agrupación.',
    COST_METHOD_comeFromWorkInProgress: 'Este valor provém do valor distribuído no separador Estado da Obra',
    COST_METHOD_futureMessage: 'O resto dos campos só podem ser editados na guia Avaliação Atual',
    COST_METHOD_isUnfinishedMessage: 'La superficie adoptada solo podrá ser modificada en el capítulo de Estado de la Obra.',
    COST_METHOD_isUnfinishedClusterMessage: 'A superfície adotada só pode ser modificada no capítulo Unidades.',
    COST_METHOD_observations: 'Observações',
    COST_METHOD_justification_rent: 'Justificativa de % da renda atribuível ao imóvel',
    COST_METHOD_observationsGround: 'Justificativa para cálculos do valor da terra',
    COST_METHOD_justification_construction_expenses: 'Justificação dos custos de construção',
    COST_METHOD_justification_construction_expenses_text: 'O custo de construção adotado foi obtido a partir de estatísticas internas elaboradas em revistas especializadas contrastadas com orçamentos reais de construção.',
    costMethodRepercussionCalculateMessage: 'Se alterar o modo de introdução da repercussão, perderá os dados incluídos até agora, deseja continuar?',
    country: 'País',
    CountryThreeLetterISORegionName: 'País',
    CREATE_CRAWLEDASSET_annexes: 'Anexos',
    CREATE_CRAWLEDASSET_annexesInfoMessage: 'Se necessário, o valor destes anexos deverá ser deduzido do valor da oferta do comparável',
    CREATE_CRAWLEDASSET_buildeableSurface: 'Superfície edificável',
    CREATE_CRAWLEDASSET_builtSurface: 'Superfície construída',
    CREATE_CRAWLEDASSET_contactNumber: 'Número de contacto',
    CREATE_CRAWLEDASSET_coverTerraceSurface: 'Sup. terraço coberto ({{surfaceUnit}}) ',
    CREATE_CRAWLEDASSET_crawledDateTime: 'Data de criação do token: {{date}}',
    CREATE_CRAWLEDASSET_crawledSource: 'Origem da testemunha',
    CREATE_CRAWLEDASSET_numberOfGarages: 'Número de vagas de estacionamento',
    CREATE_CRAWLEDASSET_observations: 'Observações',
    CREATE_CRAWLEDASSET_offerType: 'Tipo de oferta',
    CREATE_CRAWLEDASSET_offerValue: 'Valor da oferta',
    CREATE_CRAWLEDASSET_providerCreateDate: 'Data de criação da testemunha:',
    CREATE_CRAWLEDASSET_providerData: 'Informação do fornecedor',
    CREATE_CRAWLEDASSET_providerId: 'ID do fornecedor',
    CREATE_CRAWLEDASSET_providerUrl: 'URL do fornecedor',
    CREATE_CRAWLEDASSET_sourceType: 'Tipo de origem',
    CREATE_CRAWLEDASSET_storageRoom: 'Sala de armazenamento',
    CREATE_CRAWLEDASSET_uncoverTerraceSurface: 'Sup. terraço aberto ({{surfaceUnit}}) ',
    create: 'Criar',
    createBacktesting: 'Criar Backtesting',
    created: 'Criadas',
    creating: 'A criar',
    CriteriaTypes_0: '% das receitas',
    CriteriaTypes_1: '% dos custos totais de construção',
    CriteriaTypes_2: 'Manual ({{currency}})',
    Cultural: 'Cultural',
    currency_per_month: '{{currency}}/mes',
    currency_per_year: '{{currency}}/año',
    currency: 'Moneda',
    CurrentWorkflowStep: 'Estado',
    Customer: 'Cliente',
    CUSTOMERS_add_new_customer: '+ Adicionar novo cliente',
    CUSTOMERS_billing_information: 'Dados de faturação',
    CUSTOMERS_cif_nif: 'NIF/NIE/Passaporte/CIF',
    CUSTOMERS_cif: 'CIF',
    CUSTOMERS_contactEmail: 'E-mail',
    CUSTOMERS_contactPhone: 'Telefone',
    CUSTOMERS_creation_date: 'Data de criação',
    CUSTOMERS_customers: 'Clientes',
    CUSTOMERS_email: 'E-mail',
    CUSTOMERS_firstName: 'Nome',
    CUSTOMERS_its_a_company: 'É uma empresa',
    CUSTOMERS_lastName: 'Apelidos',
    CUSTOMERS_name: 'Nome',
    CUSTOMERS_NEW_add_customer: 'Adicionar novo cliente',
    CUSTOMERS_nif: 'NIF/NIE/Passaporte',
    CUSTOMERS_observations: 'Observações',
    CUSTOMERS_profile: 'Perfil',
    customers: 'Clientes',
    customerTranslationType: 'Tipo de traducción del cliente',
    customerValue: 'Valor del cliente',
    dashboard: 'Painel de controlo',
    DATE_OF_VISIT_addVisitDate: '+ Adicionar data de visita',
    DATE_OF_VISIT_allDay: 'Qualquer momento do dia',
    DATE_OF_VISIT_date: 'Data',
    DATE_OF_VISIT_from: 'Desde',
    DATE_OF_VISIT_until: 'Até',
    DATES_addedDateTime: 'adicionado',
    DATES_appraiserAllDataSentDeadlineDateTime: 'limite para enviar relatório',
    DATES_exchangeFinishDateTime: 'Limite para enviar relatório',
    DATES_obtainedDatetime: 'Data de obtenção',
    DATES_solvedDateTime: 'resolvido',
    DATES_visitScheduleDateTime: 'data de visita',
    DATES_visitScheduleDeadlineDateTime: 'limite para agendar',
    degree: 'Grau académico',
    delete: 'Apagar',
    DELIVERABLE_MODAL_deliverable: 'Entregável',
    DELIVERABLE_MODAL_documentName: 'Nome do documento',
    DELIVERABLE_MODAL_documentVersion: 'Versão do documento',
    DELIVERABLE_MODAL_downloadAll: 'Transferir tudo',
    DELIVERABLE_MODAL_downloading: 'A transferir',
    DELIVERABLE_MODAL_emissionDate: 'Data de emissão',
    DELIVERABLE_MODAL_expirationDate: 'Data de validade',
    DELIVERABLE_MODAL_external: 'Externo',
    DELIVERABLE_MODAL_generationDate: 'Data de geração',
    DELIVERABLE_MODAL_internal: 'Interno',
    DELIVERABLE_REPOSITORY_addRow: '+ Adicionar novo entregável',
    DELIVERABLE_REPOSITORY_deliverable: 'Entregável',
    DELIVERABLE_REPOSITORY_orderType: 'Tipo de trabajo',
    DELIVERABLE_REPOSITORY_prupose: 'Finalidade',
    DELIVERABLE_REPOSITORY_source: 'Origem',
    deliverables: 'Entregáveis',
    deliverablesConfiguration: 'Configuração de entregáveis',
    deliveredByAppraiser: 'Entregues por técnico',
    deliveredToClient: 'Concluídas',
    description: 'Descrição',
    Description: 'Descrição',
    detail: 'Detalhe',
    Developable: 'Urbanizável',
    DgcCodVia: 'Código da via',
    dni: 'NIF/NIE/Passaporte',
    document: 'Documento',
    documents: 'Documentos',
    download: 'Transferir',
    downloadBatchFile: 'Descargar fichero del lote',
    downloadCsv: 'Descargar CSV',
    duplicate: 'Duplicar',
    duplicateBatch: 'Duplicar lote',
    ECONOMIC_EXPLOITATION_buildingOrRehabilitationYear: 'Ano de construção ou reabilitação',
    ECONOMIC_EXPLOITATION_costMethod: 'Método de Custo',
    edit_cost_method_percentages: 'Editar percentagens',
    edit: 'Editar',
    elevator: 'Elevador',
    email: 'E-mail',
    Email: 'E-mail',
    EnergeticCertificateGrade: 'Certificado de eficiência energética',
    english: 'Inglês',
    ENTITIES_add_entity: 'Adicionar entidade',
    ENTITIES_auto_billing: 'Faturação automática',
    ENTITIES_cif: 'CIF',
    ENTITIES_companyName: 'Nome/Denominação social',
    ENTITIES_contactEmail: 'E-mail',
    ENTITIES_contactPhone: 'Telefone',
    ENTITIES_createEntity: 'Criar conta',
    ENTITIES_creationDateTime: 'Data de criação',
    ENTITIES_email: 'E-mail',
    ENTITIES_entities: 'Entidades',
    ENTITIES_entityType: 'Tipo de entidade',
    ENTITIES_sectorType: 'Setor',
    ENTITIES_hasCustomer: 'É cliente?',
    ENTITIES_hasPrescriptor: 'É prescritor?',
    ENTITIES_hasUsers: 'Tem utilizadores?',
    ENTITIES_its_a_company: 'É uma empresa',
    ENTITIES_isAppraiserSociety: 'É uma empresa de avaliação aprovada',
    ENTITIES_lastName: 'Apelidos',
    ENTITIES_name: 'Nome',
    ENTITIES_nationalIdentityDocument: 'DNI/CIF',
    ENTITIES_nif: 'NIF/NIE/Passaporte',
    ENTITIES_observations: 'Observações',
    ENTITIES_plus_add_new: '+ Adicionar nova',
    ENTITIES_profile: 'Perfil',
    entitiesLabel: 'Contas',
    entrance: 'Portal',
    EnvironmentPersonalOpinion: 'Opinião',
    error: 'Erro',
    errors: 'Erros',
    EX_each: 'Cada',
    EX_economicDataInfoModalContent: '<ul> <li> <span> Descrição </span> - Descrição da receita/despesa. </li> <li> <span> Valor atribuído </span> - Valor da receita/despesa ({{currency}}). </li> <li> <span> É periódico? </span> - Tipo de Receita/Despesa. Será indicado se é recorrente (periódico) ou pontual (não periódico). <ul class="inside"> <li> O Valor Atribuído à Receita/Despesa é Periódico, será distribuído de forma automática e equitativa entre o período que foi selecionado nos campos Data de Início/Fim de Receita/Despesa. </li> <li> O Valor Atribuído à Receita/Despesa não é Periódico, o valor será distribuído manualmente nos fluxos de caixa do método. </li> </ul> </li> <li> <span> Periodicidade </span> - Periodicidade da Receita/Despesa. Pode ser anual ou mensal. </li> <li> <span> Data de início </span> - Data a partir da qual ocorrerá a receita/despesa. </li> <li> <span> Data de término </span> - Data até a qual a Receita/Despesa ocorrerá. </li> </ul> ',
    EX_economicDataInfoModalTitle: 'Informações',
    EX_expensesObservations: 'Observações sobre despesas. No caso de usar rácios médios, especifique as fontes',
    EX_incomesObservations: 'Observações sobre o rendimento. No caso de usar rácios médios, especifique as fontes',
    EX_month_months: 'mês/meses',
    EX_repeatEach: 'Repetir a cada:',
    EX_year_years: 'ano/anos',
    existFormChanges: 'Há alterações no formulário',
    externalCode: 'Código externo',
    ExternalRecordId: 'N.º de referência externa',
    externalTech: 'Técnico externo',
    ExternalWoodWork: 'Carpintaria exterior',
    extract: 'Extrato',
    Facilities: 'Instalações',
    FacilityObservations: 'Observações',
    familiarRelationShip: 'Parentesco',
    features: 'Características',
    Fee: 'Honorários',
    fileInfoFileName: 'info_ficheiro_processado.txt',
    fileName: 'Nome do ficheiro',
    filesAverageTime: 'Tempos médios dos',
    filesGlobalAverageTime: 'Tempo médio geral dos processos',
    filesVolume: 'Volume de',
    filter: 'Filtrar',
    financial: 'Financeiro',
    finished: 'Concluídas',
    finishedAppraisals: 'Avaliações concluídas',
    FinishedAppraisals: 'Avaliações concluídas',
    finishedDate: 'Data da finalização',
    FirstName: 'Nome',
    floor: 'Andar',
    Floor: 'Andar',
    FORM_VALIDATION_ALPHANUMERIC: 'Apenas são permitidos caracteres alfanuméricos',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'O formato deve ser:  999999-999999',
    FORM_VALIDATION_CUSTOMER_ALREADY_EXISTS: 'Esse utilizador já existe',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'O formato de e-mail não é válido',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'O formato de e-mail não é válido',
    FORM_VALIDATION_MAX_ADDITION: 'A soma ultrapassa: ',
    FORM_VALIDATION_MAX_LENGTH: 'O comprimento máximo do campo é de: ',
    FORM_VALIDATION_MAX: 'O valor máximo é: ',
    FORM_VALIDATION_MIN_LENGTH: 'O comprimento mínimo do campo é de:',
    FORM_VALIDATION_MIN: 'O valor mínimo é: ',
    FORM_VALIDATION_NOT_SAME: 'Os campos não coincidem',
    FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH: "A sua palavra-passe deve ter pelo menos 8 caracteres de comprimento e conter uma combinação de letras minúsculas e maiúsculas, números e caracteres especiais (por exemplo, ! # $).'",
    FORM_VALIDATION_PATTERN: 'O formato está errado',
    FORM_VALIDATION_REQUIRED: 'Este campo é obrigatório',
    FORM_VALIDATION_MIN_MAX_VALUE: 'Este valor deve ser maior que {{ min }} e menor ou igual a {{ max }}',
    free: 'Livre',
    from: 'Desde',
    FuelStation: 'Estação de serviço',
    Garage: 'Garagem',
    gate: 'Porta',
    Gate: 'Porta',
    generalData: 'Dados gerais',
    generate_password: 'Gerar palavra-passe',
    GestasaId: 'N.º Gestasa',
    GlobalToOrder: 'Global',
    greenZones: 'zonas verdes',
    group_valuation_message_information: '*O ano de construção corresponderá à média ponderada de todos os elementos que compõem o grupo.',
    had_recalculate_valoration: 'Você tem que recalcular para poder adicionar uma avaliação',
    HasAirConditioning: 'Climatização',
    HasArquitectonicProtectionRegime: 'Regime de proteção arquitetónica',
    HasaSportsZone: 'Zona desportiva',
    HasAssetSketch: 'Esboço do imóvel',
    HasCadastralCertification: 'Certificado cadastral',
    HasCadastralGoodsCertificate: 'Certificado de bens cadastrais',
    HasCadastralProperty: 'Propriedade cadastral',
    HasCadastralRecord: 'Ficha do cadastro',
    HasConservationState: 'Estado de conservação',
    HasContract: 'Documentação que certifica o estado da ocupação',
    HasCorrespondanceWithRegisteredProperty: 'Correspondência com o registo da propriedade',
    HasCurrentPlanAdequation: 'Adaptação ao plano atual',
    HasDeck: 'Cobertura',
    HasElevator: 'Elevador',
    HasGarden: 'Jardim',
    HasParking: 'Estacionamento',
    HasEnergeticCertificate: 'Certificado de eficiência energética?',
    HasExpropiation: 'Expropriação',
    HasExternalEnclosure: 'Vedação exterior',
    HasGas: 'Gás',
    HasGreenArea: 'Zonas verdes',
    HasHeating: 'Aquecimento',
    HasHotWater: 'Água quente',
    HasIBIBill: 'Recibo IBI',
    HasIntercom: 'Porteiro automático',
    HasNonexistenceLeasesDocument: 'Documento de inexistência de arrendamento',
    HasOccupationStateUseAndExploitation: 'Estado de ocupação, utilização e exploração',
    HasPaymentMethod: 'Método de pagamento',
    HasPhone: 'Telefone',
    HasPhotos: 'Fotografias',
    HasPhysicalAndRegisteredIdenficication: 'Identificação física e matricial',
    HasPlumbingAndSanitaryEquipment: 'Canalização e equipamentos sanitários',
    HasPool: 'Piscina',
    HasProperyRegisterBookCopy: 'Fotocópia do livro da propriedade',
    HasPublicProtectionRegime: 'Regime de proteção pública',
    HasRegisteredCertificationUpdated: 'Certificado matricial atualizado',
    HasRegisteredPropertyDeed: 'Escritura da propriedade registada',
    HasSanitation: 'Saneamentos',
    HasSecurity: 'Segurança',
    HasSimpleNoteOutdated: 'Nota simples desatualizada',
    HasSimpleNoteUpdated: 'Nota simples atualizada',
    HasSituation: 'Situação',
    HasSwimmingPool: 'Piscina',
    HasTenisField: 'Ténis',
    HasTownHallCertificate: 'Certificado da câmara municipal',
    HasUnregisteredPropertyDeed: 'Escritura da propriedade não registada',
    HasUrbanism: 'Urbanismo',
    HasUrbanisticIdentification: 'Certificado urbanístico',
    HasUrbanisticNormative: 'Regulamento urbanístico',
    HasVisibleEasements: 'Servidões visíveis',
    heating: 'Aquecimento',
    hide_filters: 'Ocultar filtros',
    HOMOG_COEF_MODAL_accessibility: 'Coef. Acessibilidade',
    HOMOG_COEF_MODAL_antiquity: 'Coef. Antiguidade',
    HOMOG_COEF_MODAL_calculateOmogCoef: 'Calcular coeficiente de homogeinização',
    HOMOG_COEF_MODAL_direction: 'Endereço',
    HOMOG_COEF_MODAL_height: 'Coef. Altura',
    HOMOG_COEF_MODAL_infoTooltip: 'As observações serão obrigatórias se o coeficiente "outros" tiver valor',
    HOMOG_COEF_MODAL_marketPrice: "Valor de mercado ({{ currency_per_surfaceUnit }})'",
    HOMOG_COEF_MODAL_morphology: 'Coef. Morfologia',
    HOMOG_COEF_MODAL_observationPLaceholder: 'Observações',
    HOMOG_COEF_MODAL_orientation: 'Coef. Orientação',
    HOMOG_COEF_MODAL_others: 'Coef. Outros',
    HOMOG_COEF_MODAL_slope: 'Coef. Inclinação',
    HOMOG_COEF_MODAL_polyvalence: 'Coef. Polivalência',
    HOMOG_COEF_MODAL_requiredFields: 'Todos os coeficientes de homogeneização devem ser preenchidos. No caso de não aplicar qualquer conceito deixe em 1,00',
    HOMOG_COEF_MODAL_situation: 'Coef. Situação',
    HOMOG_COEF_MODAL_state: 'Coef. Estado',
    HOMOG_COEF_MODAL_surface: 'Coef. Superfície',
    HOMOG_COEF_MODAL_typologieAndSubtypologies: 'Tipologia e subtipologia',
    HOMOG_COEF_MODAL_witnessSurface: 'Superfície ({{ surfaceUnit }})',
    HOMOG_COEF_MODAL_elevators: 'Nº de Elevadores',
    HOMOG_COEF_MODAL_constructionRehabilitation: 'Ano construção / reabilitação',
    Hotel: 'Hotel',
    identification: 'Identificação',
    Identification: 'Identificação',
    Idufir: 'C.R.U (IDUFIR)',
    incidents: 'Incidentes',
    incidentsResolutionAverageTime: 'Tempo médio de resolução de incidentes',
    incomeOrExpensesInvoiced: 'Faturação Receitas/Despesas',
    incomeOrExpensesProvision: 'Provisão Receitas/Despesas',
    incomesExpensesPeriodicityMessage: 'O valor atribuído inserido será repetido de acordo com a periodicidade indicada (valor anual ou mensal).',
    INCOMPATIBILITIES_userName: 'Nome de utilizador',
    incompatibilities: 'Incompatibilidades',
    IndividualByAsset: 'Particular por imóveis',
    IneCodLocality: 'Código da localidade',
    inProcess: 'Em processamento',
    inProgress: 'Em processamento',
    inputFileWebPath: 'Ruta del fichero',
    Inscription: 'Inscrição',
    InternalWoodWork: 'Carpintaria interior',
    invoice: 'Fatura',
    INVOICES_ALL_INVOICES: 'Todas as faturas',
    INVOICES_askForConfirm: 'Solicitar confirmação',
    INVOICES_askForConfirmation: 'Solicitar confirmação',
    INVOICES_askForConfirmationModal: 'Vai solicitar confirmação da fatura. Tem a certeza?',
    INVOICES_billingFlow: 'Fluxo de cobrança',
    INVOICES_cancelationReason: 'Motivo do cancelamento',
    INVOICES_cif_nif: 'NIF/NIE/Passaporte/CIF',
    INVOICES_code_management: 'Gestão de códigos',
    INVOICES_confirmation_date: 'Data da confirmação',
    INVOICES_CONFIRMATION_SENT: 'Confirmação enviada',
    INVOICES_CONFIRMED: 'Confirmada',
    INVOICES_dayFlow: 'Dia/Fluxo',
    INVOICES_DETAIL_resetInvoiceConfirmation: 'A fatura atual vai ser reiniciada. Isto pode demorar alguns segundos.',
    INVOICES_downloadBills: 'Transferir faturas',
    INVOICES_downloadDraft: 'Transferir rascunho',
    INVOICES_downloadInvoicesModal: 'Todas as faturas do ano e mês escolhidos serão transferidas. Isto pode demorar alguns minutos.',
    INVOICES_downloadInvoicesSummaryMonth: 'Transferir resumo mensal do Excel',
    INVOICES_downloadInvoicesSummaryYear: 'Transferir resumo anual do Excel',
    INVOICES_downloadInvoicesZip: 'Transferir ZIP de faturas',
    INVOICES_file_number: 'N.º do processo',
    INVOICES_fullInvoiceReset: 'Reiniciar completamente',
    INVOICES_incomeTax: 'Quota do',
    INVOICES_internal_reference_number: 'N.º de referência interno',
    INVOICES_invoiceConfirmationDate: 'Data de confirmação da fatura',
    INVOICES_invoiceGenerationDate: 'Data de confirmação da fatura',
    INVOICES_massConfirmation: 'Vai solicitar a confirmação de todas as faturas para o ano e mês escolhidos. Isto pode demorar alguns minutos.',
    INVOICES_month: 'Mês',
    INVOICES_invoiceMonth: 'Mês da fatura',
    INVOICES_name: 'Nome',
    INVOICES_payment_summary: 'Resumo dos pagamentos',
    INVOICES_PENDING_REQUEST_CONFIRMATION: 'A aguardar pedido de confirmação',
    INVOICES_REJECTED: 'Rejeitada',
    INVOICES_rejection_date: 'Data da rejeição',
    INVOICES_reseting: 'A reiniciar a fatura',
    INVOICES_resetInvoice: 'Reiniciar fatura',
    INVOICES_resetInvoiceKeepState: 'Reiniciar mantendo o estado',
    INVOICES_resetInvoiceKeepStatus: 'Manter estado',
    INVOICES_resetInvoices: 'Reiniciar faturas',
    INVOICES_resetInvoicesConfirmation: 'Todas as faturas do ano e mês escolhidos serão reiniciadas. Isto pode demorar alguns minutos.',
    INVOICES_sent_confirmation_date: 'Data do envio da confirmação',
    INVOICES_status: 'Estado',
    INVOICES_subtotal: 'Subtotal',
    INVOICES_taxBase: 'Base tributável para',
    INVOICES_technical_mannager_users: 'Utilizadores gestores de técnicos',
    INVOICES_total_with_taxes_included: 'Montante (impostos incluídos)',
    INVOICES_total: 'Total',
    INVOICES_year: 'Ano da fatura',
    INVOICES_youSure: 'Tem a certeza?',
    invoices: 'Faturas',
    InvoiceStatus_0: 'A aguardar pedido de confirmação',
    InvoiceStatus_1: 'Confirmação enviada',
    InvoiceStatus_2: 'Confirmada',
    InvoiceStatus_3: 'Rejeitada',
    irpfPercentage: 'Percentagem de retenção de IRPF (%)',
    IrpfPercentage: 'Percentagem de retenção de IRPF (%)',
    IsActive: 'Utilizador ativo',
    isActiveLicense: 'Licença Ativa',
    IsAdequatedToCurrentPlanning: 'Planeamento em vigor',
    IsOkStatusValues_false: 'KO',
    IsOkStatusValues_true: 'OK',
    isUserActive: 'Utilizador ativo',
    isVPO: '¿Es VPO?',
    language: 'Idioma',
    LastName: 'Apelidos',
    LastRehabilitationYear: 'Ano de remodelação',
    lastUpdate: 'Última atualização',
    latitude: 'Latitude',
    Latitude: 'Longitude',
    LEGAL_MAX_value: 'Valor',
    LegalSituation: 'Situação legal',
    LICENSE_individual: 'Individual',
    LICENSE_teams: 'Equipas',
    LicenseExpirationDatetime: 'Data de fim',
    licenseExpired: 'A sua licença expirou',
    LicensePlanChange: 'Histórico',
    LicenseRegistryDatetime: 'Data de registo',
    list: 'Listagem',
    locality: 'Localidade',
    Locality: 'Localidade',
    LocalityEnvironmentInformation: 'Localização e zona limítrofe',
    location: 'Localização',
    Location: 'Localização',
    LocationConfirmedType: 'Tipo de localização',
    LocationObservations: 'Observações',
    log_in: 'Iniciar sessão',
    log_out: 'Terminar sessão',
    logging_in: 'A iniciar sessão...',
    LOGIN_forgotPassword: 'Esqueceu-se da palavra-passe?',
    LOGIN_password: 'Palavra-passe*',
    LOGIN_resetYourPassword: 'Introduza o seu endereço de e-mail, e será enviada uma mensagem com instruções para recuperar a sua palavra-passe',
    LOGIN_signin: 'Iniciar sessão',
    LOGIN_userName: 'Nome de utilizador/E-mail*',
    logout: 'Terminar sessão',
    Longitude: 'Latitude',
    longitude: 'Longitude',
    mainAssetTipology: 'Tipología Calculado',
    mainAssetUse: 'Uso Calculado',
    mainBatchAssetTipology: 'Tipología Origen',
    mainBatchAssetUse: 'Uso Origen',
    mainConstructiveSubtypology: 'Subtipología',
    mainConstructiveTotalBuiltSurfaceWithCommonElements: 'Superficie construida',
    mainConstructiveTypology: 'Tipología',
    mainConstructiveUse: 'Uso',
    MainElements_Deck: 'Cobertura',
    MainElements_Enclosure: 'Cobertura',
    MainElements_Structure: 'Estrutura',
    mainUse: 'Uso primário',
    manager: 'Responsável',
    massive: 'Trabajo massivo',
    MAXIMUM_LEGAL_mainAssetPercentage: 'Percentagem da unidade principal',
    MAXIMUM_LEGAL_totalValue: 'Valor total',
    MAXIMUM_LEGAL_unitaryValue: 'Valor unitário',
    MAXLEGAL_METHOD_costMethodRequired: 'Avaliação por método de custo',
    MAXLEGAL_METHOD_groundLimitationValue: 'Limitação de valor do solo',
    MAXLEGAL_METHOD_groundMaximumLegal: 'Valor máximo legal do solo',
    MAXLEGAL_METHOD_groundTotalValue: 'Valor total do solo',
    MAXLEGAL_METHOD_isNotChildElement: 'Este item não tem unidade principal',
    MAXLEGAL_METHOD_maximumLegalValue: 'Valor máximo legal',
    MAXLEGAL_METHOD_parentHasNotMaximumLegalValue: 'O elemento pai não dispõe de valor no método máximo legal',
    MAXLEGAL_METHOD_parentLegalMaxValue: 'O valor total do máximo legal do pai é',
    MAXLEGAL_METHOD_qualifiedSurface: 'Superfície classificada',
    MAXLEGAL_METHOD_REAMaximumLegal: 'REA (máximo legal)',
    MAXLEGAL_METHOD_REAMethodCost: 'REA (método de coste)',
    MAXLEGAL_METHOD_residualStaticAbr: 'Método estático residual no método de avaliação de custos',
    MAXLEGAL_METHOD_totalValue: 'Valor total máximo legal',
    MAXLEGAL_METHOD_unitValue: 'Valor unitário máximo legal',
    MAXLEGAL_METHOD_updatedNetIncome: 'Rendas atualizadas',
    MAXLEGAL_METHOD_updatedReversionValue: 'Reversão atualizada',
    MAXLEGAL_METHOD_updatedTotalValue: 'Total atualizado',
    MAXLEGAL_METHOD_updatedValues: 'Valores atualizados',
    MAXLEGAL_METHOD_usefulCheckedSurface: 'Superfície útil verificada',
    MAXLEGAL_METHOD_usefulQualifiedSurface: 'Superfície útil certidão de qual.',
    MAXLEGAL_METHOD_usefulRegistralSurface: 'Superfície útil registada',
    MAXLEGAL_METHOD_valueTypes: 'Tipos de valor',
    MENU_ASSESSMENTS_add: 'Adicionar avaliação',
    MENU_ASSESSMENTS_claims: 'Reclamações',
    MENU_ASSESSMENTS_incidents: 'Incidentes',
    MENU_ASSESSMENTS_massive_load: 'Trabajo massivo',
    MENU_ASSESSMENTS_orders_alive: 'Avaliações ativas',
    MENU_ASSESSMENTS_orders_all: 'Todas',
    MENU_ASSESSMENTS_orders_cancelled: 'Canceladas',
    MENU_ASSESSMENTS_orders_deliveredByAppraiser: 'Entregue por técnico',
    MENU_ASSESSMENTS_orders_deliveredToClient: 'Entregue ao cliente',
    MENU_ASSESSMENTS_orders_inProcess: 'Em processamento',
    MENU_ASSESSMENTS_orders_pendingToAssignManager: 'A aguardar atribuição de responsável',
    MENU_ASSESSMENTS_orders_pendingToSchedule: 'A aguardar agendamento',
    MENU_ASSESSMENTS_orders_scheduledByTec: 'Agendadas por técnico',
    MENU_ASSESSMENTS_orders_toAssign: 'A aguardar atribuição de técnico',
    MENU_ASSESSMENTS_orders_validated: 'Validadas',
    MENU_ASSESSMENTS_orders_validationProcess: 'Validação em curso',
    MENU_ASSESSMENTS_orders: 'Trabajos',
    MENU_ASSESSMENTS_portfolios: 'Carteiras',
    MENU_ASSESSMENTS: 'Avaliações',
    MENU_ASSETS_add: 'Adicionar',
    MENU_ASSETS_assets: 'Ativos',
    MENU_ASSETS_list: 'Listagem',
    MENU_CLAIMS: 'Reclamações',
    MENU_CLIENTS: 'Clientes',
    MENU_dashboard: 'Painel de controlo',
    MENU_ENTITIES_appraisal_companies: 'Empresas de avaliação',
    MENU_ENTITIES_customers: 'Clientes',
    MENU_ENTITIES_prescribers: 'Prescritores',
    MENU_ENTITIES_users: 'Utilizadores',
    MENU_ENTITIES: 'Entidades',
    MENU_FINANCIAL_paymentSummary_allInvoices: 'Todas as faturas',
    MENU_FINANCIAL_paymentSummary_confirmedByInvoicer: 'Confirmadas',
    MENU_FINANCIAL_paymentSummary_pendingToSendToInvoicer: 'A aguardar pedido de confirmação',
    MENU_FINANCIAL_paymentSummary_rejectedByInvoicer: 'Rejeitado',
    MENU_FINANCIAL_paymentSummary_sendToRevisionToInvoicer: 'Confirmação enviada',
    MENU_FINANCIAL_paymentSummary: 'Resumo do pagamento',
    MENU_FINANCIAL: 'Finanças',
    MENU_ORDERS_list: 'Listagem',
    MENU_ORDERS_requests: 'Solicitações',
    MENU_QUALITYCONTROL_createOrder: 'Criar solicitação',
    MENU_QUALITYCONTROL_orders_alive: 'Avaliações ativas',
    MENU_QUALITYCONTROL_orders_all: 'Todas',
    MENU_QUALITYCONTROL_orders_cancelled: 'Canceladas',
    MENU_QUALITYCONTROL_orders_deliveredToClient: 'Entregue ao cliente',
    MENU_QUALITYCONTROL_orders_pending2AssignTechnical: 'A aguardar atribuição de técnico',
    MENU_QUALITYCONTROL_orders_pendingToAssignProcessManager: 'A aguardar atribuição de gestor de processo',
    MENU_QUALITYCONTROL_orders_pendingToAssignTechnicalManager: 'A aguardar atribuição de gestor técnico',
    MENU_QUALITYCONTROL_orders_pendingToStartValidation: 'A aguardar início da validação',
    MENU_QUALITYCONTROL_orders_validating: 'Validação em curso',
    MENU_QUALITYCONTROL_orders_validationFinished: 'Validação concluída',
    MENU_QUALITYCONTROL_orders: 'Trabajos',
    MENU_QUALITYCONTROL: 'Controlo de qualidade',
    MENU_USERS_processManagers_list: 'Listagem',
    MENU_USERS_processManagers_statistics: 'Estatisticas',
    MENU_USERS_processManagers: 'Utilizadores gestor de processos',
    MENU_USERS_professionals: 'Profissionais',
    MENU_USERS_techManagers_list: 'Listagem',
    MENU_USERS_techManagers: 'Utilizadores gestores de técnicos',
    MENU_USERS: 'Utilizadores',
    MinimumChecks: 'Verificações mínimas',
    minlength: 'Longitude mínima {{value}}',
    moreFilters: 'Mais filtros',
    month: 'Mês',
    MonthsEnum_1: 'Janeiro',
    MonthsEnum_10: 'Outubro',
    MonthsEnum_11: 'Novembro',
    MonthsEnum_12: 'Dezembro',
    MonthsEnum_2: 'Fevereiro',
    MonthsEnum_3: 'Março',
    MonthsEnum_4: 'Abril',
    MonthsEnum_5: 'Maio',
    MonthsEnum_6: 'Junho',
    MonthsEnum_7: 'Julho',
    MonthsEnum_8: 'Agosto',
    MonthsEnum_9: 'Setembro',
    municipality: 'Município',
    my_network: 'Minha rede',
    MY_PROFILE_MODAL_cancel: 'Cancelar',
    MY_PROFILE_MODAL_cancelling: 'A cancelar',
    MY_PROFILE_MODAL_confirm: 'Confirmar',
    MY_PROFILE_MODAL_confirming: 'A confirmar',
    MY_PROFILE_MODAL_my_profile: 'O meu perfil',
    MY_PROFILE_MODAL_save: 'Guardar',
    MY_PROFILE_myProfile: 'O meu perfil',
    my_profile: 'O meu perfil',
    my_work_modal_body: 'Ao trabalhar como um <strong> profissional </strong> nesta licença, você não poderá acessá-la através da versão web. Se quiser ver ou trabalhar nos seus arquivos, pode fazê-lo através da aplicação que tem disponível nas lojas (Google Play ou App Store) ou a partir da versão móvel. ',
    my_work_modal_btn: 'Ir para versão móvel',
    my_work_modal_title: 'AVISO',
    MY_WORK_mywork: 'O meu trabalho',
    myComparables: 'Valum comparáveis',
    externalComparables: 'Comparáveis externos',
    studyMarket: 'Estudos de mercado',
    studyMarketList: 'Lista de pesquisa de mercado',
    mywork_finalized_expedients: 'Expedientes finalizados',
    mywork_job_summary: 'Resumen de trabajo',
    mywork_my_assignments: 'Mis asignaciones',
    mywork_my_pending_job: 'Mi trabajo pendiente',
    mywork_pending_expedients: 'Expedientes pendientes',
    mywork_total_expedients: 'Expedientes totales',
    mywork_totals: 'Totales',
    myData_info_displacement: 'Para calcular a nossa contribuição para a redução da pegada de carbono, precisaríamos que nos dissesse como viaja para as visitas.',
    myData_label_displacement: 'Modo de movimento mais comum',
    myData_preferences_typology: 'Tipologías',
    MULTIPLE_RECALCULATION_title: 'Recálculo múltiplo de ativos',
    MULTIPLE_RECALCULATION_subtitle: '*É realizado o recálculo de todos os elementos do relatório pendentes de recálculo.',
    MULTIPLE_RECALCULATION_result: 'Resultado do recálculo',
    name_or_business_name: 'Nome ou denominação social',
    name: 'Nome',
    Name: 'Nome',
    nationalBankSpainCutomerType: 'Tipo de cliente de BDE',
    NationalIdentityDocument: 'NIF/NIE/Passaporte',
    nationalIdentityDocument: 'Documento nacional de identidade (cartão do cidadão)',
    necessaryRecalculate: 'É necessário recalcular antes de poder editar.',
    necessaryRecalculateToNavigate: 'Você precisa recalcular antes de poder acessar.',
    NETWORK_acceptCancelation: 'Aceitar cancelamento',
    NETWORK_acceptRequest: 'Aceitar solicitação',
    NETWORK_acceptRequestQuestion: 'Aceitar solicitação?',
    NETWORK_alreadyRelatedMessage: 'Esta pessoa já pertence à sua rede de contatos. Verifique se os dados inseridos estão corretos.',
    NETWORK_cancelConfirmation: 'Tem certeza que deseja cancelar esta conexão?',
    NETWORK_cancelConnection: 'Cancelar conexão',
    NETWORK_code: 'Código',
    NETWORK_codeCopied: 'Código copiado!',
    NETWORK_colaborateWithLicenses: 'Colaborar com outras licenças',
    NETWORK_conectionDate: 'Data de conexão',
    NETWORK_confirmAndSend: 'Confirmar e enviar',
    NETWORK_connectionCode: 'Código de conexão',
    NETWORK_connectionHistory: 'Histórico de conexão',
    NETWORK_connectionType: 'Tipo de conexão',
    NETWORK_copyCode: 'Copiar código',
    NETWORK_insertPersonalDataToConnect: 'Insira os dados da pessoa com a qual deseja se conectar',
    NETWORK_invalidSharingIdMessage: 'O código que você digitou não corresponde a nenhum usuário Tasafy, por favor, verifique este código e faça a solicitação novamente',
    NETWORK_joinYourLicense: 'Junte-se à sua licença',
    NETWORK_licenseeCodeContent: 'Este é o código de licença que outros profissionais devem usar para encontrar você e se conectar com você. Dessa forma, eles passam a fazer parte da sua rede e você pode começar a atribuir-lhes todo o trabalho de que precisa. ',
    NETWORK_licenseeCodeTitle: 'Deixe outros profissionais aderirem à sua licença!',
    NETWORK_licenseMessage: 'Quero colaborar com você para fazer avaliações em ...',
    NETWORK_message: 'Message',
    NETWORK_myIdentification: 'My ID',
    NETWORK_name: 'Nome',
    NETWORK_newConnection: 'Nova conexão',
    NETWORK_pendingConnectionRequestMessage: 'Você já tentou se conectar com esta pessoa, mas ela ainda não aceitou o convite. Você pode reenviar a solicitação de conexão novamente ',
    NETWORK_professionalCodeContent: 'Este é o código profissional com o qual outras licenças podem procurar por você e colaborar com você. Desta forma, você se torna parte da rede deles e eles podem começar a designar seu trabalho. ',
    NETWORK_professionalCodeTitle: '{{userName}}, deseja colaborar com outras licenças como profissional?',
    NETWORK_reassignAppraisals: 'Reatribuir todos os arquivos para: ',
    NETWORK_received: 'Recebido',
    NETWORK_rejectRequest: 'Rejeitar solicitação',
    NETWORK_requestDate: 'Data de solicitação',
    NETWORK_requestMessageWorkFor: '{{name}} deseja se conectar com você!',
    NETWORK_requestMessageWorkWith: '{{name}} quer trabalhar com você!',
    NETWORK_requests: 'Requests',
    NETWORK_resendConfirmation: 'Tem certeza que deseja reenviar a solicitação?',
    NETWORK_resendRequest: 'Reenviar solicitação',
    NETWORK_sended: 'Enviado',
    NETWORK_state: 'Status',
    NETWORK_successfulCancelation: 'O cancelamento da conexão com {{name}} foi bem-sucedido',
    NETWORK_userMessage: 'Quero trabalhar com você para aumentar minha rede de técnicos em ...',
    NETWORK_wantWorkFor: 'Você quer trabalhar para ...?',
    NETWORK_wantWorkWith: 'Deseja trabalhar com ...?',
    NETWORK_workWithMe: 'Trabalhe comigo!',
    NETWORK_workWithSomeone: 'Junte-se a alguém!',
    newCustomer: 'Novo cliente',
    newProfessional: 'Novo profissional',
    nif: 'NIF/NIE/Passaporte',
    no_documents: 'Não há documentos',
    not_valid_email: 'E-mail inválido',
    not: 'não',
    notAutomaticInvoicing: 'Faturação não automática',
    NotDevelopable: 'Não urbanizável',
    notHavePrescriber: 'Não possui prescritor',
    notInvoiced: 'Não faturado',
    number: 'Número',
    NumberOfBuildingAccesses: 'Número de acessos',
    NumberOfLicensees: 'N.º de utilizadores ativos',
    Observación: 'Observações',
    Observations: 'Observação',
    observations: 'Observações',
    justificationPercentageNegotiation: 'Justificativa da % de negociação',
    residualObservations: 'Observações de premissas de construção e custos de construção',
    OcupationState: 'Estado da ocupação',
    OcupationType: 'Tipo de ocupação',
    of: 'de',
    Office: 'Escritório',
    ORDER_REQUEST_DETAIL_addNewAsset: '+ Adicionar novo ativo',
    ORDER_REQUEST_DETAIL_available: 'Disponível',
    ORDER_REQUEST_DETAIL_cancel: '- Cancelar',
    ORDER_REQUEST_DETAIL_euro: 'EUROS (sem taxas).',
    ORDER_REQUEST_DETAIL_hasAppraisals: 'Numa avaliação',
    ORDER_REQUEST_DETAIL_idufir: "C.R.U(IDUFIR)'",
    ORDER_REQUEST_DETAIL_propertyRegister: 'Registo da propriedade',
    ORDER_REQUEST_DETAIL_propertyRegisterNumber: 'N.º do registo',
    ORDER_REQUEST_DETAIL_realEstate: 'Propriedade',
    ORDER_REQUEST_DETAIL_registerData: 'Dados de registo',
    ORDER_REQUEST_DETAIL_showDetail: 'Ver detalhe',
    ORDER_REQUEST_requestSimpleNote: 'Solicitar nota simples',
    ORDER_REQUESTS_customerWillReceiveAnEmailSoon: 'Em breve, o cliente receberá um e-mail com o orçamento e os dados da solicitação.',
    order: 'Pedido',
    orderDateTime: 'Data do pedido',
    orderDescription: 'Descripción de pedido',
    orderId: 'ID del pedido',
    orderInputFileId: 'orderInputFileId',
    orderInputFileInputFileName: 'orderInputFileInputFileName',
    orders: 'Trabalhos',
    origin: 'Origem',
    otherExpenses: 'Outras despesas',
    otherExpensesRappel: 'Despesas relacionadas',
    feesAndPublicDocuments: 'Taxas, impostos e documentos oficiais',
    Others: 'Outros',
    outOfDate: 'Fora de prazo',
    Paper: 'Folha',
    parametersOfValoration: 'Parâmetros de avaliação',
    partialInvoiced: 'Parcialmente faturado',
    PARTICIPANTS_hasUsers: 'Tem utilizadores?',
    participants: 'Participantes',
    partnerCheckWarningMessage: 'Quando assinala a marca de colaborador, está a marcar automaticamente a geração de faturas para os trabajos em que atua como prescritor. Para alterar, aceda à secção "Dados de Faturação".',
    password: 'Palavra-passe',
    pattern: 'Formato errado',
    PavementType: 'Tipo de pavimento',
    pending: 'Pendente',
    pendingToAssignManager: 'A aguardar atribuição de responsável',
    pendingToAssignProcessManager: 'A aguardar atribuição de responsável de processos',
    pendingToAssignTechnicalManager: 'A aguardar atribuição de responsável técnico',
    pendingTOAssignTechnician: 'A aguardar atribuição de técnico',
    pendingToStartValidation: 'A aguardar início da validação',
    PERIOD_percentage: 'Percentagem',
    PERIOD_unitary: 'Unitário',
    PersonalOpinion: 'Observações',
    phone: 'Telefone',
    Phone: 'Telemóvel',
    PHOTOS_mainImageWarning: 'A foto principal será única a pedido e aparecerá na capa do documento. Tem a certeza de que pretende continuar?',
    pool: 'Piscina',
    population: 'população',
    Portal: 'Portal',
    portuguese: 'Português',
    position: 'Função',
    postalCode: 'Código postal',
    PostalCode: 'Código postal',
    preferences: 'Preferências profissionais',
    prescribers: 'Prescritores',
    PrescriptorType: 'Tipo de prescritor',
    PROCESS_MANAGER_USER: 'Gestor de processos',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Adicionar gestor de processos',
    PROCESS_MANAGER_USERS_addNew: 'Adicionar novo',
    PROCESS_MANAGER_USERS_appraisals_in_process_count: 'Avaliações em processamento',
    PROCESS_MANAGER_USERS_email: 'E-mail',
    PROCESS_MANAGER_USERS_first_name: 'Nome',
    PROCESS_MANAGER_USERS_is_active: 'Utilizador ativo',
    PROCESS_MANAGER_USERS_lastname: 'Apelidos',
    PROCESS_MANAGER_USERS_national_identity_document: 'NIF/NIE/Passaporte',
    PROCESS_MANAGER_USERS_plus_add_user: '+ Adicionar utilizador',
    PROCESS_MANAGER_USERS_position: 'Função',
    PROCESS_MANAGER_USERS: 'Utilizadores gestor de processos',
    process_managers: 'Gestores de processos',
    processDept: 'Departamento de processos',
    ProcessManager: 'Gestores de processos',
    ProcessManagerUser: 'Gestor de processos',
    processState: 'Estado del proceso',
    processStates: 'Estados del proceso',
    PROFESSIONAL_LIST_isPartner: 'É colaborador',
    PROFESSIONAL_LIST_isAbsent: 'Ausente',
    PROFESSIONAL_LIST_isVerifiedAppraiser: 'Profissional validado',
    PROFESSIONAL_LIST_inProgressTooltip: 'Para profissionais, apenas arquivos com status 3 a 6 são levados em consideração.',
    PROFESSIONALS_addNewDocument: 'Adicionar novo documento',
    PROFESSIONALS_addSignature: 'adicionar assinatura',
    PROFESSIONALS_appraisalsInProcessCount: 'Em processamento',
    PROFESSIONALS_appraisers: 'Avaliadores',
    PROFESSIONALS_changeSignature: 'Alterar assinatura',
    PROFESSIONALS_commercialPercentageFee: '% de honorários supridos',
    PROFESSIONALS_distance: 'Distância',
    PROFESSIONALS_executionPercentageFee: '% de execução',
    Professionals_internalObservations: 'Observações internas',
    PROFESSIONALS_maxDistance: 'Distância máxima',
    PROFESSIONALS_month: 'Mês',
    PROFESSIONALS_NEW_add_professional: 'Adicionar avaliador',
    PROFESSIONALS_notFileSelected: 'Não há ficheiros selecionados',
    PROFESSIONALS_notifications: 'Notificações',
    PROFESSIONALS_paymentOption: 'Modo de faturação aos clientes:',
    PROFESSIONALS_qualification: 'Grau académico',
    PROFESSIONALS_recieveAppNotifications: 'Receber notificações na aplicação',
    PROFESSIONALS_recieveEmailNotifications: 'Receber notificações por e-mail',
    PROFESSIONALS_selectFile: 'Selecione um ficheiro',
    PROFESSIONALS_signature: 'assinatura',
    PROFESSIONALS_status: 'Estado',
    PROFESSIONALS_totalTaxesIncluded: 'Montante (impostos incluídos)',
    PROFESSIONALS_typologiesAppraisable: 'Tipologias',
    PROFESSIONALS_year: 'Ano',
    professionals: 'Profissionais',
    PROFFESIONALS_aditionalInfo: 'Informações adicionais',
    PROFFESIONALS_aditionalInfoTooltip: 'O código externo é utilizado para preservar alguma numeração própria. As observações podem ser lidas pelo avaliador na aplicação. Embora as observações internas só possam ser lidas neste painel de administração, o avaliador não poderá lê-las na aplicação.',
    PROFFESIONALS_observationTooltip: 'As observações podem ser lidas pelo avaliador na aplicação. Embora as observações internas só possam ser lidas neste painel de administração, o avaliador não poderá lê-las na aplicação.',
    profile: 'Perfil',
    PROPERTY_DATA_TABLE_adress: 'Endereço',
    PROPERTY_DATA_TABLE_antiquity: 'Antiguidade',
    PROPERTY_DATA_TABLE_assetRregistryInf: 'Informação de registo do imóvel',
    PROPERTY_DATA_TABLE_CadastralReference: 'Referência cadastral',
    PROPERTY_DATA_TABLE_estimatedValueBand: 'Escalão de valor estimado',
    PROPERTY_DATA_TABLE_isUsedInAppraisal: 'Numa avaliação',
    PROPERTY_DATA_TABLE_pruposedProperty: 'Tarifa proposta',
    PROPERTY_DATA_TABLE_refCatastral: 'Ref. cadastral',
    PROPERTY_DATA_TABLE_RegistryInfoBook: 'Livro',
    PROPERTY_DATA_TABLE_RegistryInfoIdufir: "C.R.U. (IDUFIR)'",
    PROPERTY_DATA_TABLE_RegistryInfoInscription: 'Inscrição',
    PROPERTY_DATA_TABLE_RegistryInfoRealEstateNumber: 'Propriedade',
    PROPERTY_DATA_TABLE_RegistryInfoRegister: 'Registo da propriedade',
    PROPERTY_DATA_TABLE_RegistryInfoRegisterNumber: 'Número cadastral de propriedade',
    PROPERTY_DATA_TABLE_RegistryInfoSection: 'Folha',
    PROPERTY_DATA_TABLE_RegistryInfoVolume: 'Volume',
    PROPERTY_DATA_TABLE_surface: 'Superfície',
    PROPERTY_DATA_TABLE_tipologyAndUse: 'Uso e tipologia',
    PROPERTY_DATA_TABLE_total: 'Total:',
    PropertyRegistrationCode: 'Número de registo da propriedade',
    PropertyRegistrationName: 'Registo da propriedade',
    protected: 'Protegida',
    providerAssetId: 'ID Activo Proveedor',
    providerAssetIds: 'ID de origen',
    province: 'Província',
    Province: 'Província',
    PROVISION_EXPENSES_alert: 'Advertência!',
    PROVISION_EXPENSES_alertMessage: 'Os dados que se seguem são da Provisão de Receitas/Despesas, é necessário confirmar as Receitas/Despesas definitivas.',
    QUALITY_add_massive_control_appraisal: 'Criar um novo carregamento em massa de controle de qualidade',
    QUALITY_add_new_appraisal: 'Criar trabajo',
    QUALITY_add_new_control_appraisal: 'Criar novo trabajo de controlo de validação',
    QUALITY_addedDate: 'Data de criação',
    QUALITY_addKo: 'Adicionar KO',
    QUALITY_addMessage: 'Adicionar uma mensagem',
    QUALITY_appraiser: 'Avaliador',
    QUALITY_attachDocument: 'Anexar',
    QUALITY_cancelAppraisal: 'Anular trabajo',
    QUALITY_cancelled: 'Anulada',
    QUALITY_cancelledDate: 'Data de anulação',
    QUALITY_claimDate: 'Data da reclamação',
    QUALITY_appraiserName: 'Nome do avaliador',
    QUALITY_select_prescriber: 'Selecione um prescritor',
    QUALITY_valorationMethodAdopted: 'Método adotado',
    QUALITY_CONTROL_detail: 'Detalhe',
    QUALITY_CONTROL_documents: 'Documentação',
    QUALITY_CONTROL_finishValidationConfirm: 'Você está prestes a terminar a validação Tem certeza?',
    QUALITY_CONTROL_massiveControlAppraisal: 'Novo trabajo massivo de controlo de validação',
    QUALITY_CONTROL_messageBoard: 'Mural',
    QUALITY_CONTROL_pressingCtrlKey: 'Selecionar o conjunto mantendo pressionada a tecla control (Ctrl).',
    QUALITY_CONTROL_pressingShiftKey: 'Selecionar intervalos mantendo pressionada a tecla shift (maiúsculas).',
    QUALITY_CONTROL_registralPlat: 'Propriedades com registo',
    QUALITY_CONTROL_selectMultipleFilesOptions: 'Para selecionar vários ficheiros é possível:',
    QUALITY_CONTROL_startValidationConfirmation: 'Tem certeza de que deseja iniciar a validação',
    QUALITY_CONTROL_status: 'Resultado da validação',
    QUALITY_creationDate: 'Data de criação',
    QUALITY_customer: 'Cliente',
    QUALITY_customerName: 'Nome e apelidos/Denominação social',
    QUALITY_customerReferenceId: 'Referência do cliente',
    QUALITY_documentType: 'Tipo de documento',
    QUALITY_download_template: 'Baixe o modelo de carregamento do farm para validar',
    QUALITY_estimatedValue: 'Valor estimado',
    QUALITY_estimatedValueWithSuffix: "Valor estimado ({{currency}})'",
    QUALITY_externalTech: 'Técnico externo',
    QUALITY_fileName: 'Documento',
    QUALITY_fileNumber: 'N.º do processo',
    QUALITY_finished: 'Concluídas',
    QUALITY_finishedDate: 'Data da finalização',
    QUALITY_finishValidation: 'Concluir validação',
    QUALITY_from: 'Desde',
    QUALITY_incidenceCode: 'Código do incidente',
    QUALITY_incidentInStatus: 'Estado do incidente',
    QUALITY_isCustomerDeadlineExpired: 'Entrega ao cliente fora do prazo',
    QUALITY_isSolved: 'Está resolvida',
    QUALITY_noValidate: 'Não validar',
    QUALITY_pdf: 'PDF',
    QUALITY_pendingValidation: 'A aguardar validação',
    QUALITY_portfolios: 'Carteiras',
    QUALITY_processManager: 'Gestor de processos',
    QUALITY_processManagers: 'Gestores de processo',
    QUALITY_purpose: 'Finalidade',
    QUALITY_recordId: 'N.º do processo',
    QUALITY_resultValidation: 'Resultado da validação',
    QUALITY_select_client: 'Selecione um cliente',
    QUALITY_solvedDate: 'Data de resolução',
    QUALITY_startValidation: 'Iniciar validação',
    QUALITY_status: 'Estado',
    QUALITY_techManager: 'Gestor de técnicos',
    QUALITY_toDate: 'Até',
    QUALITY_validate: 'Validar',
    QUALITY_validationFinished: 'Validação concluída',
    QUALITY_validationStarted: 'Validação iniciada',
    QUALITY_validationState: 'Status de validação',
    QUALITY_viewPDF: 'Ver PDF',
    QUALITY_withClaims: 'Com reclamações',
    QUALITY_withClaimsNotResolved: 'Com reclamações não resolvidas',
    QUALITY_xml: 'XML',
    QualityOfBuilding: 'Qualidade das edificações',
    REA_costMethodSurface: 'Superfície do método de custo',
    REA_groundValueMaximumLegal: 'Valor do solo segundo o máximo legal',
    REA_total: 'Total',
    REA_valueMaximumLegalDividedSurface: 'Este valor é dado a partir do valor máximo legal dividido pela área adotada no método de custo',
    recalculationNeeded: 'É necessário recalcular',
    recalculation: 'Recálculo',
    recalculation_success: 'Recálculo bem sucedido',
    RecordId: 'N.º do processo',
    ReformLastYear: 'Ano de remodelação',
    registered: 'Registada',
    RegisteredPropertyNumber: 'Registo da propriedade',
    registryName: 'Registo',
    relatedAppraisals: 'Avaliações relacionadas',
    Religious: 'Religioso',
    remainder: 'Restante',
    remove_cluster_asset_modal_1: 'Remover unidade?',
    remove_cluster_asset_modal_2: 'Remover esta unidade irá remover as unidades vinculadas. Retirar?',
    RENTAL_METHOD_activeNetIncome: 'Renda líquida ativa',
    RENTAL_METHOD_amountMonthlyExpenses: 'Montante das despesas mensais ({{currency}})',
    RENTAL_METHOD_annualNetIncome: 'Renda líquida anual',
    RENTAL_METHOD_annualNetIncomeSuffix: '{{currency}}/ano',
    RENTAL_METHOD_capex: 'Capex anual',
    RENTAL_METHOD_capexPercent: '% capex anual',
    RENTAL_METHOD_capexValue: 'Montante capex anual',
    RENTAL_METHOD_capitalGain: 'Mais-valias/menos-valias do solo',
    RENTAL_METHOD_chooseDate: 'Selecionar data',
    RENTAL_METHOD_currentIncome: 'Renda atual',
    RENTAL_METHOD_defaulting: 'Morosidade',
    RENTAL_METHOD_defaultingPercent: '% de morosidade',
    RENTAL_METHOD_defaultingValue: 'Meses de morosidade',
    RENTAL_METHOD_economicMarketIncomeData: 'Dados económicos de renda do mercado',
    RENTAL_METHOD_euroMonthSuffix: '({{currency}}/mês)',
    RENTAL_METHOD_euroYearSuffix: '({{currency}}/ano)',
    RENTAL_METHOD_extinctionContractDate: 'Data de extinção do contrato',
    RENTAL_METHOD_finalValue: 'Valor final ({{currency_per_surfaceUnit}}/mês)',
    RENTAL_METHOD_generalEconomicData: 'Dados gerais e económicos contratuais',
    RENTAL_METHOD_groundRepercussion: 'Repercussão do solo',
    RENTAL_METHOD_groundRepercussionError: 'Método de custo',
    RENTAL_METHOD_homogeneizedValue: 'Valor homogeneizado ({{currency_per_surfaceUnit}}/mês)',
    RENTAL_METHOD_incomeUpdated: 'Rendas atualizadas',
    RENTAL_METHOD_initialValue: 'Valor inicial ({{currency_per_surfaceUnit}}/mês)',
    RENTAL_METHOD_IPC: 'IPC (%)',
    RENTAL_METHOD_leisure: 'Desocupação',
    RENTAL_METHOD_leisurePercent: '% de desocupação',
    RENTAL_METHOD_leisureValue: 'Meses de desocupação',
    RENTAL_METHOD_marketNetIncome: 'Renda líquida mercado',
    RENTAL_METHOD_MonthlyEquivalentUpdateRate: 'Taxa de atualização mensal equivalente',
    RENTAL_METHOD_monthlyExpenses: 'Despesas mensais',
    RENTAL_METHOD_monthlyGrossIncome: 'Renda bruta mensal',
    RENTAL_METHOD_monthlyNetIncome: 'Renda líquida mensal',
    RENTAL_METHOD_monthlyNetIncomeSuffix: '{{currency}}/mês',
    RENTAL_METHOD_observationsOcupation: 'Comentários relacionados com a ocupação',
    RENTAL_METHOD_residualUsefulLife: 'Vida útil residual',
    RENTAL_METHOD_rollbackUpdated: 'Reversão atualizada',
    RENTAL_METHOD_rollbackValue: 'Valor da reversão',
    RENTAL_METHOD_unitNetIncome: 'Renda líquida unitária',
    RENTAL_METHOD_unitNetIncomeSuffix: '({{currency_per_surfaceUnit}})/mês',
    RENTAL_METHOD_unitCurrencySurfaceSuffix: '{{currency_per_surfaceUnit}}',
    RENTAL_METHOD_updateRatePercent: 'Taxa de atualização',
    RENTAL_METHOD_updateValue: 'Valor da atualização',
    RENTAL_METHOD_valuesUpdated: 'Valores atualizados',
    REPORT_actualValuation: 'Avaliação atual',
    REPORT_ASSET_hide_assets_to_group: 'Ocultar activos a agrupar',
    REPORT_ASSET_only_finished: '* Elementos não acabados não podem ser agrupados.',
    REPORT_ASSET_only_group_same_types: '* Não podrás agrupar elementos mezclados, suelos solo se puede agrupar con suelos, elementos terminados, con elementos terminados y explotaciones económicas con explotaciones económicas.',
    REPORT_ASSET_report_structure_childrenHasErrors_error: 'É necessário rever os erros pertencentes aos anexos',
    REPORT_ASSET_report_structure_hasValorations_error: 'No puedes agrupar este activo ya que tiene métodos de valoración asociados.',
    REPORT_ASSET_report_structure_isClustered_error: 'Você não pode agrupar este ativo, pois ele pertence a outro agrupamento',
    REPORT_ASSET_report_structure_stateNotDefined_error: 'Você deve selecionar o Status do ativo em Tipologia para poder agrupar este ativo.',
    REPORT_ASSET_show_assets_to_group: 'Mostrar activos a agrupar',
    REPORT_CUSTOMIZATION_footerLine1: 'Rodapé de página (linha 1)',
    REPORT_CUSTOMIZATION_footerLine2: 'Rodapé de página (linha 2)',
    REPORT_CUSTOMIZATION_footerLine3: 'Rodapé de página (linha 3)',
    REPORT_CUSTOMIZATION_includeCustomImageHeader: 'Incluir cabeçalho de imagem personalizado',
    REPORT_CUSTOMIZATION_includeCustomLogo: 'Incluir o logotipo da capa',
    REPORT_CUSTOMIZATION_includeSignature: 'Incluir a assinatura do representante em todos os relatórios',
    REPORT_CUSTOMIZATION: 'Personalize o seu relatório de avaliação',
    REPORT_futureValuation: 'Avaliação H.E.T.',
    REPORT_LIST_deliverablesAccess: 'Acesso aos entregáveis',
    REPORT_LIST_generateDraft: 'Gerar rascunho',
    REPORT_reportVersionSelected: 'Versão do relatório selecionada',
    REPORT_valuation_summary_adoptedMethod: 'Método adotado',
    REPORT_valuation_summary_adoptedSurface: 'Superfície adotada',
    REPORT_valuation_summary_cadastralReference: 'Referência cadastral',
    REPORT_valuation_summary_element: 'Elemento',
    REPORT_valuation_summary_registeredPropertyNumber: 'Registo da propriedade',
    REPORT_valuation_summary_totalValue: 'Valor totall ({{currency}})',
    REPORT_valuation_summary_unitaryValue: 'Valor unitário',
    REPORT_valuationSummary: 'Resumo de avaliações',
    REPORT_validationAsset: 'Validação ativa',
    REPORT_validation: 'Relatório de validação',
    REPORT_thereAreNoItems: 'Não há elementos',
    REPORT_checkValidation: 'Verifique os relatórios',
    REPORT_checkReportValidation: 'Relatórios de revisão',
    REPORT_checkReportsValidation: 'Relatórios de revisão',
    REPORT_checkAssetValidation: 'Verificar o ativo',
    reportVersion: 'Versão',
    REQUEST_createdFrom: 'Data de criação início',
    REQUEST_createdTo: 'Data de criação fim',
    REQUEST_customerInvoiced: 'Avaliações faturáveis a cliente',
    REQUEST_notCancelled: 'Não anuladas',
    requestCreateInvoiceModalMsg1: 'A provisão do montante incluída no pedido é',
    requestCreateInvoiceModalMsg2: 'Indique o montante a faturar',
    requestCreateInvoiceModalMsg3: 'A fatura será gerada para as avaliações selecionadas e para o valor indicado. Esta fatura não pode ser alterada. Tem a certeza de que deseja continuar?',
    REQUESTER_COMPANIES_billing_information: 'Dados de faturação',
    REQUESTER_COMPANIES_code: 'Código',
    REQUESTER_COMPANIES_DEFAILS_USERS_users: 'Utilizadores',
    REQUESTER_COMPANIES_name: 'Nome',
    REQUESTER_COMPANIES_NEW_add_prescriber: 'Adicionar prescritor',
    REQUESTER_COMPANIES_prescribers: 'Prescritores',
    REQUESTER_COMPANIES_prescriptorType: 'Tipo de prescritor',
    REQUESTER_COMPANIES_profile: 'Perfil',
    Requester: 'Prescritor',
    REQUESTS_acceptQuoteManually: 'Aceitar orçamento manualmente',
    REQUESTS_add_manually: 'Introduzir manualmente',
    REQUESTS_add_new_asset: 'Adicionar novo ativo',
    REQUESTS_add_new_request: 'Criar solicitação',
    REQUESTS_addedUserFullName: 'Nome e apelidos',
    REQUESTS_address: 'Endereço',
    REQUESTS_addToInvoice: 'Adicionar à fatura',
    REQUESTS_amounts: 'Montantes',
    REQUESTS_feesAndPublicDocuments: 'Taxas, impostos e documentos oficiais',
    REQUESTS_apply: 'Aplicar',
    REQUESTS_appraisal_owner_data: 'Dados do titular da avaliação',
    REQUESTS_appraisal_owner_name: 'Nome do titular',
    REQUESTS_appraisal_owner_lastName: 'Apelido do titular',
    REQUESTS_appraisal_customer_data: 'Dados do cliente',
    REQUESTS_appraisal_retriever_email: 'E-mail do recetor da avaliação',
    REQUESTS_appraisals: 'Avaliações',
    REQUESTS_askForConfirmation: 'Solicitar confirmação',
    REQUESTS_at_any_moment_of_the_day: 'Qualquer momento do dia',
    REQUESTS_basicFilters: 'Filtros básicos',
    REQUESTS_state: 'Status do aplicativo',
    REQUEST_RETURN: '< Vá para o aplicativo',
    REQUESTS_billing_data: 'Dados de faturação',
    REQUESTS_billing: 'Faturação',
    REQUESTS_financial_information: 'Informação financeira',
    REQUESTS_budget_number: 'N.º do orçamento',
    REQUESTS_cadastralReference: 'Referência cadastral',
    REQUESTS_cancel: 'Cancelar',
    REQUESTS_cancelled: 'Anuladas',
    REQUESTS_communication: 'Comunicação',
    REQUESTS_message_code: 'Código da mensagem',
    REQUESTS_message_board: 'Mural',
    REQUESTS_state_communication: 'Estado de comunicação',
    REQUESTS_cancelRequest: 'Cancelar solicitação',
    REQUESTS_copyRequest: 'Copiar solicitação',
    REQUESTS_cancelRequestConfirmation: 'Esta solicitação será cancelada. Deseja continuar?',
    REQUESTS_cif: 'CIF',
    REQUESTS_client_error: 'O cliente não tem e-mail, este campo é obrigatório em pedidos de pré-pagamento',
    REQUESTS_code: 'Código',
    REQUESTS_commercial_percentage_fee: '% de honorários supridos',
    REQUESTS_copyDataFromClient: 'Copiar dos dados do cliente',
    REQUESTS_copyDataFromOwner: 'Copiar dados do titular',
    REQUESTS_createAssessments: 'Criar avaliação',
    REQUESTS_createOrder: 'Criar trabajo',
    REQUESTS_createWork: 'Crear trabalho',
    REQUESTS_createNewInvoice: 'Criar nova fatura',
    REQUESTS_createOk: 'A solicitação foi criada corretamente.',
    REQUESTS_creationDate: 'Data de criação',
    REQUESTS_customer: 'Cliente',
    REQUESTS_date: 'Data',
    REQUESTS_DETAIL_createDate: 'Data de criação',
    REQUESTS_DETAIL_paymentAmount: 'Montante',
    REQUESTS_DETAIL_paymentDate: 'Data e hora',
    REQUESTS_DETAIL_paymentId: 'ID de pagamento',
    REQUESTS_DETAIL_description: 'Descrição',
    REQUESTS_DETAIL_paymentState: 'Estado',
    REQUESTS_DETAIL_redsysPaymentId: 'Redsys ID',
    REQUESTS_DETAIL_redsysResponseState: 'Estado Redsys',
    REQUESTS_detail: 'Detalhe',
    REQUESTS_direction: 'Endereço',
    REQUESTS_dni_nie_passport: 'NIF/NIE/Passaporte',
    REQUESTS_dni: 'NIF / CIF Cliente',
    REQUESTS_do_visit: 'Realizar a visita',
    REQUESTS_do_you_have_code: 'Tem código?',
    REQUESTS_document: 'Documento',
    REQUESTS_documents: 'Documentos',
    REQUESTS_eco_805_2003: 'Eco 805/2003',
    REQUESTS_editBillingData: 'Editar dados de faturação',
    REQUESTS_email: 'E-mail',
    REQUESTS_end: 'Concluir',
    REQUESTS_euros_without_taxes: 'Euros (sem taxas)',
    REQUESTS_execution_percentage_fee: '% de execução',
    REQUESTS_expensesProvision: 'Provisão de Proveitos/Despesas',
    REQUESTS_expensesRapel: 'Despesas relacionadas',
    REQUESTS_externalReference: 'N.º da referência externa',
    REQUESTS_feeOptions: 'Modo tarifa',
    REQUESTS_finishDate: 'Data da finalização',
    REQUESTS_finished: 'Concluídas',
    REQUESTS_first_name_last_name_business_name: 'Nome e apelidos/Denominação social',
    REQUESTS_first_name: 'Nome',
    REQUESTS_from: 'Desde',
    REQUESTS_hasAppraisals: 'Tem trabajos',
    REQUESTS_id: 'Identificador',
    REQUESTS_internal_amounts: 'Montantes internos',
    REQUESTS_invoiceDateTime: 'Data da fatura',
    REQUESTS_invoiceNumber: 'N.º da fatura',
    REQUESTS_invoiceNumberId: 'Número da fatura',
    REQUESTS_irpfTotal: 'Total IRPF',
    REQUESTS_is_company: 'É uma empresa',
    REQUESTS_ivaTotal: 'Total de impostos',
    REQUESTS_last_name: 'Apelidos',
    REQUESTS_locality: 'Localidade',
    REQUESTS_manualOrderAcceptConfirmation: 'A orçamento será marcado manualmente como aceite. Deseja continuar?',
    REQUESTS_new_appraisal: 'Nova avaliação',
    REQUESTS_new_request: 'Nova solicitação',
    REQUESTS_new_others: 'Nova ordem',
    REQUESTS_noPrescriber: 'Não possui prescritor',
    REQUESTS_observations: 'Observações',
    REQUESTS_order_characteristics: 'Características do trabalho',
    REQUESTS_order_type: 'Tipo de trabajo / avaliação',
    REQUESTS_job_type: 'Tipo de trabalho',
    REQUESTS_otherExpenses: 'Outras despesas associadas',
    REQUESTS_payment_history: 'Histórico de pagamentos',
    REQUESTS_communications: 'Comunicações',
    REQUESTS_history: 'Histórico',
    REQUESTS_payment_mode: 'Modo de faturação',
    REQUESTS_payment_mode_info: '*Clicar em Cobrança automática gerará uma fatura para cada arquivo concluído',
    REQUESTS_phone_number: 'Telefone',
    REQUESTS_postal_code: 'CP',
    REQUESTS_prescriptorName: 'Prescritor',
    REQUESTS_property_data: 'Dados da solicitação',
    REQUESTS_province: 'Província',
    REQUESTS_purpose: 'Finalidade',
    REQUESTS_remove_code: 'Eliminar código',
    REQUESTS_requests: 'Solicitações',
    REQUESTS_resendMailPayment: 'Reenviar o e-mail de pagamento',
    REQUESTS_select_a_client: 'Selecionar um cliente',
    REQUESTS_simpleNote: 'Nota simples',
    REQUESTS_status: 'Estado',
    REQUESTS_subtotal: 'Subtotal',
    REQUESTS_to: 'Até',
    REQUESTS_total_fees: 'Total de honorários',
    REQUESTS_total_totalAmountWarning: 'Introduza uma tarifa válida',
    REQUESTS_totalWithoutTaxes: 'Total sem impostos',
    REQUESTS_totalWithTaxes: 'Total com impostos',
    REQUESTS_visit_contact_user_data: 'Dados do contacto para a visita',
    REQUESTS_visit_type: 'Tipo de visita',
    REQUESTS_with_report: 'Executo o relatório',
    requests: 'Solicitações',
    REQUESTSS_at_any_moment_of_the_day: 'Em qualquer momento do dia',
    REQUETS_can_be_launched_in_exchange: 'Incluir a solicitação na bolsa de trabajos',
    REQUETS_generalConditions: 'Condições gerais de venda',
    REQUETS_has_read_and_accepted: 'Li e aceito as',
    REQUIRE_PASSWORD_CHANGE_inputEmail: 'Introduza o seu endereço de e-mail, e será enviada uma mensagem com instruções para recuperar a sua palavra-passe',
    REQUIRE_PASSWORD_CHANGE_restorePassword: 'Recuperar palavra-passe',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessMsg: 'Foi enviado um e-mail com as instruções para alterar a palavra-passe',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessTitle: 'E-mail',
    required: 'Este campo é obrigatório',
    ResidentialMultiFamily: 'Residência multifamiliar',
    ResidentialSingleFamily: 'Residência unifamiliar',
    RESIDUAL_DYNAMIC_workPlanDatesWarning: 'Os dados das datas de início e término da promoção são editáveis ​​na guia Status do Trabalho',
    restart: 'Reiniciar',
    RESTORE_PASSWORD_changePassword: 'Alterar palavra-passe',
    RESTORE_PASSWORD_changePasswordLabel: 'Introduza a sua nova palavra-passe para aceder à sua conta',
    RESTORE_PASSWORD_newPassword: 'Nova palavra-passe',
    revisionsResolutionAverageTime: 'Tempo médio de resolução de revisões',
    roadType: 'Tipo de via',
    root: 'Raiz',
    Sanitary: 'Sanitário',
    save: 'Guardar',
    saveAndFinish: 'Guardar e concluir',
    saveRequiredChangesToContinue: 'Você exigiu alterações pendentes de salvamento para executar o Residual Dinâmico. Feche e revise o formulário ou salve para continuar.',
    saving: 'A guardar...',
    schedule: 'Agenda',
    scheduledByTec: 'Agendadas por técnico',
    search: 'Pesquisar',
    Section: 'Secção',
    select_batch_excel_file: 'Elegir fichero CSV de carga',
    select_currency_warning: 'La moneda seleccionada cambiará la etiqueta, no influirá en el valor.',
    select_currency: 'Selecione a moeda com a qual deseja trabalhar',
    select_language_title: 'Seleccionar idioma',
    select_language: 'Selecionar idioma',
    selectAmountToIncludeInInvoice: 'Selecione o valor a incluir na fatura',
    selectCustomer: 'Selecione um cliente',
    selectedAsset: 'Ativo selecionado',
    selectedCluster: 'Agrupamento selecionado',
    selectInvoiceData: 'Selecione os dados de faturação',
    selectPrescriber: 'Selecione um prescritor',
    selectRequest: 'Selecione uma solicitação',
    send: 'Enviar',
    sending: 'A enviar',
    sendToCadastralApi: 'Enviar a la API de catastro',
    sendToClient: 'Enviar para o cliente',
    sendToValorate: 'Enviar para avaliar',
    SeveralUses: 'Muitos usos',
    show_filters: 'Mostrar filtros',
    signature: 'Assinatura',
    SIMPLE_NOTE_addedDateTime: 'Data de criação',
    SIMPLE_NOTE_attach: 'Anexar',
    SIMPLE_NOTE_Block: 'Bloco',
    SIMPLE_NOTE_Book: 'Livro',
    SIMPLE_NOTE_cadastralReference: 'Referência cadastral',
    SIMPLE_NOTE_Country: 'País',
    SIMPLE_NOTE_currentWorkflowStep: 'Estado atual',
    SIMPLE_NOTE_errorMessage: 'Deixe um comentário',
    SIMPLE_NOTE_errorType: 'Tipo de erro',
    SIMPLE_NOTE_FILTER_Book: 'Livro',
    SIMPLE_NOTE_FILTER_CadastralReference: 'Referência cadastral',
    SIMPLE_NOTE_FILTER_Idufir: 'IDUFIR',
    SIMPLE_NOTE_FILTER_OwnerFirstName: 'Nome do cliente',
    SIMPLE_NOTE_FILTER_OwnerLastName: 'Apelidos do cliente',
    SIMPLE_NOTE_FILTER_OwnerNationalIdentityDocument: 'DNI do cliente',
    SIMPLE_NOTE_FILTER_Page: 'Folha',
    SIMPLE_NOTE_FILTER_RealEstateNumber: 'Propriedade',
    SIMPLE_NOTE_FILTER_RegisterName: 'Nome do registo da propriedade',
    SIMPLE_NOTE_FILTER_RegisterNumber: 'Número do registo da propriedade',
    SIMPLE_NOTE_FILTER_Section: 'Secção',
    SIMPLE_NOTE_FILTER_status: 'Estado',
    SIMPLE_NOTE_Floor: 'Andar',
    SIMPLE_NOTE_Gate: 'Porta',
    SIMPLE_NOTE_Id: 'ID',
    SIMPLE_NOTE_Idufir: "C.R.U. (IDUFIR)'",
    SIMPLE_NOTE_Inscription: 'Inscrição',
    SIMPLE_NOTE_Locality: 'Localidade',
    SIMPLE_NOTE_mustUploadAFile: 'Deve ser anexado um ficheiro',
    SIMPLE_NOTE_notAvailable: 'Nota simples indisponível',
    SIMPLE_NOTE_OwnerFirstName: 'Nome do cliente',
    SIMPLE_NOTE_OwnerLastName: 'Apelidos do cliente',
    SIMPLE_NOTE_OwnerNationalIdentityDocument: 'DNI do cliente',
    SIMPLE_NOTE_Page: 'Página',
    SIMPLE_NOTE_Portal: 'Portal',
    SIMPLE_NOTE_PostalCode: 'Código postal',
    SIMPLE_NOTE_PrescriptorRecordId: 'Identificador do prescritor',
    SIMPLE_NOTE_Province: 'Província',
    SIMPLE_NOTE_RealEstateNumber: 'Propriedade',
    SIMPLE_NOTE_RegisterName: 'Nome do registo',
    SIMPLE_NOTE_RegisterNumber: 'Número do registo',
    SIMPLE_NOTE_Section: 'Secção',
    SIMPLE_NOTE_sendedDateTime: 'Data de envio',
    SIMPLE_NOTE_StreetName: 'Nome da rua',
    SIMPLE_NOTE_StreetNumber: 'Número da rua',
    SIMPLE_NOTE_StreetType: 'Tipo de rua',
    SIMPLE_NOTES_add_new_simple_note: '+ Adicionar nota simples',
    SIMPLE_NOTES_DETAIL_recivedFromRegister: 'Recebeu a nota simples do registo da propriedades?',
    SIMPLE_NOTES_failedReceivedFromRegister: 'O registo de propriedades não possui o documento',
    SIMPLE_NOTES_mustUploadAFile: 'Tem que carregar a nota simples',
    SIMPLE_NOTES_recivedFromRegister: 'Recebido do registo de propriedades',
    SIMPLE_NOTES_sendToClient: 'Enviar ao cliente',
    SIMPLE_NOTES_sendToPropertyRegister: 'Enviar para o registo de propriedades',
    SIMPLE_NOTES_simple_notes: 'Notas simples',
    simpleNote: 'Nota simples',
    smartaValue: 'Valor de Smarta',
    someTranslatesShouldBeAdded: 'Algunas traducciones deben ser añadidas',
    SortingDepot: 'Pavilhão industrial',
    spanish: 'Espanhol',
    Sports: 'Área de desporto',
    sportsZone: 'Sports Zone',
    stairs: 'Escada',
    Stairs: 'Escada',
    StartedAppraisals: 'Avaliações iniciadas',
    State: 'Estado',
    Stays: 'Estadias',
    StayType: 'Tipo de quarto',
    STEPPER_mobileTooltip: 'Este passo é executado na aplicação móvel',
    StorageRoom: 'Arrecadação',
    StreetCategory: 'Categoria da rua',
    streetName: 'Nome da rua',
    StreetName: 'Nome da rua',
    streetNumber: 'Número da rua',
    StreetNumber: 'Número da rua',
    streetType: 'Tipo de via',
    StreetType: 'Tipo de via',
    StudentResidence: 'Residência de estudantes',
    successAction: 'A ação foi executada corretamente',
    successful: 'Bem sucedido',
    successfully_completed: 'Executado com sucesso',
    SUPPLIER_COMPANIES_add_appraisal_company_title: 'Criar nova empresa de avaliação',
    SUPPLIER_COMPANIES_add_appraisal_company: '+ Adicionar empresa de avaliação',
    SUPPLIER_COMPANIES_appraisal_companies: 'Empresas de avaliação',
    SUPPLIER_COMPANIES_cif: 'CIF',
    SUPPLIER_COMPANIES_contactEmail: 'E-mail',
    SUPPLIER_COMPANIES_contactPhone: 'Telefone',
    SUPPLIER_COMPANIES_description: 'Descrição',
    SUPPLIER_COMPANIES_name: 'Nome',
    SUPPLIER_COMPANIES_observations: 'Observações',
    SUPPLIER_COMPANIES_profile: 'Perfil',
    sureAssignProfessionalManual: 'Tem a certeza de deseja atribuir um técnico manualmente? Esta ação removerá o trabajo da bolsa.',
    sureWantToExit: 'Tem a certeza de que deseja sair? Tem alterações não guardadas.',
    surface: 'Superfície',
    SurfaceArea: 'Superfície',
    surfaceCommons: 'Superfície construída com partes comuns ({{surfaceUnit}})',
    surfaceFrom: 'Superfície de',
    surfaceGroundPiece: 'Superfície da parcela (ha)',
    surfacePlotLand: 'Superfície da parcela',
    surfaces: 'Superfícies',
    Surfaces: 'Superfícies',
    property_features: 'Características da propriedade',
    surfacesAndTotalValue: 'Superfícies e valor da oferta',
    SurfacesObservations: 'Observações',
    surfaceTo: 'Surface to',
    surfaceUseful: "Superfície útil ({{surfaceUnit}})'",
    surfaceWithoutCommons: 'Superfície construída sem partes comuns ({{surfaceUnit}})',
    Surveillance: 'Vigilância',
    tab_information_updated_please_reload_tab: 'A informação foi atualizada. Atualize o separador',
    tax_type: 'Tipo de imposto',
    tax: 'Imposto',
    TECHNICAL_MANAGER_USER: 'Gestor de técnicos',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Adicionar gestor de técnicos',
    TECHNICAL_MANAGER_USERS_appraisals_in_process_count: 'Avaliações em processamento',
    TECHNICAL_MANAGER_USERS_email: 'E-mail',
    TECHNICAL_MANAGER_USERS_first_name: 'Nome',
    TECHNICAL_MANAGER_USERS_is_active: 'Utilizador ativo',
    TECHNICAL_MANAGER_USERS_lastname: 'Apelidos',
    TECHNICAL_MANAGER_USERS_national_identity_document: 'NIF/NIE/Passaporte',
    TECHNICAL_MANAGER_USERS_plus_add_user: '+ Adicionar utilizador',
    TECHNICAL_MANAGER_USERS_position: 'Função',
    TECHNICAL_MANAGER_USERS: 'Utilizadores gestores de técnicos',
    technical_managers: 'Gestores de técnicos',
    technicalDept: 'Departamento técnico',
    TechnicalManager: 'Gestores de técnicos',
    TechnicalManagerUser: 'Gestor de técnicos',
    technician: 'Técnico',
    tendingToSchedule: 'A aguardar agendamento',
    TENURES_warningMessage: 'A soma das percentagens deve ser de 100%',
    Terrace: 'Terraço',
    TERRAIN_CLUSTER_all: 'Todos',
    TERRAIN_CLUSTER_AppliedPremiumPercent: 'Prémio aplicado (%)',
    TERRAIN_CLUSTER_applyToAll: 'Aplicar a todos',
    TERRAIN_CLUSTER_deadlinesAndComercialParamethers: 'Prazos e parâmetros económicos',
    TERRAIN_CLUSTER_expenses: 'Despesas',
    TERRAIN_CLUSTER_ExternalFinancingIncreaseJustification: 'Justifique o aumento do financiamento',
    TERRAIN_CLUSTER_ExternalFinancingIncreasePercent: 'Aumento do financiamento (%)',
    TERRAIN_CLUSTER_filterByIncome: 'Filtrar por receita',
    TERRAIN_CLUSTER_filterByExpense: 'Filtrar por despesa',
    TERRAIN_CLUSTER_FreeRateConstantPercent: 'Taxa livre constante (%)',
    TERRAIN_CLUSTER_FreeRateType: 'Constante (Real) / Nominal (%)',
    TERRAIN_CLUSTER_income: 'Receitas',
    TERRAIN_CLUSTER_IncomeExpenseDiff: 'Receitas act. - Despesas act.',
    TERRAIN_CLUSTER_ipc: 'IPC (%)',
    TERRAIN_CLUSTER_isActiveFromDateTime: 'Data de início de promoção',
    TERRAIN_CLUSTER_isActiveUntilDateTime: 'Data do fim de promoção',
    TERRAIN_CLUSTER_isConstructionFromDateTime: 'Data de início da construção',
    TERRAIN_CLUSTER_isConstructionUntilDateTime: 'Data final da construção',
    TERRAIN_CLUSTER_isSalesFromDateTime: 'Data de início das vendas',
    TERRAIN_CLUSTER_isSalesUntilDateTime: 'Data final das vendas',
    TERRAIN_CLUSTER_Period: 'Período',
    TERRAIN_CLUSTER_PeriodTotalNetExpense: 'TOTAL de despesas ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetExpenseDiff: 'TOTAL de receitas - despesas ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetIncome: 'TOTAL de receitas ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalUpdatedExpense: 'TOTAL de despesas act.',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncome: 'TOTAL de receitas act.',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncomeExpenseDiff: 'TOTAL ATUALIZADO ({{currency}})',
    TERRAIN_CLUSTER_PeriodUpdateMonthlyRatePercent: 'Taxa act. mensal',
    TERRAIN_CLUSTER_RD_EX_Period: 'Período',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpense: 'TOTAL despesas atribuídas ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpenseDiff: 'Renda TOTAL - Despesas atribuídas ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetIncome: 'TOTAL de receita atribuída ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedExpense: 'TOTAL despesas atualizadas',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncome: 'TOTAL de renda atualizada',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncomeExpenseDiff: 'TOTAL ATUALIZADO ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodUpdateMonthlyRatePercent: 'Rate act. por mês',
    TERRAIN_CLUSTER_RiskFreePercent: 'Taxa sem risco  (%)',
    TERRAIN_CLUSTER_RiskPremiumWeighted: 'Prémio de risco ponderado',
    TERRAIN_CLUSTER_Totals: 'Totais',
    TERRAIN_CLUSTER_totalToBeAsigned: 'Total por atribuir',
    TERRAIN_CLUSTER_UpdateExpense: 'Despesa atualizada',
    TERRAIN_CLUSTER_UpdateIncome: 'Receita atualizada',
    TERRAIN_CLUSTER_UpdateMonthlyRatePercent: 'Taxa act. Mensal eq.',
    TERRAIN_CLUSTER_UpdateRatePercent: 'Tipo de atualização',
    TerrainCalculationMethod_0: 'Residual estático',
    TerrainCalculationMethod_1: 'Residual dinâmico',
    terrainToAddListTitle: 'Listagem de terrenos disponíveis para adicionar',
    testTranslation: 'Teste para ver se a fusão está feita',
    the_action_was_successful: 'A ação foi executada corretamente',
    ThirdAgeResidence: 'Lar de terceira idade',
    toAssign: 'A aguardar atribuição de técnico',
    toCreateManualCustomerMessage: 'Para criar um cliente manualmente é necessário desmarcar o cliente selecionado pressionando a cruz',
    toDate: 'Até',
    TotalBuiltSurfaceWithCommonSurfaces: 'Superfície construída com partes comuns ({{surfaceUnit}})',
    TotalBuiltSurfaceWithoutCommonSurfaces: 'Superfície construída sem partes comuns ({{surfaceUnit}})',
    TotalFloorsAboveGround: 'Pisos acima do solo',
    TotalFloorsBellowGround: 'Pisos abaixo do solo',
    TotalUsefulSurface: "Superfície útil ({{surfaceUnit}})'",
    usefulSurface: 'Superficie útil',
    translationWillBeDeleted: 'Se borrará la traducción. ¿Estás seguro?',
    TreeActionsEnum_0: 'Edição múltipla',
    TreeActionsEnum_1: 'Avaliar terrenos',
    TreeActionsEnum_2: 'Agrupar avaliações',
    TreeActionsEnum_3: 'Modificar estrutura',
    TreeActionsEnum_4: 'Importar ativos das solicitações',
    TreeActionsEnum_5: 'Recalcular métodos',
    type: 'Tipo de avaliação',
    typeOfAppraisal: 'Tipo',
    Typologies: 'Tipologias',
    typology: 'Tipologia',
    uncompleteRequiredFields: 'Faltam campos necessários',
    unexpectedError: 'Error inesperado',
    unitValue: 'Valor de oferta ({{currency_per_surfaceUnit}}/mês)',
    Unproductive: 'Improdutivo',
    until: 'Até',
    Urban: 'Urbano',
    UrbanisticSituation: 'Situação urbanística',
    use: 'Uso',
    USER_FORM_ConfirmPassword: 'Repita a palavra-passe',
    USER_FORM_CustomerId: 'Cliente',
    USER_FORM_IsActive: 'Utilizador ativo',
    USER_FORM_RequesterCompanyId: 'Prescritores',
    User: 'Utilizadores',
    userNotAssigned: 'Utilizador por atribuir',
    userNotAvailable: 'Utilizador indisponível',
    USERS_add_new_client_or_prescriber_user: 'Adicionar novo utilizador de cliente ou prescritor',
    USERS_add_new_user: 'Adicionar nova utilizador',
    USERS_add: 'Adicionar utilizador',
    USERS_addWarningModal: 'Lembre-se que o preço da sua tarifa depende do número de utilizadores associados e das avaliações feitas. Se quiser adicionar um novo, será cobrado o valor adicional no método de pagamento selecionado no seu perfil.',
    USERS_commercial: 'Desempenha a função de gestor comercial',
    USERS_createIncompatibility: 'Criar incompatibilidades',
    USERS_detail: 'Detalhe',
    USERS_edit_user: 'Editar utilizador',
    USERS_list: 'Listagem',
    USERS_process: 'Desempenha a função de gestor de processos',
    USERS_professional: 'Desempenha a função de profissional',
    USERS_receiveNotificationsByEmail: 'Receber notificações no e-mail',
    USERS_roles: 'Funções',
    USERS_supplierCompany: 'Empresa de avaliação',
    USERS_technical: 'Desempenha a função de gestor de técnicos',
    USERS_roles_incompatibilities: 'As funções que você selecionou são incompatíveis entre si.',
    USERS_user: 'Utilizador',
    USERS_userclient: 'Usuário cliente',
    users: 'Utilizadores',
    USERS: 'Utilizadores',
    useType: 'Tipo de uso',
    VALIDATE_APPRAISAL_ASSETS_MODAL_appraisalData: 'Dados avaliação',
    VALIDATE_APPRAISAL_ASSETS_MODAL_fieldName: 'Nome do campo',
    VALIDATE_APPRAISAL_ASSETS_MODAL_originData: 'Dados origem',
    VALIDATE_APPRAISAL_ASSETS_MODAL_saveChange: 'Guardar alteração',
    validated: 'Validadas',
    validatedSing: 'Validada',
    validating: 'Em processo de validação',
    validation: 'Validação',
    ValidationAppraisalStepsWithNumber_1: '1 - Alta',
    ValidationAppraisalStepsWithNumber_2: '2 - Gerenciador de processos designado',
    ValidationAppraisalStepsWithNumber_3: '3 - Gerente técnico designado',
    ValidationAppraisalStepsWithNumber_4: '4 - Técnico designado',
    ValidationAppraisalStepsWithNumber_5: '5 - Validação iniciada',
    ValidationAppraisalStepsWithNumber_6: '6 - Validação concluída',
    ValidationAppraisalStepsWithNumber_7: '7 - Entregue ao cliente',
    ValidationFinishDatetime: 'Data de validação',
    validationFinished: 'Validação concluída',
    validationProcess: 'Em processo de validação',
    valoratedBatches: 'Lotes valorados',
    VALUATION_LIST_residualDynamicDeleteMessage: 'Este método só pode ser removido removendo o método residual dinâmico de dados comum correspondente na guia Status do trabalho',
    VALUATION_LIST_residualDynamicInfo: 'O método residual dinâmico será adicionado a partir do capítulo Estado do trabalho',
    VALUATIONS_currentValuations: 'Avaliação atual',
    VALUATIONS_edit_reversion: 'Editar valor de reversão',
    VALUATIONS_futureValuations: 'Avaliação H.E.T.',
    VALUATIONS_reversion_observations: 'Observações sobre o valor de reversão',
    VALUATIONS_edit: 'Tem certeza que quer sair? Existem campos a preencher que são obrigatórios',
    valuations: 'Avaliações',
    visit: 'Visita',
    Volume: 'Volume',
    vpo: 'Vivenda de proteção oficial',
    WallType: 'Tipo de paredes',
    Warehouse: 'Armazém',
    warning: 'Advertência',
    warningModalTitle: 'AVISO!',
    warnings: 'Advertências',
    withholding: 'Retenção de %IRPF',
    year: 'Ano',
    yes: 'sim',
    yesSaving: 'Sim, a guardar',
    yesWithoutSaving: 'Sim, sem guardar',
    your_business: 'Seu negócio',
    yourLicenses: 'Tus licencias',
    AUTHORITIES_canNotAccess: 'Você não tem permissão para acessar',
    AUTHORITIES_permissions: 'Permissões',

    APPRAISALS_COMMUNICATIONS_BREADCRUMB_communications: 'Comunicações',
    APPRAISALS_COMMUNICATIONS_list_title: 'Comunicações',
    APPRAISALS_COMMUNICATIONS_list_tab_communications: 'Comunicações',
    APPRAISALS_COMMUNICATIONS_list_tab_records: 'Arquivos',
    APPRAISALS_COMMUNICATIONS_list_id: 'ID',
    APPRAISALS_COMMUNICATIONS_list_request_id: 'ID do Pedido',
    APPRAISALS_COMMUNICATIONS_list_prescriptorName: 'Nome do solicitante',
    APPRAISALS_COMMUNICATIONS_list_status: 'Estado',
    APPRAISALS_COMMUNICATIONS_list_lastEventDate: 'Data do último evento',
    APPRAISALS_COMMUNICATIONS_list_emitter: 'Emissor',

    REQUESTS_COMMUNICATIONS_BREADCRUMB_communications: 'Comunicações',
    REQUESTS_COMMUNICATIONS_list_tab_communications: 'Comunicações',
    REQUESTS_COMMUNICATIONS_list_tab_records: 'Arquivos',
    REQUESTS_COMMUNICATIONS_list_title: 'Comunicações',
    REQUESTS_COMMUNICATIONS_list_id: 'ID',
    REQUESTS_COMMUNICATIONS_list_request_id: 'ID do Pedido',
    REQUESTS_COMMUNICATIONS_list_prescriptorName: 'Nome do solicitante',
    REQUESTS_COMMUNICATIONS_list_status: 'Estado',
    REQUESTS_COMMUNICATIONS_list_lastEventDate: 'Data do último evento',
    REQUESTS_COMMUNICATIONS_list_emitter: 'Emissor',
    VALORACIONES_COMUNICACIONES_list_action: 'Ação',
    APPRAISALS_COMMUNICATIONS_info: 'Não há informaçõesn',

    zoom: 'aproximação',
    rotate: 'girar',
    reflect: 'refletir',
    straightened: 'endireitar',
    resetImageCropperChanges: 'Limpar alterações',

    communications: 'Comunicações',
    REQUESTS_unknown_user: 'Usuário desconhecido',

    APPRAISALS_COMMUNICATIONS_internal_id: 'ID Interno',
    REQUESTS_COMMUNICATIONS_internal_id: 'ID Interno',

    APPRAISALS_COMMUNICATIONS_list_workflowItemId: 'ID do processo',
    APPRAISALS_COMMUNICATIONS_list_requesterCompany: 'Solicitante',
    APPRAISALS_COMMUNICATIONS_list_state: 'Estado',
    APPRAISALS_COMMUNICATIONS_list_lastUpdateDateTime: 'Última atualização',
    APPRAISALS_COMMUNICATIONS_list_lastUpdatedUser: 'Usuário',

    APPRAISALS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'ID do proceso',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_state: 'Estado',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Última atualização',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Última atualização',
    APPRAISAL_HISTORY_COMMUNICATIONS_there_is_no_communications: 'Não há evento de comunicação',

    REQUESTS_COMMUNICATIONS_list_workflowItemId: 'ID do processo',
    REQUESTS_COMMUNICATIONS_list_requesterCompany: 'Solicitante',
    REQUESTS_COMMUNICATIONS_list_state: 'Estado',
    REQUESTS_COMMUNICATIONS_list_lastUpdateDateTime: 'Última atualização',
    REQUESTS_COMMUNICATIONS_list_lastUpdatedUser: 'Usuário',

    REQUESTS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'ID do proceso',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_state: 'Estado',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Última atualização',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Última atualização',

    ASSETS_category: 'Categoria',
    ASSETS_targetType: 'Tipo de destino',
    ASSETS_restaurant: 'Restaurante',
    ASSETS_meetingRoom: 'Sala de reunião/conferência',
    ASSETS_sportsFacilities: 'Instalações Esportivas',
    ASSETS_hotelBuildingCharacteristics: 'Características do hotel',

    APPRAISALS_records_history: 'Registro',
    APPRAISALS_records_history_communications: 'Comunicações',
    APPRAISALS_records_history_records: 'Registros',

    REQUESTS_records_history_communications: 'Comunicações',

    TOUR_button: 'Precisa de ajuda?',
    TOUR_next: 'Próximo',
    TOUR_prev: 'Voltar',
    TOUR_done: 'Ok!',
    TOUR_processManager: 'Por favor, selecione um <b>Gerente de Processo</b> para continuar!',
    TOUR_appraiser: 'Por favor, selecione um <b>Técnico</b> para continuar! Lembre-se de que as etapas a seguir são executadas no <b>aplicativo móvel</b>.',
    TOUR_technicalManager: 'Selecione um <b>Gerente Técnico</b> e inicie a validação do relatório!',
    TOUR_stepMobile: '…se não, você pode fazer o relatório do <b> aplicativo móvel Tasafy </b>!',
    TOUR_advanceSteps: 'Você prefere <b>avançar o tour</b> sem passar pelo aplicativo móvel? Preencha as datas e você pode avançar para o passo 7...',
    TOUR_startValdiation: 'Você pode iniciar a validação do relatório daqui',
    TOUR_finishOkValidation: 'Se a validação estiver correta e você quiser finalizá-la, você pode fazer daqui...',
    TOUR_finishKoValidation: '...pelo contrário, se você achar que há um erro, clique aqui para que o técnico o analise.',
    TOUR_documents: 'Vá para a seção <b>Documentos</b>, lá você encontrará seu relatório em formato <b>PDF</b>!',
    TOUR_appraiserSignature: 'Você precisa fazer alguma <b>modificação</b> no arquivo? Passe o arquivo para 11 e abra uma <b>reivindicação</b>. Marcando-o como <b>prossegue</b>, o arquivo retornará à etapa 8 para que você possa iniciar a validação novamente e alterar o que precisar.',
    TOUR_reclamationNotLastStep: 'Você precisa fazer alguma <b>modificação</b> no arquivo? Passe o arquivo para 11 e abra uma <b>reivindicação</b>. Marcando-o como <b>prossegue</b>, o arquivo retornará à etapa 8 para que você possa iniciar a validação novamente e alterar o que precisar.Do you need to make any <b>modifications</b> to the file? Pass the file to 11 and open a <b>claim</b>. Marking it as <b>proceeds</b> will return the file to step 8 so you can start the validation again and change what you need.',
    TOUR_reclamationLastStep: 'Você precisa fazer alguma <b>modificação</b> no arquivo? Abra uma <b>reclamação</b>. Marcando-o como <b>prossegue</b>, o arquivo retornará à etapa 8 para que você possa iniciar a validação novamente e alterar o que precisar.',
    TOUR_firstEntityPost: 'Quer fazer seu primeiro arquivo? Certifique-se de ter criado um cliente anteriormente',
    TOUR_firstAppraisalPost: 'Depois de criar o cliente, você pode começar a fazer seu primeiro relatório. Não se esqueça de marcar a verificação de execução pela Tasafy para preencher os campos do relatório.',
    TOUR_firstVisitToAppraisal: 'Este é seu <b>primeiro arquivo</b>? Você pode clicar em Tafy para ajudá-lo nos pontos-chave para que ele diga como continuar.',
    ASSETS_otherFeaturesRusticEstate: 'Outras características da propriedade',
    ASSETS_electricalEnergy: 'Energia Elétrica',
    ASSETS_accessToRoadInfrastructure: 'Acesso à infraestrutura rodoviária',
    ASSETS_legalizedIrrigationWater: 'Água de Irrigação Legalizada',
    ASSETS_expectedLeverage: 'Uso esperado (Imóvel rural com expectativas urbanas)',

    ASSETS_builtSurfaceOfHouseholds: 'Superfície de casas construídas',
    ASSETS_builtSurfaceForAgriculturalUses: 'Superfície construída para usos agrícolas',
    ASSETS_coveredTerraceArea: 'Área de terraço coberta',
    ASSETS_openTerraceArea: 'Área de terraço aberto',

    WELCOME_CONNECTIONS_step1_title: 'Bem-vindo ao mundo das conexões no Tasafy!',
    WELCOME_CONNECTIONS_step1_paragraph1: 'Mergulhe no universo <b>Tasafy</b> e conecte-se com os diferentes <i>jogadores</i> para poder realizar avaliações.',
    WELCOME_CONNECTIONS_step1_paragraph2: 'O que isso significa?',

    WELCOME_CONNECTIONS_step2_paragraph1: 'Nesta seção você encontrará dois códigos diferentes que você pode compartilhar para se conectar com outras licenças.',
    WELCOME_CONNECTIONS_step2_section1_title: 'CÓDIGO DE LICENÇA',
    WELCOME_CONNECTIONS_step2_section1_paragraph1: 'É o código que começa com <b>L-</b>. Forneça este código a outros usuários para que eles possam se conectar com você e começar a trabalhar para você como profissionais',
    WELCOME_CONNECTIONS_step2_section2_title: 'CÓDIGO PROFISSIONAL',
    WELCOME_CONNECTIONS_step2_section2_paragraph1: 'É o código que começa com <b>U-</b>. Dê este código a outras licenças para que elas possam se conectar com você e você se torne parte da rede delas para que elas possam comissionar seu trabalho.',

    WELCOME_CONNECTIONS_step3_paragraph1: 'Se você tiver algum código com o qual deseja se conectar, poderá adicionar uma conexão a partir do botão <b>Nova conexão</b>. ',
    WELCOME_CONNECTIONS_step3_paragraph2: 'Só assim, você pode encomendar o trabalho de todos os profissionais que fazem parte da sua rede e vice-versa, se você é quem se conecta a outras licenças eles podem encomendar o trabalho de você.',

    WELCOME_CONNECTIONS_step4_paragraph1: 'No histórico você pode ver todas as conexões que tem e a qualquer momento pode cancelar essa conexão se não quiser mais que um usuário faça parte da sua rede ou você faça parte da rede dele.',
    WELCOME_CONNECTIONS_step4_paragraph2: 'Caso este usuário tenha arquivos ativos, você pode reatribuí-los a qualquer usuário pertencente à sua licença.',

    WELCOME_CONNECTIONS_step5_paragraph1: 'No seu perfil, você poderá ver as diferentes contas às quais está conectado, poderá até ver em Meu trabalho os arquivos pendentes que você atribuiu em cada uma delas.',
    WELCOME_CONNECTIONS_step5_button: 'Vamos nos conectar!',
    APPRAISAL_REQUEST_DATA_customer_modal_tooltip_message: 'Você pode criar uma solicitação a partir do painel de solicitações',
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "Você pode criar um cliente no menu superior 'Entidades'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "Você pode criar um prescritor no menu superior 'Entidades'",
    QUALITY_CONTROL_customer_modal_tooltip_message: "Você pode criar um cliente no menu superior 'Entidades'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "Você pode criar um cliente no menu superior 'Entidades'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "Você pode criar um prescritor no menu superior 'Entidades'",
    SELECT_CUSTOMER_MODAL_table_no_content_label: 'Você ainda não tem nenhum cliente criado',

    COMMUNICATIONS_added: 'Adicionado',
    COMMUNICATIONS_information: 'Informações',
    COMMUNICATIONS_body: 'Corpo',
    COMMUNICATIONS_updated: 'Atualizado',
    COMMUNICATIONS_lastUpdated: 'Última atualização',
    COMMUNICATIONS_events: 'Eventos',
    COMMUNICATIONS_of: 'de',
    COMMUNICATIONS_seeAll: '< Ver todas as comunicações',
    APPRAISAL_CLAIMS_proceed: 'Procede',
    APPRAISAL_CLAIMS_not_proceed: 'No procede',
    APPRAISAL_CLAIMS_solve_claim: 'Resolver reclamação',

    BOTTOM_BAR_error_dialog_enabled: 'Modal de errores habilitado',

    APPRAISALS_errorInForm: 'Erro em algum campo do formulário',

    APPRAISALS_information: 'Informações do aplicativo',
    REQUESTS_information: 'Informações do aplicativo',
    EXTRA_INFORMATION_informationProvidedByEntity: 'Dados fornecidos pela entidade',
    EXTRA_INFORMATION_noExtraInformation: 'Nenhum dado de referência',
    EXTRA_INFORMATION_title: 'Título',
    EXTRA_INFORMATION_description: 'Descrição',
    EXTRA_INFORMATION_add: 'Añadido por',
    EXTRA_INFORMATION_date: 'Data Adicionada',

    REQUESTS_reportTypes: 'Tipo de relatório',
    APPRAISALS_reportTypes: 'Tipo de relatório',

    ASSETS_publicTransportation: 'Transporte Público',
    ASSETS_equipmentEndowments: 'Equipment Endowments',
    ASSETS_businessEnvironment: 'Ambiente de Negócios',
    ASSETS_tertiaryEnvironment: 'Ambiente terciário',
    ASSETS_industrialEnvironment: 'Ambiente industrial',
    ASSETS_marketingPropertyDifficulties: 'Difícil comercializar o imóvel?',
    ASSETS_numberOfElevators: 'Número de elevadores',
    ASSETS_witnessInternalState: 'Testemunha estado interno',
    ASSETS_buildingNumberOfFloors: 'Número de andares do edifício',
    ASSETS_buildingNumberOfElevators: 'Número de elevadores no prédio',

    ASSETS_publicParkings: 'Estacionamentos públicos',
    ASSETS_assetInternalState: 'Estado interno da propriedade',
    ASSETS_assetInternalOrExternal: 'Propriedade externa ou interna',
    APPRAISALS_selectDocuments: 'Selecionar documentos de avaliação',
    APPRAISALS_REPORT_selectDocuments: 'Selecionar documentos',
    APPRAISALS_downloadDocumentsSuccess: 'Os documentos foram baixados',
    APPRAISALS_downloadDocumentsError: 'Erro ao baixar documentos',

    MULTIPLE_FILE_UPLOAD_noItemsAdded: 'Não há itens adicionados.',
    APPRAISALS_REPORT_selectAll: 'Selecionar tudo',

    ASSETS_notStartedStoppedUrbanization: 'Urbanização não iniciada ou interrompida',
    ASSETS_orientation: 'Orientação',

    CLUSTER_ITEMS_LIST_can_not_be_removed_at_least_two_assets: 'Impossível excluir. Deve haver pelo menos dois ativos por pool.',
    REQUESTS_invoicesInformation: 'Informações de feedback',
    REQUESTS_selectResolver: 'Selecionar Sociedade de Avaliação',
    REQUESTS_resolver: 'Sociedade de Avaliação',
    REQUESTS_DASHBOARD_recordState: 'Status atual das solicitações',
    REQUESTS_DASHBOARD_totalOrders: 'Total de solicitações: ',
    records: 'registros',

    NETWORK_basicClientCardTitle: 'Permitir que empresas, escritórios, etc… trabalhem com você!',
    NETWORK_basicClientCardContent: 'Este código de licença permite que outras pessoas encontrem você e se conectem com você para que você possa comissionar o trabalho delas.',
    NETWORK_licenseeToBasicClientMessage: 'Eu quero participar da sua licença para que você possa fazer pedidos de mim...',
    NETWORK_basicClientToLicenseeMessage: 'Quero que você participe da minha licença para que eu possa lidar com aplicativos...',
    NETWORK_newLicenseeUserConnection: 'Nova licença/conexão profissional',
    NETWORK_newLicenseeRelationshipLicenseeConnection: 'Nova conexão com o cliente',
    NETWORK_newLicenseeRelationshipBasicClientConnection: 'Nova Conexão Licenciada',
    APPRAISALS_REPORT_expenseType: 'Tipo de despesa',

    ASSET_numberOfCopiesModalTitle: 'quantas vezes você deseja copiá-lo?',
    ASSET_numberOfCopies: 'Número de cópias',
    APPRAISALS_REPORT_copy: 'Copiar',
    APPRAISALS_LIST_professional: 'Profissional',
    NETWORK_myNetwork: 'Minha rede',
    NETWORK_customerConnections: 'Conexões do cliente',

    EXTRA_INFO_addNewData: '+ Adicionar novos dados',
    EXTRA_INFO_addNewDataTitle: 'Adicionar novos dados',
    EXTRA_INFO_generalDataTitle: 'Dados Gerais',
    EXTRA_INFO_noDataToAdd: 'Não há dados para adicionar',
    EXTRA_INFO_succesRemovingData: 'Dados removidos com sucesso',
    EXTRA_INFO_errorRemovingData: 'Erro ao remover dados',
    EXTRA_INFO_errorCreatingData: 'Erro ao criar dados',
    EXTRA_INFO_succesCreatingData: 'Dados criados com sucesso',
    ASSET_numberOfCopiesError: 'Número de cópias entre 1 e 20',
    BADGE_visitScheduleDeadlineExpiredLabel: 'Fora do tempo para agendar',
    BADGE_appraiserAllDataSentDeadlineExpiredLbel: 'Denunciar entrega atrasada',
    BADGE_customerDeadlineExpiredLabel: 'Entrega ao cliente após o prazo',
    BADGE_withIncidentsLabel: 'Com incidentes',
    BADGE_withClaimsLabel: 'Com reivindicações',
    BADGE_cancelledLabel: 'Cancelado',

    APPRAISALS_advance_visit: 'Visita antecipada',
    APPRAISALS_cantAdvanceVisitMessage: 'Você não tem permissão para realizar esta ação. Você tem que ter o papel profissional marcado.',
    APPRAISALS_visitScheduleDate: 'Data da visita agendada',
    APPRAISALS_startedVisitDate: 'Data de início da visita',
    APPRAISAL_finishVisitDate: 'Data da visita concluída',
    APPRAISALS_copyFromVisitScheduleDate: 'Copiar data da visita agendada',
    APPRAISALS_confirmImportReport: 'Você vai importar os dados do relatório selecionado para o atual, tem certeza?',
    APPRAISALS_ImportedConfirmed: 'Os dados foram importados com sucesso',
    REQUEST_errorSelectReportTypeFirst: 'O tipo de relatório deve primeiro ser selecionado',
    REPORT_CUSTOMIZATION_includeTextInCertificate: 'Incluir texto do certificado',
    REPORT_CUSTOMIZATION_certificateTextLabel: 'Texto do certificado (parte superior)',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompany: 'Empresa de avaliação?',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompanyMessage: 'NOME, representante legal de EMPRESA',
    WITNESS_copy: 'Copiar',
    WITNESS_confirmDeleteMessage: 'Tem certeza de que deseja excluir o testemunha do seu banco de dados?',
    WITNESS_OwnComparables: 'Próprios comparáveis',
    WITNESS_IdealistaComparables: 'Idealista comparável',
    REQUESTS_payments: 'Pagamentos',
    REQUESTS_paymentId: 'ID do pagamento',
    REQUESTS_paymentState: 'Estado',
    REQUESTS_paymentRedsysPaymentIdContains: 'Redsys Id',

    APPRAISALS_advanceTo: 'Avançar para',
    APPRAISALS_chooseStepToAdvance: 'Escolha a etapa para a qual deseja avançar.',
    APPRAISALS_chooseSuggestedDate: 'Deseja escolher alguma das datas propostas para agendar a visita?',
    APPRAISALS_chooseProposedDate: 'Data proposta',
    APPRAISALS_completeDates: 'Datas completas',

    IMPORT_ASSETS_REQUEST_message: 'Você vai importar os ativos da solicitação',
    ASSETS_ceeEmissions: 'Emissões (kgCO₂/m² año)',
    ASSETS_ceeEmissionsLetter: 'Letra missões',
    ASSETS_ceeDemand: 'Exigem (kWH/m² año)',
    ASSETS_ceeDemandLetter: 'Letra de exigem',
    ASSETS_ceeHeating: 'Aquecimento',
    ASSETS_ceeRefrigeration: 'Refrigeração',
    ASSETS_ceeLighting: 'Iluminação',
    ASSETS_CEEConsuEner: 'Consumo total de energia (Kwh/m² ano)',
    ASSETS_CEENormatAnroConst: 'Regulamento que se aplica ao ano de construção',
    ASSETS_CEECatAnter: 'Classificação energética anterior',
    ASSETS_CEEFchRecCertif: 'Data da visita coletada no CEE',
    ASSETS_CEEFchLimValid: 'Data limite de validade CEE',
    ASSETS_CEEFchEmisionCertif: 'data de emissão CEE',
    ASSETS_CEEMejoraCalAntCEE: 'A qualificação melhora em relação ao CEE anterior?',
    ASSETS_CEEPorceImprovement: 'Porcentagem de melhoria',
    ASSETS_CEECo2Emisions: 'Emissões de dióxido de Carbono (KgCO2/M2)',
    APPRAISALS_bindingInternalExternal: 'Interno/Externo',
    APPRAISALS_vinculationFile: 'Arquivo em:',
    APPRAISALS_notifyToEntity: 'Notificar Entidade',
    APPRAISALS_entityObservations: 'Observações de Entidade',

    COMMUNICATIONS_FILTER_BAR_needToDoSomething: 'Precisa de uma ação',
    COMMUNICATIONS_FILTER_BAR_inProcess: 'Em processamento',
    COMMUNICATIONS_FILTER_BAR_finishedAndReviewed: 'Concluído o revisado',

    COMMUNICATIONS_markAsReviewed: 'Marcar como revisada',
    COMMUNICATIONS_reviewed: 'Revisada: ',

    statusFilters: 'Status do trabajo',
    REPORT_validation_calculate: 'Validações de atualização',
    REPORT_validation_watch: 'Validações de informações',
    REPORT_validation_calculating: 'Calculando...',
    REPORT_validation_succeed: 'Clique aqui para ver o resultado da validação',
    APPRAISALS_externalManager: 'Validador Básico',

    ACCOUNT_MANAGEMENT_invoiceCustomization: 'Configuración de facturas',
    ACCOUNT_MANAGEMENT_areYouSureDeleteLogo: 'Tem certeza de que deseja remover o logotipo personalizado de suas novas faturas?',
    ACCOUNT_MANAGEMENT_customLogo: 'Logo personalizado',
    REQUESTS_expensesProvisionAllAmountInEuro: 'Provisão de Proveitos/Despesas',
    APPRAISALS_precedentType: 'Tipo de precedente',
    APPRAISALS_updatePrecedents: 'Atualizar precedentes',
    APPRAISALS_precedents: 'Precedentes',
    APPRAISALS_lookingForPrecedents: 'Estamos procurando precedentes...',
    APPRAISALS_precedentsFounded: 'Foram encontrados aplicativos para esta avaliação. Você pode encontrar esses arquivos na seção de plano de fundo.',
    APPRAISALS_precedentsNotFounded: 'Nenhum precedente foi encontrado para esta avaliação.',
    APPRAISALS_precedentsTitleTooltip: 'A lista de avaliações que existe no sistema é exibida independentemente da data de conclusão.',
    APPRAISALS_aditionalDataNotCompleted: 'Os dados apresentados nas informações do pedido devem ser adicionados em Dados Adicionais. Caso contrário, não será possível enviar.',
    LINK_tooltip: 'Use controle + clique ou roda do mouse para abrir em uma nova guia',
    COMPARABLES_environmentFeatures: 'Características do ambiente',
    COMPARABLES_situationWithinArea: 'Situação dentro da área',
    COMPARABLES_socioEconomicLevel: 'Área de nível socioeconômico',
    COMPARABLES_fastTrackProximity: 'FastTrack proximity',
    COMPARABLES_buildingType: 'Tipo de área de construção',

    COOKIES_headerTitle: 'Suas configurações de cookies',
    COOKIES_headerDescription: 'Nós cuidamos bem de você e de seus dados. Você pode ler mais sobre como usamos cookies e atualizar suas configurações de cookies aqui.',
    COOKIES_analyticsCookies: 'Cookies de análise',
    COOKIES_analyticsCookiesDescription: 'Os cookies analíticos permitem coletar estatísticas sobre o uso e o tráfego do nosso site, para que possamos melhorá-lo.',
    COOKIES_analyticsPurposeCookiesProviders: 'Quais provedores de cookies usamos para fins de análise?',
    COOKIES_analyticsPurposeCookiesProvidersDescription: 'Nós usamos esses provedores para coletar informações para fins estatísticos.',
    COOKIES_googleAnalyticsDescription: 'O Google é especializado em produtos e serviços relacionados à Internet, incluindo tecnologias de publicidade on-line, mecanismo de pesquisa, computação em nuvem, software e hardware.',
    COOKIES_hotjarDescription: 'Hotjar Ltd. fornece ferramentas de análise da web que analisam o tráfego de dados do cliente',
    COOKIES_urlText: 'Leia a política de privacidade do provedor de cookies',
    MULTIPLE_EDITION_title: 'Edição massiva',
    MULTIPLE_EDITION_chapter: 'Capítulo',
    MULTIPLE_EDITION_chooseChapterMessage: 'Escolha um capítulo para completar as informações dos ativos em edição massiva',
    MULTIPLE_EDITION_errorMessage: 'Por favor, verifique os campos do formulário',
    MULITPLE_EDITION_chaptersSelector: 'Seletor de capítulo',

    COMMUNICATIONS_technicalManager: 'Validador',
    copy_button: 'Copiar',
    paste_button: 'Pegar',
    copy_success: 'A informação foi copiada.',
    paste_success: 'Informações coladas.',
    paste_error: 'Não há informações copiadas.',
    paste_not_allowed: 'Não é possível colar as informações em um arquivo já fechado.',
    copied: '¡Copiado!',
    APPRAISALS_errorRecalculatingValidations: 'Falha ao recalcular validações',

    VALIDATION_checkWarnings: 'Sucesso! Mostramos os avisos para verificar se você os levou em consideração',
    VALIDATION_warning: 'Aviso!',
    APPRAISALS_contract: 'Origem',

    EmptyValue: 'Sem valor',

    web_reload_app_title: 'Nova versão disponível!',
    web_reload_app_message: '<p class="app-version-p">Há uma <b>versão mais recente</b> da ferramenta disponível.</p><p class="app-version-p"> Em ordem para aproveitar as atualizações mais recentes, recarregue esta guia pressionando <img class="app-version-image" src="assets/images/icons/reload.PNG"> ao lado da barra de pesquisa do navegador ou pressione as teclas <span class="app-version-key">Ctrl</span> <b>+</b> <span class="app-version-key">F5</span> no seu teclado.</p>',
    mobile_android_reload_app_message: '<p class="app-version-p">Há uma <b>versão mais recente</b> da ferramenta disponível.</p><p class="app-version-p"> Em ordem para aproveitar as atualizações mais recentes, baixe o aplicativo na <b>Play Store</b>.</p>',
    mobile_ios_reload_app_message: '<p class="app-version-p">Há uma <b>versão mais recente</b> da ferramenta disponível.</p><p class="app-version-p"> Em ordem para aproveitar as atualizações mais recentes, baixe o aplicativo na <b>Apple Store</b></p>',
    APPRAISALS_mbWeight: 'Peso',
    APPRAISALS_sizeInMB: 'MB',
    APPRAISALS_editDocument: 'Editar documento',
    DOCUMENTS_obtainedDatetimeNull: '<b>A data de emissão é exigida em documentos do tipo</b> Documentação Cadastral, Nota Simples e Certificação Cadastral.',
    DOCUMENTS_vinculatedToAssets: 'Documentos do tipo Documentação Cadastral devem estar vinculados a um ativo.',
    APPRAISAL_notifiedToEntity: 'Notificação para Entidade',

    MASSIVE_marketStudy: 'Para criar um estudo de mercado, você pode fazê-lo em: •Common Data -> Market Study',
    Massive_marketStudyCopyCell: 'Uso e tipologia são diferentes',
    Massive_surfaceCopyCell: 'Não há superfície para este ativo.',

    cadastralSurfaces: 'cadastral',
    checkSurfaces: 'verificado',
    registeredSurfaces: 'registro',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalDatetime: 'Data de validação anterior',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalWorkProgressPercent: 'Progresso do trabalho do relatório anterior (%)',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalTotalValue: 'Avaliação avaliação anterior',
    entityDetail: 'Detalhe da entidade',
    ASSETS_rCoefficient: 'R',
    ASSETS_rCoefficientInfo: 'Área construída com áreas comuns entre áreas úteis',
    ASSETS_existsComparableMarket: 'Existe um mercado comparável?',
    ASSETS_defaultComparableMarketText: 'Não foi possível calcular o valor do imóvel pelo método da comparação uma vez que não se verificam os requisitos descritos no artigo 21º do Despacho ECO 805/2003 para a utilização deste método.',

    avaliaçãoInfo: 'Informações',
    REPORT_LIST_succesDraftDeneration: 'Se o rascunho do relatório não abriu em uma nova guia, verifique se não há pop-ups bloqueados em seu navegador e gere-o novamente.',
    APPRAISALS_externalConnectionError: 'Ocorreu um erro na conexão',
    ASSETS_socioEconomicLevel: 'Nível socioeconômico',
    languageReport: 'idioma do relatório',
    SelectAllNodes: 'Marcar/Desmarcar todos',
    CONTACT_US_title_Tasafy: 'Suporte Tasafy',
    CONTACT_US_description: 'Se você tiver alguma dúvida ou problema com a ferramenta, entre em contato conosco pelo seguinte e-mail:',
    CONTACT_US_call: 'Chamar',
    MASSIVE_possessions: 'Posses',
    MASSIVE_stays: 'Stadias',
    locationError: 'As coordenadas não podem ser encontradas para o endereço indicado.',

    hour: 'Hora',

    creation: 'Creación',
    edition: 'Edición',
    actual: 'Actual',
    HET: 'HET',

    VALORATION_METHOD_MODAL_ACTION: 'Qual ação você deseja executar?',
    VALORATION_METHOD_MODAL_TYPE: 'Tipo de avaliação',
    PARTNER_MODAL_slide1_title1: 'Programa de parceiros 2023',
    PARTNER_MODAL_slide1_subtitle1: 'NÓS AJUDAMOS VOCÊ A TORNAR SEUS SONHOS MAIORES',
    PARTNER_MODAL_slide2_header1: 'Ainda mais perto do seu crescimento',
    PARTNER_MODAL_slide2_paragraph1: 'Na Valum sempre nos preocupamos em ajudar você a <b>aproveitar ao máximo seu trabalho</b>.',
    PARTNER_MODAL_slide2_paragraph2: 'Primeiro fizemos isso oferecendo a adesão ao nosso <b>programa de parceiros</b>: uma nova forma de <b>desenvolvimento profissional</b> e uma fonte complementar de renda.',
    PARTNER_MODAL_slide2_paragraph3: 'Posteriormente, lançamos a ferramenta de gestão e execução de avaliações mais completa do mercado -<b>Tasafy</b>-, e demos a você acesso ao <b>módulo colaborador: um espaço único</b> onde você pode criar seus próprios orçamentos, monitorar todo o seu trabalho em tempo real e obter a máxima satisfação do cliente.',
    PARTNER_MODAL_slide2_paragraphBottom1: '...',
    PARTNER_MODAL_slide3_header1: 'Apresentando algo revolucionário...',
    PARTNER_MODAL_slide3_paragraph1: 'Agora chega 2023 e queremos continuar <b>apostando em você e no seu trabalho</b>.',
    PARTNER_MODAL_slide3_paragraph2: 'Já imaginou inserir seus honorários no mesmo momento em que o cliente recebe seu laudo de avaliação?',
    PARTNER_MODAL_slide3_paragraph3: 'Nós tornamos isso possível!',
    PARTNER_MODAL_slide4_header1: 'Descubra! A sua agilidade marcará a nossa',
    PARTNER_MODAL_slide4_paragraph1: 'O cliente efetua o pagamento do orçamento emitido pela Tasafy.',
    PARTNER_MODAL_slide4_paragraph2: 'Você visita o imóvel e inicia a execução do laudo de avaliação através da Tasafy.',
    PARTNER_MODAL_slide4_paragraph3: 'Você entrega a minuta do laudo de avaliação à Valum. Isso é validado e entregue ao cliente',
    PARTNER_MODAL_slide4_paragraph4: 'Valum e você aprova as taxas para este arquivo e elas serão pagas imediatamente no mesmo dia*.',
    PARTNER_MODAL_slide4_paragraphTitle0: 'Exemplo útil',
    PARTNER_MODAL_slide4_paragraphTitle1: 'Segunda-feira',
    PARTNER_MODAL_slide4_paragraphTitle2: 'terça-feira',
    PARTNER_MODAL_slide4_paragraphTitle3: 'Quarta-feira',
    PARTNER_MODAL_slide4_paragraphBottom1: '*Verificar requisitos e condições',

    PARTNER_MODAL_slide5_header1: 'Requisitos',
    PARTNER_MODAL_slide5_paragraph1: 'Este formato de pagamento só se aplica a orçamentos gerados a partir de 1 de janeiro de 2023 e pagos por POS: cartão de crédito ou bizum, bem como para pagamentos por transferência bancária apenas quando o valor for superior a € 1.500 + IVA.',
    PARTNER_MODAL_slide5_paragraph2: 'Da mesma forma, só será válido para arquivos em que você apareça como prescritor. Atribuições para coleta ou montagem de dados são, portanto, excluídas.',
    PARTNER_MODAL_slide5_paragraph3: 'Da mesma forma, deve ser um relatório de avaliação feito por nossa ferramenta de execução <b>Tasafy</b>, outras ferramentas externas não podem ser usadas.',

    PARTNER_MODAL_slide6_header1: 'Condições gerais',
    PARTNER_MODAL_slide6_paragraph1: 'O sistema de cobrança passa a ser diário. De segunda a sexta, a partir das 15h, será gerada uma fatura com todos os seus arquivos entregues ao cliente (status 11). No seu painel de cobrança, você deve validá-lo.',
    PARTNER_MODAL_slide6_paragraph2: 'Você decide quando validar suas faturas: diariamente, semanalmente, quinzenalmente... Você saberá que, uma vez validadas suas faturas, receberá o pagamento a partir das 17h00',
    PARTNER_MODAL_slide6_paragraph3: 'A data da fatura será a data da sua validação. Como antes, você deve preencher o número da fatura.',
    PARTNER_MODAL_slide6_paragraph4: 'Para receber o pagamento no mesmo dia, você deve aceitar suas faturas antes das 17h de cada dia. Após esse período, você receberá o pagamento no dia seguinte após as 17h',
    PARTNER_MODAL_slide6_paragraph5: 'Nos feriados também serão geradas notas fiscais para arquivos com status 11. O pagamento será feito no próximo dia útil. Da mesma forma, as faturas validadas às sextas-feiras serão pagas juntamente com as da segunda-feira',

    PARTNER_MODAL_slide7_header1: 'Como funciona',
    PARTNER_MODAL_slide7_paragraph1: 'De segunda a sexta-feira, a partir das 15h, será gerada uma fatura provisória que incluirá todos os seus arquivos no status 11 gerados nas últimas 24 horas.',
    PARTNER_MODAL_slide7_paragraph2: 'Você receberá um alerta em seu celular notificando que você tem uma fatura pendente de validação.',
    PARTNER_MODAL_slide7_paragraph3: 'Lembre-se de ativar as notificações. Você deve validá-lo e inserir o número da fatura correspondente.',
    PARTNER_MODAL_slide7_paragraph4: 'Você terá até as 17h desse dia para realizar a validação. Posteriormente, o seu pagamento será efetuado.',
    PARTNER_MODAL_slide7_paragraph5: 'Tudo o que você validar após as 17h será incluído no pagamento do próximo dia útil.',
    PARTNER_MODAL_slide7_paragraph6: 'Se você rejeitar uma fatura por não concordar com os honorários de um arquivo, ela entrará no processo de revisão e será corrigida, se aplicável, no dia seguinte antes das 17h. Você receberá seu pagamento posteriormente.',

    PARTNER_MODAL_slide8_paragraph1: 'OBRIGADO',
    PARTNER_MODAL_slide8_paragraph2: 'Para qualquer dúvida ou mais informações, entre em contato conosco',
    PARTNER_MODAL_slide8_button1: 'Concluir',
    PROFESSIONAL_MODAL_paragraph: '<p>Você tem <b>documentação pendente</b> de entrega para poder começar a receber cobranças.</p><p>Por favor, verifique a seção de documentos do seu perfil</p>',
    PROFESSIONAL_MODAL_button: 'Ir para documentos',
    IMPORT_ASSETS_message: 'Não se esqueça de importar ativos de aplicativos para que você possa <b>completar seu relatório!<div class="js-intro-show-again"></div>',
    IMPORT_ASSETS_dontShowAgain: 'Não mostrar novamente',
    IMPORT_ASSETS_title: 'Importar ativos',

    COMMUNICATIONS_reviewedBy: 'Revisado por',
    COMMUNICATIONS_theDay: 'o dia',

    REQUESTS_fromPartner: 'É do parceiro?',

    TECHNICAL_MODAL_slide1_title1: '¡Da la bienvenida a nuestras campanas!',

    TECHNICAL_MODAL_slide2_header1: 'Para que servem?',
    TECHNICAL_MODAL_slide2_paragraphTitle0: 'Existem três tipos:',
    TECHNICAL_MODAL_slide2_paragraphTitle1: 'ERRORS',
    TECHNICAL_MODAL_slide2_paragraph1: 'O sino indicará quais campos do relatório devem ser modificados para entrega.',
    TECHNICAL_MODAL_slide2_paragraphTitle2: 'AVISOS',
    TECHNICAL_MODAL_slide2_paragraph2: 'O sino indicará informações relevantes a serem consideradas.',
    TECHNICAL_MODAL_slide2_paragraphTitle3: 'INFORMAÇÃO',
    TECHNICAL_MODAL_slide2_paragraph3: 'O sino irá sugerir possíveis melhorias para o relatório.',

    TECHNICAL_MODAL_slide3_header1: 'Como posso consultá-los?',
    TECHNICAL_MODAL_slide3_paragraph1: 'Os alertas podem ser consultados a qualquer momento acessando a última versão do relatório e clicando no sininho que aparece no canto superior direito, aqui:',
    TECHNICAL_MODAL_slide3_paragraph2: 'Em seguida, uma janela como a seguinte será exibida:',
    TECHNICAL_MODAL_slide3_paragraph3: 'Após efetuar as modificações, não esqueça de clicar no botão Atualizar validações, na parte inferior, para conhecer a versão mais atual das campanhas disponíveis.',

    COMPARABLES_completeComparables: 'Complete suas testemunhas',
    COMPARABLES_findComparables: 'Encontre sua testemunha',
    COMPARABLES_createComparable: 'Criar testemunha',
    COMPARABLES_selectComparable: 'Selecionar testemunha',
    COMPARABLES_chooseComparablesTitle: 'Escolha os testemunha que deseja usar',
    COMPARABLES_confirmSelection: 'Confirmar seleção',
    COMPARABLES_newSearch: 'Nueva búsqueda',
    COMPARABLES_backToSearchResultList: 'Voltar ao resultado da pesquisa',
    COMPARABLES_completeErrorMessage: 'Preencha os dados necessários para poder adicionar o token',
    COMPARABLES_skipConfirmMessage: 'Ao realizar esta ação, o resultado da busca realizada será perdido, continuar?',
    COMPARABLES_addComparables: 'Adicionar testemunhas',
    COMPARABLES_completeSuccessMessage: 'Concluído',
    COMPARABLES_searchInIdealista: 'Procurar em idealista',
    COMPARABLES_yearConstruction: 'Ano de construção',
    COMPARABLES_operation: 'Tipo de venda',
    COMPARABLES_numberOfRoomsFromTo: 'Número de quartos',
    COMPARABLES_numberOfBathsFromTo: 'Nº casas de banho',
    COMPARABLES_numberOfRooms: 'Número de quartos',
    COMPARABLES_numberOfBaths: 'Número de casas de banho',
    COMPARABLES_interiorStateProperty: 'Estado interior do imóvel',
    COMPARABLES_top: 'Alta',
    COMPARABLES_middle: 'Intermediário',
    COMPARABLES_ground: 'Baixa',
    COMPARABLES_moreFilters: 'Mais filtros',
    COMPARABLES_searchResultEmpty: 'Não há resultados para esta pesquisa',
    COMPARABLES_searchResultError: 'Falha na pesquisa',
    COMPARABLES_added: 'Adicionado',
    COMPARABLES_withoutAddress: 'Testemunha não endereçada',
    COMPARABLES_removeComparableMessage: 'Se você excluir o token, as informações inseridas serão perdidas. Continuar?',
    COMPARABLES_idealistaTermsAndConditionsTitle: 'Termos e Condições do Idealista',
    COMPARABLES_idealistaRequiredWitnessInfo: '<p>Na próxima etapa, os tokens <a href="www.idealista.com">Idealista.com</a> serão consultados e baixados. Este serviço tem um custo associado de 20 cêntimos + cotações e cada consulta irá devolver um máximo de 30 testemunhas.</p><p>A aplicação de filtros excessivamente restritivos pode reduzir o volume de testemunhas encontradas.</p> <p>Se após a consulta não forem obtidas testemunhas, o preço será também 20 cêntimos + cotações.</p>',
    COMPARABLES_idealistaConditionsAccept: 'Eu li e aceito as condições',
    COMPARABLES_addComparableManually: 'Adicionar testemunha manualmente',
    COMPARABLES_chooseIdealistaComparables: 'Escolha a testemunha que deseja usar',
    COMPARABLES_chooseACadastralReference: 'Escolha uma referência cadastral',

    ASSET_marketStudyCreateInValorationMethod: '*Será criado um estudo de mercado que você pode selecionar para outros métodos neste relatório',
    COMPARABLES_confirmCloseModal: 'Tem certeza de que deseja fechar esta janela? Você perderá a pesquisa e os comparáveis preenchidos.',

    MULTIPLE_EDITION_valuationState: 'Estado de valuación',
    MULTIPLE_EDITION_addValuation: 'Adicionar avaliação',
    MULTIPLE_EDITION_cancelAddValuation: 'Cancelar avaliação adicionada',
    MULTIPLE_EDITION_editingValuation: 'Editando avaliação',
    MULTIPLE_EDITION_cancelEditValuation: 'Cancelar edição',
    MULTIPLE_EDITION_createValorationNotAllowed: 'Valorização não pode ser adicionada para este ativo',
    RECALCULATION_successMessage: 'recálculo bem sucedido',
    RECALCULATION_errorMessage: 'Falha ao executar o recálculo',

    MARKET_STUDY_assetReferenced: 'Ativo referenciado',
    MARKET_STUDY_assetReferencedMessage: 'Este estudo de mercado refere-se às características do imóvel seleccionado, sendo aplicável a outros activos com propriedades semelhantes.',
    MARKET_STUDY_assetReferenceInfo: '*Dados extraídos do relatório, se desejar completá-los ou modificá-los deve fazê-lo no capítulo correspondente.',
    MARKET_STUDY_height: 'Altura',
    MARKET_STUDY_landValueCalulation: 'Cálculo do valor do terreno dentro do custo',
    MARKET_STUDY_plotArea: 'Área do lote',
    editDocument_error: 'Não é possível editar um documento que foi importado.',
    APPRAISALS_VALUATION_saveGroundValuation: 'Salvar valor do terreno',
    ground_characteristics: 'Características do solo',
    info_vinculation: 'Uma vez criada a avaliação, o link não será editável',
    info_order: 'Depois que o pedido for criado, o link não será editável',
    COST_METHOD_GROUND_comparisonMethod: 'Avaliação do terreno - Comparação',
    COST_METHOD_GROUND_staticResidualMethod: 'Avaliação do terreno - Resíduo estático',
    COST_METHOD_GROUND_dynamicResidualMethod: 'Avaliação de terreno - Resíduo Dinâmico',
    CLUSTER_recalculateUnits: 'Recalcular unidades',
    APPRAISALS_VALUATION_groundValuation: 'Valor do solo',

    COST_METHOD_observationsProposedValues: 'Justificativa para cálculo da vida útil',
    APPRAISAL_DASHBOARD_FILTER_tabOrdersInfo: 'Informações do pedido',
    APPRAISAL_DASHBOARD_FILTER_interveners: 'Intervenientes',
    USER_IS_VERIFIED_APPRAISER: 'Profissional validado',
    USER_IS_VERIFIED_APPRAISER_info: 'Ao verificar o profissional, confirma-se que ele forneceu toda a documentação necessária para colaboração e registrou corretamente as incompatibilidades, permitindo a atribuição do trabalho.',
    USER_internal_observations_tooltip: 'Estes comentários não serão visualizados pelo profissional a partir do seu perfil.',
    USER_userStatus: 'Status do usuário',
    USER_info_text: 'A informação refletida nesta seção corresponde às preferências indicadas pelo profissional, não sendo possível edição. A atribuição de trabalhos será realizada de acordo com critérios verificados, podendo ser ajustados na secção de formação.',
    absents_info_text: 'Nesta seção você pode indicar seus períodos de inatividade para bloquear o recebimento de pedidos durante o mesmo. Pressione o botão "+" para adicionar dias de ausência',
    absence: 'Ausência',
    abstences_add: 'Adicionar dias de ausência',
    absences_startDate: 'Início da ausência',
    absences_finishDate: 'Fim de ausência',
    COMPARABLES_comparablesTitle: 'Comparáveis',
    COMPARABLES_infoComparablesTitle: 'Informações comparáveis',
    COMPARABLES_appraisalFilterTitle: 'Detalhes do pedido',
    COMPARABLES_appraisalIdFilter: 'ID da avaliação',
    USERS_state_inactive: 'Usuário inativo',
    USERS_state_checked: 'Usuário validado',
    USERS_state_absent: 'Usuário ausente',
    USERS_state_notValidated: 'Usuário não validado',
    training: 'Treinamento',
    typologies_to_evaluate: 'Tipologias para avaliar',
    limit_assignment_check: 'Limitar a atribuição de atribuições à coleta de dados',
    limit_assignment_check_subtitle: 'Se esta opção for selecionada, o profissional só poderá realizar tarefas classificadas como Coleta de Dados',
    edit_professional_preferences_check: 'Editar preferências profissionais',
    professional_score: 'Pontuação do profissional',
    APPRAISALS_selectScore: 'Avalie o trabalho técnico realizado pelo profissional responsável por este arquivo',
    TRAINING_globalScore: 'PONTUAÇÃO GLOBAL',
    TRAINING_deadline: 'DEADLINE',
    TRAINING_execution: 'EXECUÇÃO',
    TRAINING_level1: 'NÍVEL 1',
    TRAINING_level2: 'NÍVEL 2',
    TRAINING_level3: 'NÍVEL 3',
    TRAINING_elements: 'ELEMENTOS',
    TRAINING_buildings: 'EDIFÍCIOS',
    TRAINING_terrains: 'TERREINS',
    TRAINING_rustic: 'PROPRIEDADES RÚSTICAS',
    TRAINING_economicExploitations: 'EXPL.ECONÔMICAS',
    TRAINING_deadline_title: 'Total de notas em relação ao prazo',
    TRAINING_level_title: 'Total de notas relativas à execução',
    soon: 'Breve',

    REQUESTS_confirmExpenses: 'Confirmar provisão de receitas/despesas ou setor de clientes',
    REQUESTS_sectorCustomer: 'Setor declientes',
    REQUESTS_confirmExpensesIconTooltip: 'A provisão de receitas/despesas precisa ser confirmada ou setor de clientes',
    REQUESTS_pendingConfirmExpenses: 'Provisão de receitas/despesas',
    pdfCustomerAdvanced: 'Relatório de Avaliação',
    QUALITY_CONTROL_customerAdvanced_externalReference: 'Referência Externa',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerName: 'Nome do proprietário da avaliação',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerLastName: 'Apelido do avaliador',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerFullName: 'Nome e apelido do titular da avaliação',
    QUALITY_CONTROL_customerAdvanced_appraisalOwnerDNI: 'ID do proprietário da avaliação',
    GLOBALSEARCH_searchResult: 'Resultado da pesquisa',

    REQUEST_documentPDF: 'Documento PDF',
    REQUEST_uploadDocument: 'Carregar documento PDF',
    carregar: 'Carregar',
    SIVASA_waitingForPayment: 'Aguardando pagamento',
    SIVASA_cancelledNonPayment: 'Cancelamento por falta de pagamento',
    MULTIPLE_FILE_UPLOAD_chooseLabel: 'Selecionar',
    MULTIPLE_FILE_UPLOAD_uploadLabel: 'Carregar',
    MULTIPLE_FILE_UPLOAD_cancelLabel: 'Excluir tudo',
    COMPARABLES_backToSelectedComparables: 'Complete sua testemunha',
    COMPARABLES_completet: 'Li e aceito as condições',
    COMPARABLES_searchResult: 'Resultado da pesquisa',
    COMPARABLES_selectedComparables: 'Testemunhas selecionadas',
    COMPARABLES_noItemsSelected: 'Nenhuma testemunha selecionada',
    COMPARISON_METHOD_witnessComplete: 'Complete sua testemunha',
    COMPARISON_METHOD_SearchWitness: 'Encontre sua testemunha',
    VISITTYPE_tooltip: 'Para avaliações realizadas ao abrigo da Circular 4/2017, o tipo de visita mínima exigida (caso seja selecionado “exterior”) não será obrigatório para a realização de visita interior.',

    QUALITY_processManagerUserTitle: 'Gerenciador de Processos',
    QUALITY_technicalManagerUserTitle: 'Gerente Técnico',
    QUALITY_professionalUsers: 'Técnicos',
    addingKeyValue: '+ Adding key/value',
    nifNiePassportCifCustomer: 'NIF/NIE/Passaporte/Cliente CIF',
    nifNiePassportCif: 'NIF/NIE/Passaporte/CIF',

    QUALITY_DASHBOARD_summaryOfUnmodifiedFiles: 'Resumen de control de calidad sin finalizar',
    QUALITY_DASHBOARD_qCPending: 'Pendientes de control de calidad',
    QUALITY_DASHBOARD_qCPendingAssignProfessional: 'Pendientes de asignar técnico',
    QUALITY_DASHBOARD_qCInProgess: 'En proceso de validación',
    QUALITY_DASHBOARD_summaryOfQCValidated: 'Resumen control calidad finalizados',
    QUALITY_DASHBOARD_summaryOfQCTotalValidated: 'Resumen total finalizados',
    QUALITY_DASHBOARD_qCValidationOK: 'Validación OK',
    QUALITY_DASHBOARD_qCValidationKO: 'Validación KO',
    QUALITY_DASHBOARD_summaryQCFinished: 'Resumen control de calidad finalizado',
    QUALITY_DASHBOARD_globalAverageTime: 'Tiempo medio global control de calidad',
    QUALITY_DASHBOARD_professionalAssignmentAverageTime: 'Tiempo medio asignación',
    QUALITY_DASHBOARD_validationAverageTime: 'Tiempo medio validación',
    MENU_how_it_works: 'Como funciona?',

    CLUSTER_wokrState: 'Estado da obra',
    CLUSTER_state: 'Estado do ativo',
    REPORT_validatedVersion: 'Versão validada',

    BUILDING_STATE_isClusteredBuildingSteteMessage: 'O capítulo Status do trabalho para este ativo não pode ser concluído porque pertence a um cluster.',
    UPLOAD_DOCUMENT_documentation: 'Documentação',
    UPLOAD_DOCUMENT_visitPhoto: 'Foto da visita',
    DOCUMENTS_mustSelectAssets: 'As fotos da visita devem ser atribuídas aos ativos',
    UPLOAD_PHOTO_message: 'Nesta secção deverão ser adicionadas apenas fotografias da visita. Os restantes documentos, independentemente do formato, deverão ser incorporados no capítulo de documentação.',
    ASSETS_energeticExemptionJustification: 'Justificativa de isenção',
    ASSETS_energeticExemptionJustificationMessage: 'Justificativa da isenção da obrigação de contribuir com EEC',

    APPRAISAL_REPORT_econominExploitationExceedUsefulLife: 'As datas que você selecionou ultrapassam a vida útil do imóvel, lembre-se de considerar os custos necessários nos fluxos de exploração para esta extensão da vida útil.',
    BDE_canAddBdeDataButton: 'Adicionar dados do Banco de Espanha',
    BDE_removeBdeDataButton: 'Remover dados do Banco de Espanha',
    BDE_bdeData: 'Dados do Banco de Espanha',
    BDE_sectionMessage: '<i>Os dados do Banco de Espanha devem ser discriminados ao nível do registo de propriedade para <u>terrenos, propriedades rurais e elementos de construção</u>. </br></br>Para <u>edifícios</u> será reportado conjuntamente no nível de avaliação, fornecendo o uso principal.</i>',
    BDE_valuationInformationTitle: 'Informações relativas ao método de avaliação adotado',
    BDE_valuationInformationHETTitle: 'Informações sobre o método de avaliação de HET adotado',
    BDE_valuationInformationBuildingElementTitle: 'Utilização do edifício a desenvolver',
    BDE_adoptedValorationValue: 'Valor atual adotado ({{currency}})',
    BDE_adoptedValorationHETValue: 'Valor adotado HET ({{currency}})',
    BDE_adoptedValorationMethod: 'Método de avaliação atual adotado',
    BDE_adoptedValorationHETMethod: 'Método de avaliação HET adotado',
    BDE_khCoefficientWarningIncluded: 'Aviso específico foi incluído',
    BDE_usefulLife: 'Excelente vida útil (anos)',
    BDE_durationProject: 'Duração do projeto (meses)',
    BDE_yearsToFinish: 'Prazo para conclusão das obras (anos)',
    BDE_evolution: 'Evolução esperada do mercado. Avaliar',
    BDE_removeConfirmMessage: 'Se você excluir os dados do Banco de Espanha, perderá as informações inseridas. Você deseja continuar?',
    BDE_filterExecutionTitle: 'Relatórios sem execução pela Tasafy',
    BDE_activeData: 'Dados BdE ativos',
    BDE_completeData: 'Dados BdE completos',
    BDE_withMoreThanOneHundredParentAssetAsResidential: 'Tem mais de 100 casas',

    APPRAISALS_addMassiveAssets: 'Adição massiva de ativos',
    APPRAISALS_addMassiveAssetsStructure: 'Adição massiva de estrutura de avaliação',
    APPRAISALS_uploadMassiveAssetsFile: 'Carregar arquivo de upload',
    APPRAISALS_massiveAssetsFileResult: 'Carregar resultado',
    APPRAISALS_massiveAssetsProcessFile: 'Arquivo de processo',
    APPRAISALS_massiveAssetsInProcess: 'Processando...',
    APPRAISALS_massiveAssetsProcessOK: 'O arquivo foi cargado com sucesso',
    APPRAISALS_massiveAssetsProcessKO: 'O arquivo não está no formato correto',
    APPRAISALS_massiveAssetProcessOK: 'Ativo cargado com sucess',

    APPRAISALS_massiveAssetsBack: 'Voltar',
    APPRAISALS_massiveAssetsFinish: 'Concluir',
    APPRAISALS_massiveAssetsSelectFile: 'Selecionar arquivo',
    APPRAISALS_massiveAssetsTimeMessage: 'Tempo de carregamento aproximado: 10 ativos por segundo',
    CLUSTER_BUILDINGSTATE_recalculateClusterWarning: 'Lembre-se que após recalcular você deve salvar as alterações.',

    APPRAISALS_MASSIVE_addToStructure: 'Adicionar ativos à estrutura existente',
    APPRAISALS_MASSIVE_replaceStructure: 'Substitua a estrutura atual (as informações existentes serão removidas)',
    APPRAISALS_MASSIVE_replaceStructureMessage: 'Não é possível adicionar novas propriedades cadastrais para este relatório. Somente será permitida a incorporação de anexos vinculados aos existentes. Para  </br> </br>fazer isso, você deve primeiro adicionar uma descrição a eles para poder refleti-la no modelo. Esta opção está disponível no capítulo <b>Descrição</b> da <b>Edição em Massa</b>',
    APPRAISALS_REPORT_addDescription: 'Adicionar Descrição',
    APPRAISALS_REPORT_assetDescription: 'Descrição do ativo',
    REPORT_validationButtonsTitle: 'Validações',
    REPORT_validationButtonsUpdate: 'Atualização',
    REPORT_validationButtonsInfo: 'Informações',
    REPORT_avaliaçãoButtonsTitle: 'Avaliações',
    REPORT_valueButtonsSummary: 'Ver resumo',
    REPORT_avaliaçãoSumary_element: 'Por elemento',
    REPORT_avaliaçãoSumary_property: 'Por imóvel cadastrado',
    APPRAISALS_documentsAndImages: 'Documentos/Imagens',
    APPRAISALS_images: 'Imagens',
    APPRAISALS_addImage: 'Adicionar imagem',
  },
};
