import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { AppraisalFileFilterModel, OrNull, AppraisalFileWithAllSizeModel, AppraisalImplementationFileDocumentTypes } from '@foxeet/domain';
import { Observable } from 'rxjs';
import { removeNullsAndUndefined } from '../utils/data-access.utils';
import { WorkflowService } from '../services/workflow.service';

@Injectable()
export class AppraisalDocumentsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalFiles', cache);
  }

  editDocument(id: number, body: any) {
    return this.put<typeof body, void>(`${id}`, body);
  }

  uploadAppraisalDocument(appraisalId: number, bodyParams: any, document: Blob): Observable<HttpEvent<any>> {
    const body = new FormData();
    console.log(bodyParams);
    body.append('File', document);
    body.append('appraisalId', `${appraisalId}`);
    Object.keys(bodyParams).forEach((key) => {
      if (bodyParams[key] !== null && bodyParams[key] !== undefined) {
        body.append(key, `${key.toLowerCase().includes('date') ? bodyParams[key].toISOString() : bodyParams[key]}`);
      }
    });

    return this.post('', body, {
      observe: 'events',
      responseType: 'json',
      reportProgress: true,
    });
  }

  setIsPrintableById(id: number, isPrintable: boolean) {
    const body = removeNullsAndUndefined({ id, isPrintable });
    return this.put(`${id}/IsPrintable`, body);
  }

  // Override because not exist /ListPaged endpoint
  public getPagedFunction() {
    return (page: number, pagesize: number, filter: any, endpoint = 'ListPaged'): any => {
      return this.getAppraisalDocuments(filter);
    };
  }

  getAppraisalDocuments(body: AppraisalFileFilterModel) {
    body = removeNullsAndUndefined(body);
    return this.post<AppraisalFileFilterModel, AppraisalFileWithAllSizeModel>(`ListAll`, body, { observe: 'response' });
  }

  getAppraisalPictures(body: AppraisalFileFilterModel) {
    body = removeNullsAndUndefined(body);
    return this.post<AppraisalFileFilterModel, AppraisalFileWithAllSizeModel>(`ListAllPictures`, body, { observe: 'response' });
  }
  downloadAppraisalFiles(body: AppraisalFileFilterModel) {
    return this.post<typeof body, Blob>(`ZipAllFiles`, body, {
      responseType: 'blob',
    });
  }

  public download(id: number): Observable<Blob> {
    return this.get(`${id}/Download`, {
      responseType: 'blob',
    });
  }
}
