export enum CurrentWorkflowStep {
  CurrentWorkflowStep,
}

export enum AppraisalStatus {
  register = 1,
  toAssign2 = 2,
  technicianAssigned = 3,
  visitScheduled = 4,
  dateVisitStarted5 = 5,
  visited6 = 6,
  reportDelivered = 7,
  validatorAssigned = 8,
  validationProcess9 = 9,
  validated10 = 10,
  deliveredtoClient11 = 11,
}

export enum BooleanQuestionValues {
  yes = 'true',
  not = 'false',
}

export enum IsOkStatusValues {
  ok = 'true',
  ko = 'false',
}

export enum MonthsEnum {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export enum TerrainCalculationMethod {
  STATIC_RESIDUAL = 0,
  DYNAMIC_RESIDUAL,
}

export enum CriteriaTypes { // Same as TerrainExpenseCalculationTypes
  INCOMES = 0,
  TOTAL_CONSTRUCTION_COST = 1,
  MANUAL = 2,
}

export enum XPlatform {
  WEB_ADMIN = 0,
  MOBILE = 1,
  WEB_CUSTOMER = 2,
}

export interface SignalrReportResponse {
  WorkflowItemAssetId: number;
  assetSection: number;
  assetSectionObjectMappingModel: any;
}

export enum AssetInstructions {
  Others = 0,
  Parts = 1,
  Building = 2,
  Terrain = 3,
  RusticEstate = 4,
}

export enum Facilities {
  Other = 0,
  HotWater = 1,
  Heating = 2,
}

export enum FacilityLocations {
  Asset = 0,
  Building = 1,
}

export enum CeillingTypes {
  Painting = 0,
}

export enum SimpleNotesDefaultFiltersNameEnum {
  'all' = 0,
  'created',
  'sent-to-register',
  'recived-from-register',
  'delivered-to-client',
}

export enum ValorationMethodsToShow {
  ComparationMethod = 0,
  CostMethod = 1,
  DiscountCashflow = 3,
  ContractCashflowMethod = 4,
  LegalMaximumMethod = 5,
  OtherMethod = 6,
}

export enum InternationalizationTypes {
  Currency = 0,
  UnitLength = 1,
  SurfaceUnit = 2,
  CurrencyPerSurfaceUnit = 3,
}

export enum AvailableValorationMethods {
  ComparationMethod = 0,
  CostMethod = 1,
  DiscountCashflow = 3,
  ContractCashflowMethod = 4,
  LegalMaximumMethod = 5,
  OtherMethod = 6,
  StaticResidual = 7,
  DynamicResidual = 8,
  EconomicExploitationMethod = 10,
  RusticEstate = 11,
}

export enum ValuationResumeModalTabOptions {
  HET = 'true',
  Current = 'false',
}

export enum AppraisalDocumentFormType {
  Document = 0,
  Picture = 1,
}

export enum IncomeDistValueType {
  Percentage = 1,
  Unit = 2,
}

export enum PeriodDistValueType {
  Anual = 1,
  Monthly = 2,
}

// TODO: NOT EXIST ON BACKEND, REVIEW IF SHOULD BE IN FRONT OR NOT.
export enum ConnectionRequestActions {
  Accept = 0,
  Reject = 1,
  CancelConnection = 2,
}

export enum CadastralInfoByLocationTypes {
  StreetAndNumber = 0,
  PolygonAndPlot = 1,
  Coordinates = 2,
}

export enum DevelopEnvironment {
  dev,
  qa,
  prod,
}

export enum AppId {
  IVFORCE,
  TASAFY,
  AVM,
}

export enum AppraisalDashboardActionType {
  All = 0,
  AppraisalPendingToReceive = 1,
  AppraisalPendingToValidate = 2,
  IncidentsPendingToReceive = 3,
  IncidentsPendingToValidate = 4,
  RevisionsPendingToReceive = 5,
  RevisionsPendingToValidate = 6,
  ClaimsPendingToReceive = 7,
  ClaimsPendingToValidate = 8,
  TotalIncidents = 9,
}

export enum QualityControlDashboardActionType {
  All = 0,
  qCPending = 1,
  qCPendingAssignProfessional = 2,
  qCInProgress = 3,
  qCValidated = 4,
  qCValidatedOK = 5,
  qCValidatedKO = 6,
}

export enum AddNewAssetMode {
  CadastralReferenceByDirection = 0,
  InformationByCadastralReference = 1,
  Manually = 2,
  ExternalSource = 3,
}

export enum WebAddComparablesMode {
  InformationByCadastralReference = 0,
  CadastralReferenceByDirection = 1,
  ExternalSource = 2,
}

export enum WebAddNewAssetMode {
  InformationByCadastralReference = 0,
  CadastralReferenceByDirection = 1,
  Manually = 2,
}

export enum WebAllTabsAddNewMode {
  InformationByCadastralReference = 0,
  CadastralReferenceByDirection = 1,
  Manually = 2,
  ExternalSource = 3,
}

export enum NewAssetSlidesTypes {
  search = 0,
  result = 1,
}

export enum MarketStudyAction {
  cancel = 0,
  modify = 1,
  new = 2,
}

/**
 * @todo: move to domain
 */
export enum CashFlowTableShowModesEnum {
  Yearly = 0,
  Monthly = 1,
}

export enum SupplierCompanies {
  Valum = 0,
  Tinsa = 1,
}

export enum CashflowPeriodActionType {
  Added = 0,
  Updated = 1,
  Deleted = 2,
}

export enum ValuationMassiveStates {
  NoActionAllowed = 0,
  Creatable = 1,
  Editable = 2,
}

export enum LicensePlans {
  Basic = 1,
  Expert = 2,
  BasicClient = 3,
  Trial = 5,
  Premium = 6,
}

export enum LicensePlanChangeTypes {
  Downgrade = 0,
  Same = 1,
  Upgrade = 2,
}

export enum LicenseeReportCustomizeTypes {
  Logo = 0,
  Signature = 1,
  ImageHeader = 2,
}

export enum ConnectionActions {
  Accept = 0,
  Reject = 1,
  Cancel = 2,
}

export enum AcademyCourseDocumentTypes {
  Others = 0,
}

export enum AcademyCourseDocumentTypologies {
  Blueprints = 0,
}

export enum RedirectionComponents {
  PaymentPlan = 0,
  PaymentMethod = 1,
}

export enum ReportLanguages {
  es = 0,
  en = 1,
}

export enum AppraisalExternalCommunicationProcessStates {
  InProcess = 0,
  UserActionRequired = 1,
  FinishedOrReviewed = 2,
}

export enum SummaryView {
  element = 0,
  property = 1,
}

export enum DocumentsTabEnum {
  documents = 0,
  pictures = 1,
}
